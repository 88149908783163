import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";

export const OriginalContribution = () => {
  const { currentOnboardingNodeId, currentOnboardingNodeName } = useOnboarding();
  const { data, doSetData, customerCreated } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      {!customerCreated && (
        <p className="text-[12px] text-grey-300 mr-12 mt-2">
          We believe that you've created something{" "}
          <span className="text-grey-200">
            new and meaningful in your field of expertise.
          </span>
          To support this criteria, we'll include{" "}
          <span className="text-grey-200">
            details and documentation about a product, technology, or business
            you've developed, designed, or meaningfully contributed to.
          </span>
        </p>
      )}

      <Field
        id="whatMakesYouUnique"
        label="List 4-5 bullets that describe what makes this contribution unique or novel in some way."
      >
        <TextArea
          placeholder="What makes this unique?"
          value={data.whatMakesYouUnique?.value as string}
          onChange={(val) => doSetData("whatMakesYouUnique", val)}
          className="w-full"
        />
      </Field>

      <Field
        id="whatMakesYouImpactful"
        label="List 4-5 bullets that describe the impact of this contribution to the industry"
      >
        <TextArea
          placeholder="What makes this impactful?"
          value={data.whatMakesYouImpactful?.value as string}
          onChange={(val) => doSetData("whatMakesYouImpactful", val)}
          className="w-full"
        />
      </Field>

      <Field
        id="supportingDocuments"
        label="Upload any supporting documents that demonstrate what you built and its achievements."
        description="Examples include: a patent, README of a code repository you majorly contributed to, highly cited research paper. 
        To show significant industry impact, examples include: high user metrics for a product, major partnerships, press articles featuring your work, or citations from reputable institutions in the field
        All documents are for your visa application only."
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="Please leave any additional comments you'd like the Lighthouse team to review as it relates to this criteria"
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="original-contribution"
          name="original-contribution"
          description="Add another Original Contribution of Major Significance for our experts to review"
        />
      )}
    </OnboardingForm>
  );
};
