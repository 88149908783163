import { Field, OnboardingForm } from "@/components/form";
import { Checkbox, ListTextInput, TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";
import { URLReview } from "@/components/urlReview";
import { useEffect } from "react";

export const Authorship = () => {
  const { currentOnboardingNodeId, nodeStatus, currentOnboardingNodeName } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  useEffect(() => {
    if (data == null) return;
    if (
      data.publications == null ||
      data.publications.value == null ||
      data.publications.value.length === 0
    ) {
      if (data.hasOtherPublications?.value === true) return;
      doSetData("hasOtherPublications", true);
    }
  }, [data.publications]);

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        This is evidence that you have published{" "}
        <span className="text-grey-200">
          peer-reviewed scholarly articles or authored an article
        </span>{" "}
        in a major media or trade publication.
      </p>
      {(data.publications?.value ?? []).length > 0 && (
        <>
          <Field id="publications">
            <URLReview
              title="Good news! We've found that you've published articles we can use in your application for this criteria."
              description="Review and approve if these are correct."
              links={data.publications?.value ?? []}
              updateLinks={(links) => doSetData("publications", links)}
            />
          </Field>
          <Checkbox
            label="I have other publications I've authored that I'd like Lighthouse to review."
            checked={data.hasOtherPublications?.value ?? false}
            onCheckedChange={(value) => {
              doSetData("hasOtherPublications", value);
            }}
            disabled={nodeStatus === "submitted_for_review"}
            className="mr-12"
          />
        </>
      )}

      {data.hasOtherPublications?.value && (
        <Field
          id="otherPublications"
          label="Publications"
          description="Add any publications you have authored that you'd like Lighthouse to review."
          rejectionBorderClassName="px-2 py-1"
        >
          <ListTextInput
            value={data.otherPublications?.value}
            onValueChange={(value) => doSetData("otherPublications", value)}
            placeholder="https://scholar.google.com/article"
            disabled={nodeStatus === "submitted_for_review"}
          />
        </Field>
      )}

      <Field
        id="additionalComments"
        label="Additional comments"
        description="Please leave any additional comments you'd like the Lighthouse team to review as it relates to this criteria"
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {/* {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="authorship"
          name="authorship"
          description="Add a new Authorship criteria if you'd like to add even more evidence of publications you've authored for our experts to review"
        />
      )} */}
    </OnboardingForm>
  );
};
