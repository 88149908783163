import { Field, OnboardingForm } from "@/components/form";
import { useOnboarding } from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";
import { Input, CountryAddressInput, IncomeInput } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { RadioSelect } from "@/components/radio";
import { z } from "zod";

const emailSchema = z.string().email("Please enter a valid email address");
const urlSchema = z.string().url("Please enter a valid URL");

export const CurrentOrIntendedEmployer = () => {
  const { currentOnboardingNodeId, currentOnboardingNodeName } = useOnboarding();
  const { data, fetching, error, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  const worksiteAddress = (data.worksiteAddress?.value ?? {}) as Record<
    string,
    string
  >;

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      {fetching && data == null && <Spinner />}

      {error != null && (
        <div className="text-sm text-negative">
          Error loading employer details, please contact
          support@lighthousehq.com for assistance.
        </div>
      )}

      {data != null && !fetching && (
        <>
          <p className="text-[12px] text-grey-300 mr-12 mt-2">
            Lighthouse may need to collect company information from your current
            or intended employer for this visa. This is the employer entity that
            will serve as the "petitioner" on your application, as this visa
            cannot be self-sponsored.
          </p>

          <Field id="companyName" label="What is the name of the company?">
            <Input
              placeholder="Company name"
              type="text"
              value={data.companyName?.value as string}
              onValueChange={(val) => doSetData("companyName", val)}
              className="w-full"
            />
          </Field>

          <Field id="roleTitle" label="What is your role or title?">
            <Input
              placeholder="Role or title"
              type="text"
              value={data.roleTitle?.value as string}
              onValueChange={(val) => doSetData("roleTitle", val)}
              className="w-full"
            />
          </Field>

          <Field id="intendedSalary" label="What is your intended salary?">
            <IncomeInput
              value={data.intendedSalary?.value as string}
              onValueChange={(val) => doSetData("intendedSalary", val)}
            />
          </Field>

          <div className="pr-12">
            <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3">
              <div className="flex flex-col gap-2 pt-3 pb-3 pl-1.5">
                <div id="field-worksiteAddress" className="flex flex-col gap-2">
                  <Field
                    id="worksiteAddress"
                    label="What is the company worksite address where you will be working once on the visa?"
                    className="pr-4"
                  >
                    <Input
                      placeholder="123 Main St"
                      type="text"
                      value={worksiteAddress.street ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          street: val,
                        })
                      }
                      autoComplete={"street-address"}
                      className="w-full"
                    />
                  </Field>
                  <Field label="City" className="pr-4">
                    <Input
                      placeholder="San Francisco"
                      type="text"
                      value={worksiteAddress.city ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          city: val,
                        })
                      }
                      autoComplete={"address-level2"}
                      className="w-full"
                    />
                  </Field>
                  <div className="flex flex-row gap-2 w-full">
                    <Field label="State" className="pr-4">
                      <Input
                        placeholder="California"
                        type="text"
                        value={worksiteAddress.state ?? ""}
                        onValueChange={(val) =>
                          doSetData("worksiteAddress", {
                            ...worksiteAddress,
                            state: val,
                          })
                        }
                        autoComplete={"address-level1"}
                        className="w-full"
                      />
                    </Field>
                    <Field label="ZIP / Postal Code" className="pr-4">
                      <Input
                        placeholder="94102"
                        type="text"
                        value={worksiteAddress.zip ?? ""}
                        onValueChange={(val) =>
                          doSetData("worksiteAddress", {
                            ...worksiteAddress,
                            zip: val,
                          })
                        }
                        autoComplete={"postal-code"}
                        className="w-full"
                      />
                    </Field>
                  </div>
                  <Field label="Country" className="pr-4">
                    <CountryAddressInput
                      value={worksiteAddress.country}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          country: val as string,
                        })
                      }
                      priorityCountries={["US"]}
                      className="w-full"
                    />
                  </Field>
                </div>
              </div>
            </div>
          </div>

          <Field id="companyWebsite" label="What's the company's website?">
            <div className="flex flex-col gap-1">
              <Input
                placeholder="Company URL"
                type="url"
                value={data.companyWebsite?.value as string}
                onValueChange={(val) => doSetData("companyWebsite", val)}
                className="w-full"
              />
              {data.companyWebsite?.value &&
                !urlSchema.safeParse(data.companyWebsite.value).success && (
                  <div className="text-xs text-negative">
                    Please enter a valid URL
                  </div>
                )}
            </div>
          </Field>

          <Field
            id="employerRepName"
            label="Employer representative's full name"
          >
            <Input
              placeholder="Enter full name"
              type="text"
              value={data.employerRepName?.value as string}
              onValueChange={(val) => doSetData("employerRepName", val)}
              className="w-full"
            />
          </Field>

          <Field
            id="employerRepTitle"
            label="Employer representative's role title"
          >
            <Input
              placeholder="Enter role title"
              type="text"
              value={data.employerRepTitle?.value as string}
              onValueChange={(val) => doSetData("employerRepTitle", val)}
              className="w-full"
            />
          </Field>

          <Field
            id="employerRepEmail"
            label="Employer representative's email address"
          >
            <div className="flex flex-col gap-1">
              <Input
                placeholder="Email address"
                type="email"
                value={data.employerRepEmail?.value as string}
                onValueChange={(val) => doSetData("employerRepEmail", val)}
                className="w-full"
              />
              {data.employerRepEmail?.value &&
                !emailSchema.safeParse(data.employerRepEmail.value).success && (
                  <div className="text-xs text-negative">
                    Please enter a valid email address
                  </div>
                )}
            </div>
          </Field>

          <Field
            id="sendAdminPortalInvite"
            label="Can Lighthouse send an invite to your employer representative's Admin Portal, where they will be able to track progress of your application, sign forms, review any support letters?"
          >
            <RadioSelect
              value={data.sendAdminPortalInvite?.value}
              orientation="vertical"
              options={[
                {
                  value: true,
                  label: "Yes, Lighthouse can reach out to the Petitioner Representative",
                },
                {
                  value: false,
                  label: "No, I want to reach out to the Petitioner Representative myself",
                },
              ]}
              onChange={(val) => doSetData("sendAdminPortalInvite", val)}
            />
          </Field>
        </>
      )}
    </OnboardingForm>
  );
};
