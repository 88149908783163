import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type TutorialModalState = {
  modalShown: boolean;
  setModalShown: (value: boolean) => void;
};

export type GlobalModalState = {
  employer: TutorialModalState;
  letter: TutorialModalState;
  form: TutorialModalState;
  petitionReview: TutorialModalState;
};

export const useTutorialModalState = create(
  persist<GlobalModalState>(
    (set) => ({
      employer: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            employer: { ...state.employer, modalShown: value },
          })),
      },
      letter: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            letter: { ...state.letter, modalShown: value },
          })),
      },
      form: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            form: { ...state.form, modalShown: value },
          })),
      },
      petitionReview: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            petitionReview: { ...state.petitionReview, modalShown: value },
          })),
      },
    }),
    {
      name: "lighthouse-tutorial-modal-state",
      storage: createJSONStorage(() => localStorage),
      merge: (persistedState, currentState) => ({
        employer: {
          modalShown:
            (persistedState as any)?.employer?.modalShown ??
            currentState.employer.modalShown,
          setModalShown: currentState.employer.setModalShown,
        },
        letter: {
          modalShown:
            (persistedState as any)?.letter?.modalShown ??
            currentState.letter.modalShown,
          setModalShown: currentState.letter.setModalShown,
        },
        form: {
          modalShown:
            (persistedState as any)?.form?.modalShown ??
            currentState.form.modalShown,
          setModalShown: currentState.form.setModalShown,
        },
        petitionReview: {
          modalShown:
            (persistedState as any)?.petitionReview?.modalShown ??
            currentState.petitionReview.modalShown,
          setModalShown: currentState.petitionReview.setModalShown,
        },
      }),
    }
  )
);
