import { useState } from "react";
import { Button } from "./button";
import { Input } from "./inputs";
import { Modal } from "./modal";
import { z } from "zod";
import { useDebounce } from "@/lib/hooks/debounce";
import { useWorkspaces } from "@/lib/hooks/workspaces";
import { useMutation, useQuery } from "urql";
import {
  getCompanyMembers,
  inviteCompanyMember,
  updateCompanyMemberRole,
} from "@/lib/queries";
import { useUser } from "@clerk/clerk-react";
import { Popover, PopoverTrigger, PopoverContent } from "./popover";
import { ChevronDownIcon } from "@radix-ui/themes";
import { CheckIcon } from "@radix-ui/react-icons";
import { Avatar } from "./avatar";

const zodSchema = z.string().email();

const RoleSelector = (props: {
  value: "admin" | "member" | "viewer";
  onChange: (value: "admin" | "member" | "viewer") => void;
}) => {
  const { value, onChange } = props;

  const [open, setOpen] = useState(false);

  const roleOptions: {
    value: "admin" | "member";
    label: string;
  }[] = [
    { value: "admin", label: "Administrator" },
    { value: "member", label: "Member" },
  ];

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <button className="flex flex-row gap-2 pl-3 py-3 items-center">
          <span className="text-sm text-grey-300 capitalize">
            {roleOptions.find((option) => option.value === value)?.label ??
              "Unknown"}
          </span>
          <ChevronDownIcon />
        </button>
      </PopoverTrigger>

      <PopoverContent
        side="bottom"
        align="start"
        className="-mt-3 shadow-border w-36 rounded-md p-0"
      >
        <div className="flex flex-col w-full">
          {roleOptions.map((option) => (
            <div
              key={option.value}
              className="flex flex-row items-center gap-2 py-2 px-3 first:rounded-t-md last:rounded-b-md cursor-pointer hover:bg-grey-700 text-grey-300"
              onClick={() => onChange(option.value)}
            >
              {option.label}

              {option.value === value && (
                <CheckIcon className="w-4 h-4 text-positive ml-auto" />
              )}
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const UserRow = (props: {
  id: number;
  email: string;
  role: "admin" | "member" | "viewer";

  onRoleChange: (id: number, role: "admin" | "member" | "viewer") => void;
}) => {
  const { id, email, role, onRoleChange } = props;
  const { user } = useUser();

  const primaryEmail = user?.primaryEmailAddress?.emailAddress;

  return (
    <div className="flex flex-row items-center gap-2 text-sm text-grey-300">
      <Avatar username={email.split("@")[0]} className="w-7 h-7 rounded-full" />
      <span className="text-grey-100">{email}</span>
      {primaryEmail === email && (
        <span className="text-sm text-grey-400">(you)</span>
      )}

      <div className="ml-auto">
        <RoleSelector value={role} onChange={(val) => onRoleChange(id, val)} />
      </div>
    </div>
  );
};

const Members = () => {
  const { selectedWorkspace } = useWorkspaces();

  const parsedWorkspaceId = parseInt(
    selectedWorkspace?.id.split("-")[1] ?? "-1"
  );

  const [{ data }] = useQuery({
    query: getCompanyMembers,
    variables: {
      id: parsedWorkspaceId,
    },
    pause:
      selectedWorkspace?.id == null || selectedWorkspace?.id.startsWith("user"),
  });

  const changeRole = useMutation(updateCompanyMemberRole)[1];

  const [error, setError] = useState<string>();

  const doChangeRole = async (
    id: number,
    role: "admin" | "member" | "viewer"
  ) => {
    setError(undefined);

    const { error } = await changeRole({
      input: {
        companyId: parsedWorkspaceId,
        userId: id,
        role: role,
      },
    });

    if (error != null) {
      setError(error.message);
      return;
    }
  };

  return (
    <div className="w-full flex flex-col gap-1">
      <span className="text-sm text-grey-300 mt-2">Members</span>
      {data?.getCompanyWorkspace.members.members.map((member) => (
        <UserRow
          key={member.id}
          id={member.id}
          email={member.email}
          role={member.role}
          onRoleChange={doChangeRole}
        />
      ))}

      {error != null && (
        <div className="text-sm px-3 py-2 rounded-md bg-negative/10 text-negative/80">
          Could not change role.
          {error.slice(error.startsWith("[Graph") ? 14 : 0, error.length) +
            "."}{" "}
          <br />
          <br />
          Reach out to{" "}
          <a href="mailto:support@lighthousehq.com" className="underline">
            support@lighthousehq.com
          </a>{" "}
          for assistance if this persists.
        </div>
      )}
    </div>
  );
};

export const ManageTeamMembersModal = (props: {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const { open, onOpenChange } = props;
  const { selectedWorkspace } = useWorkspaces();

  const parsedWorkspaceId = parseInt(
    selectedWorkspace?.id.split("-")[1] ?? "-1"
  );

  const [{ data }] = useQuery({
    query: getCompanyMembers,
    variables: {
      id: parsedWorkspaceId,
    },
    pause:
      selectedWorkspace?.id == null ||
      selectedWorkspace?.id.startsWith("user") ||
      parsedWorkspaceId == -1,
  });

  const [inviteError, setInviteError] = useState<string>();

  const [emailInput, setEmailInput] = useState("");

  const debouncedEmailInput = useDebounce(emailInput, 150);

  const validEmail = zodSchema.safeParse(debouncedEmailInput).success;

  const inviteMember = useMutation(inviteCompanyMember)[1];

  const userInCompany = data?.getCompanyWorkspace.members.members.find(
    (member) => member.email === debouncedEmailInput
  );

  const doInviteMember = async () => {
    const companyId = parsedWorkspaceId;
    setInviteError(undefined);

    if (companyId == -1 || debouncedEmailInput == null) return;
    if (userInCompany || !validEmail) return;

    const { error } = await inviteMember({
      input: {
        companyId,
        email: debouncedEmailInput,
        role: "member",
      },
    });

    if (error != null) {
      setInviteError(error.message);
      return;
    }

    setEmailInput("");
  };

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      title="Manage team members"
      fitContent
      borderUnderTitle
    >
      <div className="w-full h-full flex flex-col gap-3 pr-6">
        <span className="text-sm text-grey-300 mt-4">
          Invited team members will receive an email invitation to join the
          company workspace.
        </span>

        <div className="flex flex-row items-center gap-2">
          <Input
            placeholder="Email address"
            type="email"
            value={emailInput}
            onValueChange={(value) => setEmailInput(value)}
          />

          <Button
            variant="primary"
            disabled={!validEmail || userInCompany != null}
            onClick={doInviteMember}
          >
            Invite
          </Button>
        </div>
        {inviteError != null && (
          <div className="text-sm px-3 py-2 rounded-md bg-negative/10 text-negative/80 break-all">
            Could not invite member.
            {inviteError.slice(
              inviteError.startsWith("[Graph") ? 14 : 0,
              inviteError.length
            ) + "."}{" "}
            <br />
            <br />
            Reach out to{" "}
            <a href="mailto:support@lighthousehq.com" className="underline">
              support@lighthousehq.com
            </a>{" "}
            for assistance if this persists.
          </div>
        )}
        <Members />
      </div>
    </Modal>
  );
};
