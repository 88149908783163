import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { IncomeInput, USStateSelect } from "@/components/inputs";

import { Field, SubkeyUploadArea, SubmitModal } from "@/components/form";
import { DraftIcon } from "@/components/icons/draft";
import {
  CountryAddressInput,
  DateInput,
  Input,
  PhoneNumber,
  PhoneNumberInput,
  TextArea,
} from "@/components/inputs";
import { Select } from "@/components/select";
import { useState } from "react";
import {
  OnboardingRouteProvider,
  useOnboarding,
} from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";
import { InputText, LabelText } from "@/components/typography";
import { Button } from "@/components/button";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { TrashIcon } from "@/components/icons/trash";
import { LockIcon } from "@/components/icons/sidebar";
import { getOnboarding } from "@/lib/queries";

import { ResultOf } from "@/lib/graphql";
import { WarningIcon } from "@/components/icons/warning";
import { cn } from "@/lib/cn";

const CompanyOnboardingInner = (props: {
  currentOnboardingNodeId: number;
  onboardingId: number;
}) => {
  const { nodeStatus, visaClass } = useOnboarding();
  const { data, doSetData, fetching } = useOnboardingNodeData(
    props.currentOnboardingNodeId
  );
  const worksiteAddress = data?.worksiteAddress?.value ?? {};

  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const h1bOrTn = visaClass === "h-1b" || visaClass === "tn";

  const validateRequiredFields = () => {
    if (data == null) return false;

    const minimumJobRequirements = data.minimumJobRequirements?.value as any;
    const supervisorInformation = data.supervisorInformation?.value as any;
    const additionalWorksites = data.additionalWorksites?.value as any[];

    const requiredFields = [
      // Employment offer letter
      data.signedEmploymentOfferLetter?.value,

      // Role details
      data.intendedRoleTitle?.value,
      data.intendedStartDate?.value,
      data.intendedSalary?.value,

      // Worksite address
      data.worksiteAddress?.value?.street,
      data.worksiteAddress?.value?.city,
      data.worksiteAddress?.value?.state,
      data.worksiteAddress?.value?.zip,
    ];

    // Additional H-1B specific validations
    if (visaClass === "h-1b" || visaClass === "tn") {
      const visaclassSpecificFields = [
        data.jobDescription?.value,

        // Minimum job requirements
        minimumJobRequirements?.minimumDegree,
        minimumJobRequirements?.fieldOfStudy,
        minimumJobRequirements?.yearsOfExperience,

        // Supervisor information
        supervisorInformation?.name,
        supervisorInformation?.title,
        supervisorInformation?.email,
        supervisorInformation?.phoneNumber,
      ];

      requiredFields.push(...visaclassSpecificFields);

      if ((additionalWorksites ?? []).length != 0) {
        // Validate each additional worksite
        const allWorksitesValid = additionalWorksites.every(
          (site) => site?.street && site?.city && site?.state && site?.zip
        );
        if (!allWorksitesValid) {
          return false;
        }
      }
    }

    return requiredFields.every((field) => field != null && field !== "");
  };

  const isFormValid = validateRequiredFields();

  return (
    <div>
      <SubmitModal
        open={submitModalOpen}
        setOpen={setSubmitModalOpen}
        onboardingId={props.onboardingId}
        currentNodeId={props.currentOnboardingNodeId}
        allRequiredFieldsCompleted={isFormValid}
      />

      {fetching && <Spinner />}
      {!fetching && data != null && (
        <div className="flex flex-col gap-4 pb-10 px-1">
          <Field
            id="signedEmploymentOfferLetter"
            label="Signed Employment Offer Letter"
            description="This document will help us prepare the Employment Verification letter. You can review, comment, and collaborate to ensure alignment on the role title, salary, and other details."
            required
          >
            <SubkeyUploadArea
              onboardingNodeId={props.currentOnboardingNodeId}
              subKey="signedEmploymentOfferLetter"
            />
          </Field>

          <Field
            id="intendedRoleTitle"
            label="Intended Role Title"
            description="This is the title the beneficiary will be assigned upon approval."
            required
          >
            <Input
              type="text"
              placeholder="e.g. Software Engineer"
              value={data.intendedRoleTitle?.value as string}
              onValueChange={(val) => doSetData("intendedRoleTitle", val)}
            />
          </Field>

          <Field
            id="intendedStartDate"
            label="Intended Start Date"
            description="This is the start date the beneficiary will be assigned upon approval."
            required
          >
            <DateInput
              value={
                data.intendedStartDate?.value != null
                  ? new Date(data.intendedStartDate.value)
                  : undefined
              }
              onValueChange={(val) =>
                doSetData("intendedStartDate", val?.toISOString())
              }
            />
          </Field>

          <Field
            id="intendedSalary"
            label="Intended Salary"
            description="This is the salary the beneficiary will be assigned upon approval."
            required
          >
            <IncomeInput
              value={data.intendedSalary?.value}
              onValueChange={(val) => doSetData("intendedSalary", val)}
            />
          </Field>

          <Field
            id="jobDescription"
            label="Job Description"
            description="List 4-5 job duties for the role"
            required={h1bOrTn}
          >
            <TextArea
              placeholder="Enter detailed job description"
              value={data.jobDescription?.value as string}
              onChange={(val: string) => doSetData("jobDescription", val)}
            />
          </Field>

          <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3 mr-12">
            <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
              <InputText className="text-grey-300 text-[12px]">
                Minimum Job Requirements {h1bOrTn ? "(Required)" : ""}
              </InputText>
              <div
                id="field-minimumJobRequirements"
                className="flex flex-col gap-2"
              >
                <Field label="Minimum Degree Required" required={h1bOrTn}>
                  <Select
                    value={
                      (data.minimumJobRequirements?.value as any)
                        ?.minimumDegree ?? ""
                    }
                    onValueChange={(val?: string) =>
                      doSetData("minimumJobRequirements", {
                        ...(data.minimumJobRequirements?.value ?? {}),
                        minimumDegree: val,
                      })
                    }
                    options={[
                      {
                        value: "No Degree Required",
                        label: "No Degree Required",
                      },
                      { value: "Bachelors", label: "Bachelors" },
                      { value: "Masters", label: "Masters" },
                      { value: "Doctorate", label: "Doctorate" },
                    ]}
                    placeholder="Select degree requirement"
                  />
                </Field>
                <Field label="Field of Study" required={h1bOrTn}>
                  <Input
                    type="text"
                    placeholder="Computer Science"
                    value={
                      (data.minimumJobRequirements?.value as any)
                        ?.fieldOfStudy ?? ""
                    }
                    onValueChange={(val) =>
                      doSetData("minimumJobRequirements", {
                        ...(data.minimumJobRequirements?.value ?? {}),
                        fieldOfStudy: val,
                      })
                    }
                  />
                </Field>
                <Field label="Years of Experience" required={h1bOrTn}>
                  <Input
                    type="text"
                    placeholder="5"
                    value={
                      (data.minimumJobRequirements?.value as any)
                        ?.yearsOfExperience ?? ""
                    }
                    onValueChange={(val) =>
                      doSetData("minimumJobRequirements", {
                        ...(data.minimumJobRequirements?.value ?? {}),
                        yearsOfExperience: val,
                      })
                    }
                  />
                </Field>
                <Field label="Specialized Skills/Certifications">
                  <Input
                    type="text"
                    placeholder="Python, AWS, etc."
                    value={
                      (data.minimumJobRequirements?.value as any)
                        ?.specializedSkills ?? ""
                    }
                    onValueChange={(val) =>
                      doSetData("minimumJobRequirements", {
                        ...(data.minimumJobRequirements?.value ?? {}),
                        specializedSkills: val,
                      })
                    }
                  />
                </Field>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3 mr-12">
            <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
              <InputText className="text-grey-300 text-[12px]">
                Direct Supervisor Information
              </InputText>
              <div
                id="field-supervisorInformation"
                className="flex flex-col gap-2"
              >
                <Field
                  label="Name"
                  description="Name of the direct supervisor who will be overseeing the beneficiary"
                  required={h1bOrTn}
                >
                  <Input
                    type="text"
                    placeholder="Full Name"
                    value={
                      (data.supervisorInformation?.value as any)?.name ?? ""
                    }
                    onValueChange={(val) =>
                      doSetData("supervisorInformation", {
                        ...(data.supervisorInformation?.value ?? {}),
                        name: val,
                      })
                    }
                  />
                </Field>
                <Field
                  label="Title"
                  description="Job title of the direct supervisor"
                  required={h1bOrTn}
                >
                  <Input
                    type="text"
                    placeholder="Job Title"
                    value={
                      (data.supervisorInformation?.value as any)?.title ?? ""
                    }
                    onValueChange={(val) =>
                      doSetData("supervisorInformation", {
                        ...(data.supervisorInformation?.value ?? {}),
                        title: val,
                      })
                    }
                  />
                </Field>
                <Field
                  label="Email"
                  description="Work email address of the direct supervisor"
                  required={h1bOrTn}
                >
                  <Input
                    type="text"
                    placeholder="Email Address"
                    value={
                      (data.supervisorInformation?.value as any)?.email ?? ""
                    }
                    onValueChange={(val) =>
                      doSetData("supervisorInformation", {
                        ...(data.supervisorInformation?.value ?? {}),
                        email: val,
                      })
                    }
                  />
                </Field>
                <Field
                  label="Phone Number"
                  description="Work phone number of the direct supervisor"
                  required={h1bOrTn}
                >
                  <PhoneNumberInput
                    placeholder="Enter phone number"
                    value={
                      (data.supervisorInformation?.value as any)?.phoneNumber
                    }
                    onValueChange={(val: PhoneNumber | undefined) =>
                      doSetData("supervisorInformation", {
                        ...(data.supervisorInformation?.value ?? {}),
                        phoneNumber: val,
                      })
                    }
                  />
                </Field>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3 mr-12">
            <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
              <InputText className="text-grey-300 text-[12px]">
                What is the employee's worksite address?
              </InputText>
              <div id="field-worksiteAddress" className="flex flex-col gap-2">
                <Field label="Address" className="pr-4" required>
                  <Input
                    placeholder="123 Main St"
                    type="text"
                    value={worksiteAddress.street ?? ""}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        street: val,
                        country: "United States",
                      })
                    }
                    autoComplete={"street-address"}
                  />
                </Field>
                <Field label="City" className="pr-4" required>
                  <Input
                    placeholder="San Francisco"
                    type="text"
                    value={worksiteAddress.city ?? ""}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        city: val,
                        country: "United States",
                      })
                    }
                    autoComplete={"address-level2"}
                  />
                </Field>
                <div className="flex flex-row gap-2 w-full">
                  <Field label="State" className="pr-4" required>
                    <USStateSelect
                      value={worksiteAddress.state ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          state: val,
                          country: "United States",
                        })
                      }
                    />
                  </Field>
                  <Field label="ZIP / Postal Code" className="pr-4" required>
                    <Input
                      placeholder="94102"
                      type="text"
                      value={worksiteAddress.zip ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          zip: val,
                          country: "United States",
                        })
                      }
                      autoComplete={"postal-code"}
                    />
                  </Field>
                </div>
                <Field label="Country" className="pr-4" required>
                  <CountryAddressInput
                    value={worksiteAddress.country ?? "United States"}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        country: val as string,
                      })
                    }
                    priorityCountries={["US"]}
                    disabled
                  />
                </Field>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <LabelText
              className={cn("flex flex-row items-center text-grey-300 text-sm")}
              as="span"
            >
              Additional Worksites
            </LabelText>
            <p className="text-xs text-grey-400">
              Add all other worksites where the employee may work.
            </p>
            {((data.additionalWorksites?.value as any[]) ?? []).length > 0 && (
              <>
                {((data.additionalWorksites?.value as any[]) ?? []).map(
                  (site, index) => (
                    <div
                      key={index}
                      className="pt-1.5 px-3 pb-4 gap-1 flex flex-col bg-grey-700 rounded-lg relative"
                    >
                      <button
                        onClick={() => {
                          const sites = [
                            ...((data.additionalWorksites?.value as any[]) ??
                              []),
                          ];
                          sites.splice(index, 1);
                          doSetData("additionalWorksites", sites);
                        }}
                        className="absolute right-3 top-5"
                      >
                        <TrashIcon className="fill-grey-300 w-3.5 h-3.5" />
                      </button>
                      <Field label="Address" className="pr-4" required>
                        <Input
                          placeholder="123 Main St"
                          type="text"
                          value={site?.street ?? ""}
                          onValueChange={(val) => {
                            const sites = [
                              ...((data.additionalWorksites?.value as any[]) ??
                                []),
                            ];
                            sites[index] = { ...sites[index], street: val };
                            doSetData("additionalWorksites", sites);
                          }}
                          autoComplete={"street-address"}
                        />
                      </Field>
                      <Field label="City" className="pr-4" required>
                        <Input
                          placeholder="San Francisco"
                          type="text"
                          value={site?.city ?? ""}
                          onValueChange={(val) => {
                            const sites = [
                              ...((data.additionalWorksites?.value as any[]) ??
                                []),
                            ];
                            sites[index] = { ...sites[index], city: val };
                            doSetData("additionalWorksites", sites);
                          }}
                          autoComplete={"address-level2"}
                        />
                      </Field>
                      <div className="flex flex-row gap-2 w-full">
                        <Field label="State" className="pr-4" required>
                          <USStateSelect
                            value={site?.state ?? ""}
                            onValueChange={(val) => {
                              const sites = [
                                ...((data.additionalWorksites
                                  ?.value as any[]) ?? []),
                              ];
                              sites[index] = {
                                ...sites[index],
                                state: val,
                              };
                              doSetData("additionalWorksites", sites);
                            }}
                          />
                        </Field>
                        <Field
                          label="ZIP / Postal Code"
                          className="pr-4"
                          required
                        >
                          <Input
                            placeholder="94102"
                            type="text"
                            value={site?.zip ?? ""}
                            onValueChange={(val) => {
                              const sites = [
                                ...((data.additionalWorksites
                                  ?.value as any[]) ?? []),
                              ];
                              sites[index] = { ...sites[index], zip: val };
                              doSetData("additionalWorksites", sites);
                            }}
                            autoComplete={"postal-code"}
                          />
                        </Field>
                      </div>
                    </div>
                  )
                )}
              </>
            )}
            <div className="flex flex-col gap-2">
              <button
                className="text-xs text-blue flex flex-row gap-1.5 items-center"
                onClick={() => {
                  const sites = [
                    ...((data.additionalWorksites?.value as any[]) ?? []),
                  ];
                  sites.push({
                    street: "",
                    city: "",
                    state: "",
                    zip: "",
                  });
                  doSetData("additionalWorksites", sites);
                }}
              >
                <PlusCircledIcon className="w-3 h-3" />
                {((data.additionalWorksites?.value as any[]) ?? []).length === 0
                  ? "Add additional worksite"
                  : "Add another worksite"}
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <LabelText
              className={cn("flex flex-row items-center text-grey-300 text-sm")}
              as="span"
            >
              Remote Work Address
            </LabelText>

            <p className="text-xs text-grey-400">
              If the employee will work remotely, add the address where they
              will work.
            </p>

            <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3 mr-12">
              <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
                <div
                  id="field-remoteWorkAddress"
                  className="flex flex-col gap-2"
                >
                  <Field label="Address" className="pr-4">
                    <Input
                      placeholder="123 Main St"
                      type="text"
                      value={
                        (data.remoteWorkAddress?.value as any)?.street ?? ""
                      }
                      onValueChange={(val) =>
                        doSetData("remoteWorkAddress", {
                          ...(data.remoteWorkAddress?.value ?? {}),
                          street: val,
                        })
                      }
                      autoComplete={"street-address"}
                    />
                  </Field>
                  <Field label="City" className="pr-4">
                    <Input
                      placeholder="San Francisco"
                      type="text"
                      value={(data.remoteWorkAddress?.value as any)?.city ?? ""}
                      onValueChange={(val) =>
                        doSetData("remoteWorkAddress", {
                          ...(data.remoteWorkAddress?.value ?? {}),
                          city: val,
                        })
                      }
                      autoComplete={"address-level2"}
                    />
                  </Field>
                  <div className="flex flex-row gap-2 w-full">
                    <Field label="State" className="pr-4">
                      <USStateSelect
                        value={
                          (data.remoteWorkAddress?.value as any)?.state ?? ""
                        }
                        onValueChange={(val) =>
                          doSetData("remoteWorkAddress", {
                            ...(data.remoteWorkAddress?.value ?? {}),
                            state: val,
                          })
                        }
                      />
                    </Field>
                    <Field label="ZIP / Postal Code" className="pr-4">
                      <Input
                        placeholder="94102"
                        type="text"
                        value={
                          (data.remoteWorkAddress?.value as any)?.zip ?? ""
                        }
                        onValueChange={(val) =>
                          doSetData("remoteWorkAddress", {
                            ...(data.remoteWorkAddress?.value ?? {}),
                            zip: val,
                          })
                        }
                        autoComplete={"postal-code"}
                      />
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-end pr-12">
            {nodeStatus !== "submitted_for_review" && (
              <Button
                variant="primary"
                onClick={() => setSubmitModalOpen(true)}
              >
                <DraftIcon />
                Submit for Review
              </Button>
            )}

            {nodeStatus === "submitted_for_review" && (
              <Button
                variant="primary"
                disabled
                className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
              >
                <LockIcon />
                Submitted
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const EmployerDetailsOnboardingNode = (props: {
  onboardingNodeId: number;
  onboardingId: number;
  status?: ResultOf<
    typeof getOnboarding
  >["onboarding"]["groups"]["0"]["nodes"][0]["internalStatus"];
}) => {
  const { onboardingNodeId, onboardingId, status } = props;

  return (
    <div className="w-full flex flex-col gap-4">
      <OnboardingRouteProvider
        onboardingId={onboardingId}
        currentGroup="employer_documents"
        currentNodeId={onboardingNodeId}
      >
        {(status === "submitted_for_review" || status === "approved") && (
          <div className="max-w-full flex flex-row items-center bg-grey-100 gap-2 rounded-lg py-3 px-4 mt-2 text-grey-500 text-xs mr-12">
            <LockIcon />
            The fields below have been submitted, and are not editable.
          </div>
        )}

        {status === "rejected" && (
          <div className="max-w-full w-[50%] flex flex-row items-center bg-grey-100 gap-2 rounded-lg py-3 px-4 mr-12">
            <WarningIcon />
            <span className="text-grey-800 text-xs">
              Fields in this step have been rejected
            </span>
          </div>
        )}

        <CompanyOnboardingInner
          currentOnboardingNodeId={onboardingNodeId}
          onboardingId={onboardingId}
        />
      </OnboardingRouteProvider>
    </div>
  );
};
