import { RefObject, useEffect, useState } from "react";

const computeRect = (element: HTMLElement | null) => {
  if (element == null) return { width: 0, height: 0, x: 0, y: 0 };

  const { width, height, x, y } = element.getBoundingClientRect();

  return { width, height, x, y };
};

export const useResizeObserver = (ref: RefObject<HTMLElement | null>) => {
  const [rect, setRect] = useState(computeRect(ref.current));

  useEffect(() => {
    if (ref.current == null) return;

    setRect(computeRect(ref.current));

    const ob = new ResizeObserver(() => setRect(computeRect(ref.current)));
    ob.observe(ref.current);

    return () => ob.disconnect();
  }, [ref]);

  return rect;
};
