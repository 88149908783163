import { createContext, useContext, useState } from "react";
import {
  getOnboarding,
  getOnboardingNode,
  subscribeToOnboardingChange,
} from "@/lib/queries";
import { useQuery, useSubscription } from "urql";
import { ResultOf } from "gql.tada";
import { calculateProgress, OnboardingProgress } from "@/lib/progress";
import { FormValues } from "@/lib/formValues";
import { redirect, useNavigate } from "@tanstack/react-router";

export type OnboardingNodeGroups = ResultOf<
  typeof getOnboarding
>["onboarding"]["groups"][0]["id"];

export type OnboardingNodes = NonNullable<
  NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
>[0]["nodes"];

type OnboardingContextType = {
  onboardingId: number;
  visaClass: string;
  currentOnboardingNodeId?: number;
  currentOnboardingGroup?: OnboardingNodeGroups;
  nextStep: () => void;
  previousStep: () => void;
  canGoBack: boolean;
  canGoForward: boolean;
  switchGroup: (group: OnboardingNodeGroups) => void;

  allOnboardingGroups: NonNullable<
    NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
  >;
  groupNodes: NonNullable<
    NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
  >[0]["nodes"];
  nodeStatus?: NonNullable<
    NonNullable<
      ResultOf<typeof getOnboardingNode>["onboardingNode"]
    >["internalStatus"]
  >;
  availableGroups: OnboardingNodeGroups[];
  switchNode: (nodeId: number) => void;
  progress: OnboardingProgress;

  rejectedFields: string[];
  currentRejectedFieldIndex?: number;
  setCurrentRejectedFieldIndex: (value: number) => void;

  isCurrentNodeLastInGroup: boolean;
  refetchOnboarding: () => void;
  currentOnboardingNodeName: string;
};

const OnboardingContext = createContext<OnboardingContextType | null>(null);

const isThisLastNodeInGroup = (
  selectedNode: {
    group: OnboardingNodeGroups | undefined;
    nodeId: number | undefined;
  },
  data: ResultOf<typeof getOnboarding>
) => {
  if (selectedNode.group == null || selectedNode.nodeId == null) return false;
  if (["gate", "dashboard", "review"].includes(selectedNode.group))
    return false;

  const group = data?.onboarding.groups.find(
    (x) => x.id === selectedNode.group
  );

  if (group == null) return false;
  if (group.nodes.length === 0) return false;

  return group.nodes[group.nodes.length - 1].id === selectedNode.nodeId;
};

export const OnboardingRouteProvider = (props: {
  children: React.ReactNode;
  onboardingId: number;
  currentGroup: OnboardingNodeGroups;
  currentNodeId: number;
}) => {
  const nav = useNavigate();
  const [currentRejectedFieldIndex, setCurrentRejectedFieldIndex] =
    useState<number>();

  const [{ data }, refetch] = useQuery({
    query: getOnboarding,
    variables: { id: props.onboardingId },
    context: {
      requestPolicy: "cache-first",
    },
  });

  const nonEmptyGroups = data?.onboarding.groups.filter(
    (x) => x.nodes.length > 0
  );

  useSubscription(
    {
      query: subscribeToOnboardingChange,
      variables: {
        id: props.onboardingId,
      },
    },
    (_, data) => {
      if (data?.onboarding?.id) {
        refetch({ requestPolicy: "network-only" });
      }
      return data;
    }
  );

  const nextStep = () => {
    if (data == null) return;
    if (nonEmptyGroups == null) return;

    const currentGroup = nonEmptyGroups.find(
      (x) => x.id === props.currentGroup
    );

    const currentGroupIndex = nonEmptyGroups.findIndex(
      (x) => x.id === props.currentGroup
    );

    if (currentGroup == null) return;

    if (
      currentGroup.nodes[currentGroup.nodes.length - 1].id ===
      props.currentNodeId
    ) {
      if (currentGroupIndex === nonEmptyGroups.length - 1) {
        nav({ to: "/home" });
        return;
      }

      const nextGroup = nonEmptyGroups[currentGroupIndex + 1];
      const firstNodeInNewGroup = nextGroup.nodes[0];

      nav({
        to: `/group/$groupId`,
        params: { groupId: nextGroup.id },
        search: { nodeId: firstNodeInNewGroup.id },
        replace: true,
      });
      return;
    } else {
      const nodes = currentGroup.nodes;

      const currentNodeIndex = nodes.findIndex(
        (node) => node.id === props.currentNodeId
      );

      nav({
        to: `/group/$groupId`,
        params: { groupId: currentGroup.id },
        search: { nodeId: nodes[currentNodeIndex + 1].id },
        replace: true,
      });
    }
  };

  const previousStep = () => {
    if (data == null) return;
    if (nonEmptyGroups == null) return;
    if (props.currentGroup === "gate") {
      return;
    }

    const currentGroupIndex = nonEmptyGroups.findIndex(
      (x) => x.id === props.currentGroup
    );

    if (currentGroupIndex === -1) return;

    const currentGroup = nonEmptyGroups[currentGroupIndex];

    if (currentGroup.nodes[0].id === props.currentNodeId) {
      if (props.currentGroup === "about_you") {
        redirect({ to: "/home" });
        setCurrentRejectedFieldIndex(undefined);
        return;
      }

      const previousGroup = nonEmptyGroups[currentGroupIndex - 1];
      if (previousGroup == null) return;

      const lastNodeInPreviousGroup =
        previousGroup.nodes[previousGroup.nodes.length - 1];

      nav({
        to: `/group/$groupId`,
        params: { groupId: previousGroup.id },
        search: { nodeId: lastNodeInPreviousGroup.id },
        replace: true,
      });
      setCurrentRejectedFieldIndex(undefined);

      return;
    } else {
      const nodes =
        nonEmptyGroups.find((x) => x.id === props.currentGroup)?.nodes ?? [];

      const currentNodeIndex = nodes.findIndex(
        (node) => node.id === props.currentNodeId
      );

      nav({
        to: `/group/$groupId`,
        params: { groupId: currentGroup.id },
        search: { nodeId: nodes[currentNodeIndex - 1].id },
        replace: true,
      });
      setCurrentRejectedFieldIndex(undefined);
    }
  };

  const flatNodes =
    nonEmptyGroups
      ?.filter((x) => x.id !== "gate")
      .flatMap((group) => {
        return group.nodes.map((node) => ({ ...node, group: group.id }));
      }) ?? [];

  const groupNodes =
    nonEmptyGroups?.find((x) => x.id === props.currentGroup)?.nodes ?? [];

  const currentOnboardingNode = groupNodes.find(
    (node) => node.id === props.currentNodeId
  );

  const rejectedFields = Object.keys(
    currentOnboardingNode?.content ?? {}
  ).filter(
    (field) =>
      (currentOnboardingNode?.content as unknown as FormValues)[field]
        ?.status === "rejected"
  );

  const availableGroups = nonEmptyGroups?.map((x) => x.id) ?? [];

  const switchGroup = (group: OnboardingNodeGroups) => {
    if (data == null) return;
    const nodes = nonEmptyGroups?.find((x) => x.id === group)?.nodes ?? [];

    if (nodes.length === 0) {
      nav({
        to: `/group/$groupId`,
        params: { groupId: group },
        replace: true,
      });
      setCurrentRejectedFieldIndex(undefined);
      return;
    }

    nav({
      to: `/group/$groupId`,
      params: { groupId: group },
      search: { nodeId: nodes[0].id },
      replace: true,
    });
    setCurrentRejectedFieldIndex(undefined);
  };

  const switchNode = (nodeId: number) => {
    if (data == null) return;

    nav({
      to: `/group/$groupId`,
      params: { groupId: props.currentGroup },
      search: { nodeId: nodeId },
    });
  };

  const canGoBack =
    flatNodes.length > 0 && flatNodes[0].id != props.currentNodeId;
  const canGoForward =
    flatNodes.findIndex((node) => node.id === props.currentNodeId) <
    flatNodes.length - 1;

  const progress = calculateProgress(data?.onboarding.groups ?? []);

  const isCurrentNodeLastInGroup =
    props.currentNodeId == null || data == null
      ? false
      : isThisLastNodeInGroup(
          { group: props.currentGroup, nodeId: props.currentNodeId },
          data
        );

  const doChangeCurrentRejectedFieldIndex = (value: number) => {
    setCurrentRejectedFieldIndex(value);
    const selectedRejectedField = rejectedFields[value];

    const divElement = document.getElementById(
      `field-${selectedRejectedField}`
    );

    if (divElement == null) return;
    setTimeout(function () {
      divElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      divElement.focus();
    }, 100);
  };

  const formattedNodeType = currentOnboardingNode?.type
    .split("-")
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");

  const shouldShowPrefix = props.currentGroup === "work_experience";

  const currentOnboardingNodeName =
    (currentOnboardingNode?.name.trim().length === 0
      ? formattedNodeType
      : shouldShowPrefix
        ? `${formattedNodeType} - ${currentOnboardingNode?.name}`
        : currentOnboardingNode?.name) ?? "";

  const nodeStatus = currentOnboardingNode?.internalStatus;

  return (
    <OnboardingContext.Provider
      value={{
        onboardingId: props.onboardingId,
        visaClass: data?.onboarding.visaClass ?? "",
        canGoBack,
        canGoForward,
        currentOnboardingNodeId: props.currentNodeId,
        currentOnboardingGroup: props.currentGroup,
        allOnboardingGroups: data?.onboarding.groups ?? [],
        nodeStatus,
        nextStep,
        previousStep,
        switchGroup,
        groupNodes,
        switchNode,
        availableGroups,
        progress,
        rejectedFields,
        currentRejectedFieldIndex,
        setCurrentRejectedFieldIndex: doChangeCurrentRejectedFieldIndex,
        isCurrentNodeLastInGroup,
        refetchOnboarding: refetch,
        currentOnboardingNodeName,
      }}
    >
      {props.children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context)
    throw new Error("useOnboarding must be used within OnboardingProvider");
  return context;
};
