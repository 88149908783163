import { Field, OnboardingForm } from "@/components/form";
import { Checkbox, ListTextInput, TextArea } from "@/components/inputs";
import { URLReview } from "@/components/urlReview";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";
import { useEffect } from "react";

export const Press = () => {
  const { currentOnboardingNodeId, nodeStatus, currentOnboardingNodeName } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  useEffect(() => {
    if (data == null) return;
    if (
      data.publications == null ||
      data.publications.value == null ||
      data.publications.value.length === 0
    ) {
      if (data.hasOtherPublications?.value === true) return;
      doSetData("hasOtherPublications", true);
    }
  }, [data]);

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        Published Materials, also called Press, is the criteria where we'll
        highlight articles from reputable sources that highlight you or your
        achievements. Major media can be interpreted as well-recognized and
        credible publications to support your application.
      </p>
      {(data.publications?.value ?? []).length > 0 && (
        <>
          <Field>
            <URLReview
              title="Good news! We've already found some press articles about you or your work."
              description="Let us know if these are correct."
              links={data?.publications?.value ?? []}
              updateLinks={(links) => doSetData("publications", links)}
            />
          </Field>
          <Checkbox
            label="I have other press articles that I'd like Lighthouse to review."
            checked={data.hasOtherPublications?.value ?? false}
            onCheckedChange={(value) => {
              doSetData("hasOtherPublications", value);
            }}
            disabled={nodeStatus === "submitted_for_review"}
            className="mr-12"
          />
        </>
      )}
      {data.hasOtherPublications?.value && (
        <Field
          id="otherPublications"
          label="Press articles"
          description="Add any other press articles that you'd like Lighthouse to review."
          rejectionBorderClassName="px-2 py-1"
        >
          <ListTextInput
            value={data.otherPublications?.value}
            onValueChange={(value) => doSetData("otherPublications", value)}
            placeholder="https://forbes.com/article"
          />
        </Field>
      )}

      <Field
        id="additionalComments"
        label="Additional comments"
        description="Please leave any additional comments you'd like the Lighthouse team to review as it relates to this criteria"
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>
    </OnboardingForm>
  );
};
