import {
  ExternalEditorContextProvider,
  useEditorContext,
} from "@/providers/editorProvider";
import { useNavigate } from "@tanstack/react-router";
import { TextEditorComments } from "@/components/textEditorComments";
import { TextEditor } from "@/components/textEditor";
import { useEffect, useState } from "react";
import { Mark } from "@/components/mark";
import {
  ArrowRightIcon,
  CheckCircledIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";
import { Button } from "@/components/button";
import {
  FaRegCircleCheck,
  FaRegCommentDots,
  FaSignature,
} from "react-icons/fa6";
import { customerApi } from "@lighthouse/api";
import { LoadingOverlay } from "@/components/loadingOverlay";

import { FiX } from "react-icons/fi";

import { Modal } from "./modal";

// CommentsProvider functionality is now merged with EditorProvider

export type LetterActionType = {
  id: number;
  type: string;
  letterId: number;
  externalEmail: string;
  externalName: string;
  status: string;
  beneficiaryName: string;
  additionalNotes?: string;
  followUpLetterAction?: {
    letterActionId: number;
    type: string;
    status: string;
  };
};

export const FailiureModal = (props: {
  open: boolean;
  setOpen: (alue: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Oops! Something Went Wrong"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Looks like something went wrong. Please try again.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export const InformationModal = (props: {
  isBeneficiary: boolean;
  beneficiaryName: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, isBeneficiary, beneficiaryName, setOpen } = props;

  const formattedBeneficiaryName =
    beneficiaryName[beneficiaryName.length - 1] == "s"
      ? beneficiaryName + "'"
      : beneficiaryName + "'s";

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Reviewing a letter"
      fitContent
      contentClassName="w-[650px] max-w-[80%] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        {!isBeneficiary && (
          <p className="text-sm text-grey-300">
            Your review is needed regarding this letter in support of{" "}
            {formattedBeneficiaryName} upcoming work visa application.
          </p>
        )}

        <p className="text-sm text-grey-300">
          Please review carefully the facts presented in this letter. This
          letter will be submitted to USCIS, the government agency responsible
          for citizenship and immigration.
        </p>

        <p className="text-sm text-grey-200">A few key points about USCIS:</p>

        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-start justify-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              They prefer a grandiose tone in these letters. Although this may
              seem unusual, it is standard practice and enhances the likelihood
              of approval.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Since USCIS may not be familiar with the specific industry, it is
              crucial to clearly outline the applicant's achievements and
              expertise in related or adjacent fields.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Processing times can be lengthy and the stakes are high. Prompt
              review is highly recommended.
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() => setOpen(false)}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ConfirmApproveModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onApprove: () => void;
}) => {
  const { open, setOpen, onApprove } = props;

  const [disabled, setDisabled] = useState(false);

  const approve = () => {
    if (disabled) return;

    setDisabled(true);
    setOpen(false);
    onApprove();
  };

  return (
    <Modal
      open={open}
      onOpenChange={() => setOpen(false)}
      title="Confirm Approval"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          You have suggested changes to this letter. Are you sure you want to
          approve it?
        </p>
      </div>

      <div className="flex flex-row justify-end gap-2">
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={disabled}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={approve}
        >
          Approve
        </Button>
      </div>
    </Modal>
  );
};

const StandaloneReviewHintPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <div className="flex flex-row items-center bg-grey-200 px-4 py-3 gap-2.5 absolute rounded-lg top-16 shadow-action-button">
        <FaRegCommentDots color="#3a87c2" />

        <span className="text-sm text-grey-800">
          Highlight any text to leave a comment and propose changes. If
          everything looks good, you can approve the letter.
        </span>

        <button onClick={() => setOpen(false)}>
          <FiX width={10} className="text-grey-400" />
        </button>
      </div>
    )
  );
};

const ReviewWithFollowUpSignHintPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <div className="flex flex-row items-center bg-grey-200 px-4 py-3 gap-2.5 absolute rounded-lg top-4 shadow-action-button">
        <FaRegCommentDots color="#3a87c2" />

        <span className="text-sm text-grey-800">
          Highlight any text to leave a comment and propose changes. If
          everything looks good, scroll down to sign the letter.
        </span>

        <button onClick={() => setOpen(false)}>
          <FiX width={10} className="text-grey-400" />
        </button>
      </div>
    )
  );
};

const SubmitReviewButton = (props: {
  letterActionId: number;
  hasSuggestedChanges: boolean;
  status: string;
  token: string;
  isReviewWithFollowUpSign: boolean;
  refetch: () => void;
}) => {
  const {
    letterActionId,
    hasSuggestedChanges,
    status,
    token,
    isReviewWithFollowUpSign,
    refetch,
  } = props;

  const [loading, setLoading] = useState(false);
  const [confirmApprovalModalOpen, setConfirmApprovalModalOpen] =
    useState(false);

  const [failureModalOpen, setFailureModalOpen] = useState(false);

  const approveReviewRequest = async () => {
    if (isReviewWithFollowUpSign) {
      return;
    }

    if (loading) return;
    setLoading(true);

    const res = await customerApi.submitLetterAction({
      letterActionId: letterActionId,
      token: token,
      action: "approve",
    });

    setLoading(false);

    if (!res.success) {
      setFailureModalOpen(true);
      return;
    }

    refetch();
  };

  return (
    <div>
      <LoadingOverlay isLoading={loading} />
      <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />
      <ConfirmApproveModal
        open={confirmApprovalModalOpen}
        setOpen={setConfirmApprovalModalOpen}
        onApprove={approveReviewRequest}
      />

      {status === "approved" && (
        <Button
          variant="primary"
          disabled
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Letter Approved
        </Button>
      )}

      {status !== "approved" && (
        <Button
          onClick={
            hasSuggestedChanges
              ? () => setConfirmApprovalModalOpen(true)
              : approveReviewRequest
          }
          variant="primary"
          disabled={loading}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Approve Letter
        </Button>
      )}
    </div>
  );
};

const StandaloneSignaturePopup = () => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <div className="flex flex-row items-center bg-grey-200 px-4 py-3 gap-2.5 absolute rounded-lg top-4 shadow-action-button">
        <FaSignature color="#3a87c2" />

        <span className="text-sm text-grey-800">
          Scroll down to find the spot where you can sign the document
        </span>

        <button onClick={() => setOpen(false)}>
          <FiX width={10} className="text-grey-400" />
        </button>
      </div>
    )
  );
};

const SignatureSubmitFailedPopup = (props: {
  onSubmit: () => void;
  letterActionId: number;
  status: string;
  hidden: boolean;
}) => {
  const { onSubmit, letterActionId, status, hidden } = props;

  const { editor } = useEditorContext();

  const [isSigned, setIsSigned] = useState(false);

  const checkForSignature = () => {
    try {
      if (!editor || editor.isDestroyed) {
        setIsSigned(false);
        return;
      }

      // Check for signature node safely
      const signedNode = editor.$node("signature", {
        letterActionId: letterActionId.toString(),
        signed: true,
      });

      setIsSigned(signedNode != null);
    } catch (error) {
      console.error("Error checking for signature:", error);
      setIsSigned(false);
    }
  };

  // Use a safer approach for checking editor changes
  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      checkForSignature();

      // Set up a transaction handler rather than relying on rerenders
      const handler = () => {
        if (editor && !editor.isDestroyed) {
          checkForSignature();
        }
      };

      editor.on("transaction", handler);

      return () => {
        if (editor && !editor.isDestroyed) {
          editor.off("transaction", handler);
        }
      };
    }
  }, [editor, letterActionId]);

  if (hidden || !isSigned || status === "approved") {
    return null;
  }

  return (
    <div className="z-10 flex flex-col bg-grey-200 p-4 gap-2.5 absolute rounded-lg bottom-4 right-4 shadow-action-button w-[300px]">
      <div className="flex flex-row items-center gap-1.5">
        <span className="text-sm text-grey-800">Something went wrong!</span>
      </div>

      <span className="text-sm text-grey-500 mb-2">
        There was an issue submitting your signature. Please try again.
      </span>

      <Button onClick={onSubmit} variant="secondary">
        Submit
      </Button>
    </div>
  );
};

const ApprovedBanner = (props: {
  isReviewWithFollowUpSign: boolean;
  isStandaloneSign: boolean;
  data: LetterActionType;
}) => {
  const { isReviewWithFollowUpSign, isStandaloneSign, data } = props;

  return (
    <div className="flex flex-row items-center gap-4 bg-grey-600 shadow-bubble p-5 w-[825px] rounded-lg text-sm text-grey-300">
      <div className="h-9 w-9 bg-[#D9D9D9] rounded-full flex items-center justify-center text-grey-300 text-lg">
        <FaRegCircleCheck />
      </div>

      {isReviewWithFollowUpSign ? (
        <span>
          {data.followUpLetterAction?.status === "approved"
            ? "You have approved and signed this letter. You can close this window."
            : "You have approved this letter. Scroll down to sign the letter."}
        </span>
      ) : isStandaloneSign ? (
        <span>You have signed this letter. You can close this window.</span>
      ) : (
        <span>
          You have approved this letter. You can now close this window.
        </span>
      )}
    </div>
  );
};

const ChangesSuggestedPopup = (props: {
  hideButton?: boolean;
  isReviewWithFollowUpSign: boolean;
  signatureStatus: string;
}) => {
  const { hideButton, isReviewWithFollowUpSign, signatureStatus } = props;

  const nav = useNavigate();

  return (
    <div className="z-10 flex flex-col bg-grey-200 p-4 gap-2.5 absolute rounded-lg bottom-4 right-4 shadow-action-button w-[300px]">
      <div className="flex flex-row items-center gap-1.5">
        <CheckCircledIcon color="#81CA67" />
        <span className="text-sm text-grey-800 text-nowrap">
          We've received your comments
        </span>
      </div>

      <span className="text-sm text-grey-500 mb-2 text-wrap">
        The Lighthouse team will review your suggestions and revert shortly.
        {isReviewWithFollowUpSign &&
          (signatureStatus === "pending" || signatureStatus === "sent") && (
            <span>
              <br /> <br />
              If you want, you can sign the letter now while the team implements
              your suggestions.
            </span>
          )}
        {isReviewWithFollowUpSign && signatureStatus === "approved" && (
          <span>
            <br /> <br />
            Thank you for signing! <br /> <br />
            You can close this window. The Lighthouse team will reach out if any
            further action is needed.
          </span>
        )}
      </span>

      {!hideButton && (
        <Button onClick={() => nav({ to: "/home" })} variant="secondary">
          Return to Home
        </Button>
      )}
    </div>
  );
};

const MainContent = (props: {
  data: LetterActionType;
  token: string;
  refetch: () => void;
  isStandaloneSign: boolean;
  isReviewWithFollowUpSign: boolean;
  isStandaloneReview: boolean;
  status: string;
  loading: boolean;
  onSignature: () => void;
  setInformationModalOpen: (value: boolean) => void;
}) => {
  const {
    data,
    token,
    refetch,
    isStandaloneSign,
    isReviewWithFollowUpSign,
    isStandaloneReview,
    status,
    loading,
    onSignature,
    setInformationModalOpen,
  } = props;

  // EditorContext now includes comments functionality

  return (
    <div className="relative w-full h-full flex flex-col items-center overflow-hidden">
      {data.status === "changes-requested" && (
        <ChangesSuggestedPopup
          hideButton
          isReviewWithFollowUpSign={isReviewWithFollowUpSign}
          signatureStatus={
            isReviewWithFollowUpSign
              ? data.followUpLetterAction!.status
              : status
          }
        />
      )}

      {data.status === "sent" && isStandaloneReview && (
        <StandaloneReviewHintPopup />
      )}

      {isReviewWithFollowUpSign && data.status === "sent" && (
        <ReviewWithFollowUpSignHintPopup />
      )}

      {data.status === "sent" && isStandaloneSign && (
        <StandaloneSignaturePopup />
      )}

      {(isStandaloneSign || isReviewWithFollowUpSign) && (
        <SignatureSubmitFailedPopup
          onSubmit={onSignature}
          letterActionId={
            isReviewWithFollowUpSign
              ? data.followUpLetterAction!.letterActionId
              : data.id
          }
          status={
            isReviewWithFollowUpSign
              ? (data.followUpLetterAction?.status ?? "sent")
              : data.status
          }
          hidden={loading}
        />
      )}

      <div className="w-full flex flex-row items-center justify-between px-6 h-20 mb-3 flex-shrink-0">
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center">
          <Mark />
          <h1 className="font-serif font-[600] text-base tracking-[-0.5%]">
            Lighthouse
          </h1>
        </div>

        <div className="flex flex-row items-center gap-3">
          <button
            onClick={() => setInformationModalOpen(true)}
            className="bg-grey-200 shadow-action-button h-8 w-8 rounded-full flex items-center justify-center"
          >
            <InfoCircledIcon color="white" width={20} />
          </button>

          {isStandaloneReview && (
            <SubmitReviewButton
              letterActionId={data.id}
              hasSuggestedChanges={data.status === "changes-requested"}
              token={token}
              isReviewWithFollowUpSign={false}
              status={data.status}
              refetch={refetch}
            />
          )}
        </div>
      </div>

      <div className="flex flex-row gap-2 w-full overflow-hidden justify-center">
        <div className="flex flex-col gap-4 px-10 pt-10">
          {data.status === "approved" && (
            <ApprovedBanner
              isReviewWithFollowUpSign={isReviewWithFollowUpSign}
              isStandaloneSign={isStandaloneSign}
              data={data}
            />
          )}

          <div className="no-scrollbar overflow-y-auto">
            <TextEditor
              key={data.letterId + data.status}
              disabled={data.status === "approved"}
              className="mb-10"
            />
          </div>
        </div>

        {!isStandaloneSign && (
          <div className="w-fit overflow-y-auto mt-10 no-scrollbar">
            <div className="px-10 pb-10">
              <TextEditorComments className="overflow-visible" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ExternalLetterEditor = (props: {
  data: LetterActionType;
  hideInitialInfoModal: boolean;
  token: string;
  reviewed?: boolean;
  refetch: () => void;
}) => {
  const { data, hideInitialInfoModal, token, refetch } = props;

  // Define specific boolean flags for each case
  const isStandaloneReview =
    data.type === "review-approve" && !data.followUpLetterAction;

  const isStandaloneSign = data.type === "sign";

  const isReviewWithFollowUpSign =
    data.type === "review-approve" &&
    data.followUpLetterAction?.type === "sign";

  const [informationModalOpen, setInformationModalOpen] = useState(false);

  useEffect(() => {
    if (data.status === "sent") {
      setInformationModalOpen(!hideInitialInfoModal);
    }
  }, [data.status, hideInitialInfoModal]);

  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCommentAdded = async () => {
    if (data.status === "sent") {
      const res = await customerApi.submitLetterAction({
        letterActionId: data.id,
        token: token,
        action: "request_changes",
      });

      if (!res.success) return;
      refetch();
    }
  };

  const onSignature = async () => {
    setLoading(true);

    // For review mode with follow-up signature, check the review status
    if (isReviewWithFollowUpSign) {
      if (data.status === "sent") {
        // If review is still in "sent" state, approve both review and sign
        const reviewRes = await customerApi.submitLetterAction({
          letterActionId: data.id,
          token: token,
          action: "approve",
        });

        if (!reviewRes.success) {
          setLoading(false);
          setFailureModalOpen(true);
          return;
        }

        refetch();
      }

      // If review is in "changes_requested", only approve the signature
      const signRes = await customerApi.submitLetterAction({
        letterActionId: data.followUpLetterAction!.letterActionId,
        token: token,
        action: "approve",
      });

      setLoading(false);

      if (!signRes.success) {
        setFailureModalOpen(true);
        return;
      }

      refetch();
      return;
    }

    // For sign mode, just approve the current action
    const res = await customerApi.submitLetterAction({
      letterActionId: data.id,
      token: token,
      action: "approve",
    });

    setLoading(false);

    if (!res.success) {
      setFailureModalOpen(true);
      return;
    }

    refetch();
  };

  return (
    <div
      className="relative w-full h-full"
      style={{
        background: "url('/backgrounds/letter-review.webp')",
        backgroundBlendMode: "normal",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <InformationModal
        isBeneficiary={false}
        beneficiaryName={data.beneficiaryName}
        open={informationModalOpen}
        setOpen={setInformationModalOpen}
      />

      <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />
      <LoadingOverlay isLoading={loading} />

      <div className="w-full h-full absolute bg-grey-800 bg-opacity-25 backdrop-blur-[2px]" />
      <ExternalEditorContextProvider
        letterId={data.letterId}
        userId={data.externalEmail}
        userName={data.externalName}
        commentUserIdWhitelist={!isStandaloneSign ? [data.externalEmail] : []}
        mode={isStandaloneSign ? "sign" : "review"}
        letterActionId={data.id}
        token={token}
        onCommentAdded={onCommentAdded}
        signatureLetterActionId={
          isReviewWithFollowUpSign
            ? data.followUpLetterAction!.letterActionId
            : data.id
        }
        onSignatureAdded={
          isStandaloneSign || isReviewWithFollowUpSign ? onSignature : undefined
        }
        disableSignatures={!(isStandaloneSign || isReviewWithFollowUpSign)}
      >
        <MainContent
          data={data}
          token={token}
          refetch={refetch}
          isStandaloneSign={isStandaloneSign}
          isReviewWithFollowUpSign={isReviewWithFollowUpSign}
          isStandaloneReview={isStandaloneReview}
          status={data.status}
          loading={loading}
          onSignature={onSignature}
          setInformationModalOpen={setInformationModalOpen}
        />
      </ExternalEditorContextProvider>
    </div>
  );
};
