import { Button } from "./button";
import { DownloadIcon } from "@radix-ui/react-icons";

export const DownloadButton = (props: {
  presignedUrl: string;
  name: string;
  label?: string;
}) => {
  const { presignedUrl, name, label } = props;
  const doDownloadFile = async () => {
    const link = document.createElement("a");
    link.href = presignedUrl;
    link.download = name;
    link.rel = "noopener noreferrer";
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
  };

  return (
    <Button variant="primary" onClick={doDownloadFile}>
      <DownloadIcon />
      {label ?? "Download"}
    </Button>
  );
};
