import { cn } from "@/lib/cn";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import { toast as hotToast, Toast } from "react-hot-toast";

import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

const CustomToast = (props: {
  header: string;
  icon: React.ReactNode;
  message: string;
  t: Toast;
  className?: string;
}) => {
  const { header, icon, message, t, className } = props;

  return (
    <AnimatePresence>
      <motion.div
        className={cn(
          "bg-grey-200 rounded-xl py-3 px-4 flex flex-col gap-2 w-[392px]",
          className
        )}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.1 }}
      >
        <div className="w-full flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-2">
            {icon}
            <span className="text-[14px] text-grey-800">{header}</span>
          </div>

          <div className="flex flex-row items-center gap-2">
            <button onClick={() => hotToast.dismiss(t.id)}>
              <CrossCircledIcon className="w-[16px] h-[16px] text-grey-400" />
            </button>
          </div>
        </div>
        <p className="text-[14px] text-grey-500">{message}</p>
      </motion.div>
    </AnimatePresence>
  );
};

export const customToast = (
  header: string,
  icon: React.ReactNode,
  message: string,
  className?: string
) =>
  hotToast.custom((t: Toast) => (
    <CustomToast
      header={header}
      icon={icon}
      message={message}
      t={t}
      className={className}
    />
  ));
