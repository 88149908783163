import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { DateInput, Input, TextArea } from "@/components/inputs";

import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";

export const Membership = () => {
  const { currentOnboardingNodeId, currentOnboardingNodeName } =
    useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        This is evidence that{" "}
        <span className="text-grey-200">
          you were selected into a reputable organization for outstanding
          achievements.
        </span>{" "}
        Our team will also draft a support letter for the organization to review
        and sign
        {/* <HighlightedText>Learn more</HighlightedText> */}
      </p>
      <Field id="organizationTitle" label="Organization title">
        <Input
          type="text"
          placeholder="e.g. Y Combinator, On Deck, South Park Commons"
          value={data.organizationTitle?.value as string}
          onValueChange={(val) => doSetData("organizationTitle", val)}
        />
      </Field>

      <Field
        id="selectedDate"
        label={`When were you selected to be a member of ${
          data.organizationTitle?.value == null
            ? "this membership"
            : `${data.organizationTitle?.value}`
        }?`}
      >
        <DateInput
          value={
            data.selectedDate?.value != null
              ? new Date(data.selectedDate.value)
              : undefined
          }
          onValueChange={(val) => doSetData("selectedDate", val?.toISOString())}
        />
      </Field>

      <Field
        id="supportingDocuments"
        label={`Upload confirmation you're a member of ${
          data.organizationTitle?.value == null
            ? "this membership. Upload screenshots of the email acceptance, member portal with your profile page if available."
            : `${data.organizationTitle?.value}`
        }`}
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="Please leave any additional comments you'd like the Lighthouse team to review as it relates to this criteria"
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="membership"
          name="membership"
          description="Add another Membership criteria if you'd like to add more evidence of another membership organization you've been a part of for our experts to review."
        />
      )}
    </OnboardingForm>
  );
};
