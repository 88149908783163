import { CompanyInfo, CompanyInfoTabs } from "@/components/companyInfo";
import { CompanySidebarFrame } from "@/components/sidebarFrame";
import { useWorkspaces, useWorkspaceStore } from "@/lib/hooks/workspaces";
import { getCompanyWorkspace } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

const CompanyInfoPage = () => {
  const { selectedWorkspace, rawId } = useWorkspaces();
  const nav = useNavigate();
  const { tab } = Route.useSearch();

  if (selectedWorkspace?.id.startsWith("user")) {
    nav({ to: "/home", replace: true });
    return;
  }

  return (
    <CompanyProvider
      key={`company-info-${selectedWorkspace?.id}`}
      companyId={rawId}
    >
      <CompanySidebarFrame>
        <CompanyInfo tab={tab} />
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

export const Route = createFileRoute("/_portal/company-info/")({
  validateSearch: (
    search: Record<string, unknown>
  ): { tab?: CompanyInfoTabs } => {
    return {
      tab: (search.tab as CompanyInfoTabs | undefined) ?? undefined,
    };
  },
  loader: async ({ context }) => {
    const workspaceId = useWorkspaceStore.getState().selectedWorkspace?.id;
    const workspaceRawId =
      workspaceId != null ? parseInt(workspaceId.split("-")[1]) : undefined;

    if (workspaceId == null) return;
    if (!workspaceId.startsWith("company")) return;
    if (workspaceRawId == null) return;

    await context.client.query(getCompanyWorkspace, {
      id: workspaceRawId,
    });
  },
  component: () => <CompanyInfoPage />,
});
