import { cn } from "@/lib/cn";
import { LabelText } from "./typography";

export const RadioSelect = (props: {
  value?: string | boolean;
  onChange?: (value: string | boolean) => void;
  options: { value: string | boolean; label: string }[];
  orientation?: "horizontal" | "vertical";
}) => {
  return (
    <div
      className={cn(
        "flex flex-row gap-4 items-center",
        props.orientation == "vertical" && "flex-col items-start gap-1"
      )}
    >
      {props.options.map((option) => (
        <div
          className="flex items-center px-2"
          key={option.value.toString()}
        >
          <input
            type="radio"
            id={option.value.toString()}
            value={option.value.toString()}
            checked={props.value === option.value}
            onChange={(e) => {
              if (e.target.value === "true") {
                props.onChange?.(true);
                return;
              }

              if (e.target.value === "false") {
                props.onChange?.(false);
                return;
              }
              props.onChange?.(e.target.value);
            }}
            className="accent-grey-100"
          />
          <LabelText
            className="text-grey-100 text-sm pt-0.5 pr-1 pb-[3px] pl-2"
            htmlFor={option.value.toString()}
          >
            {option.label}
          </LabelText>
        </div>
      ))}
    </div>
  );
};
