import { Carousel } from "@/components/carousel";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { RadioSelect } from "@/components/radio";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";

const PassportTooltip = () => {
  return (
    <div className="flex flex-col pt-4 gap-4">
      <span className="text-md text-grey-500 px-4">See best examples:</span>
      <ul className="gap-1.5 list-disc list-inside px-4">
        <li>Documents should be clear and legible.</li>
        <li>Please upload high-resolution photos</li>
        <li>Avoid flash to avoid glare</li>
        <li>Make sure no information is covered</li>
      </ul>

      <Carousel
        imageUrls={[
          "https://afar.brightspotcdn.com/dims4/default/affc3c0/2147483647/strip/false/crop/1500x1038+0+0/resize/1486x1028!/quality/90/?url=https%3A%2F%2Fk3-prod-afar-media.s3.us-west-2.amazonaws.com%2Fbrightspot%2Ff3%2F12%2F5096fb404c63b856a3ae9f8fbf13%2Foriginal-ngp-20photo-202.JPG",
        ]}
      />
    </div>
  );
};

const VisaStampsTooltip = () => {
  return (
    <div className="flex flex-col pt-4 gap-4">
      <span className="text-md text-grey-500 px-4">See best examples:</span>
      <ul className="gap-1.5 list-disc list-inside px-4">
        <li>Documents should be clear and legible.</li>
        <li>Please upload high-resolution photos</li>
        <li>Avoid flash to avoid glare</li>
        <li>Make sure no information is covered</li>
      </ul>

      <Carousel
        imageUrls={[
          "/tooltips/visa-stamps.webp",
          "/tooltips/visa-stamps-2.jpg",
        ]}
      />
    </div>
  );
};

export const VisaDocuments = () => {
  const { currentOnboardingNodeId, currentOnboardingNodeName } = useOnboarding();

  const { data, fetching, error, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      {fetching && data == null && <Spinner />}

      {error != null && (
        <div className="text-sm text-negative">
          Error loading personal details, please contact hello@lighthousehq.com
          for assistance.
        </div>
      )}

      {data != null && !fetching && (
        <>
          <Field
            id="appliedForUsWorkVisaPreviously"
            label="Which statement best describes your current situation regarding a US work visa?"
          >
            <RadioSelect
              orientation="vertical"
              value={data.appliedForUsWorkVisaPreviously?.value}
              onChange={(value) =>
                doSetData("appliedForUsWorkVisaPreviously", value)
              }
              options={[
                {
                  value: false,
                  label:
                    "This would be my first time applying for a US work visa",
                },
                {
                  value: true,
                  label: "I've had an O-1A or other work visa before",
                },
              ]}
            />
          </Field>

          <Field
            id="passport"
            label="Please upload your most recent passport"
            tooltip={<PassportTooltip />}
          >
            <SubkeyUploadArea
              onboardingNodeId={currentOnboardingNodeId ?? -1}
              subKey="passport"
            />
          </Field>

          <Field
            id="visaStamps"
            label="Please upload photos or copies of any and all previous US visa approvals."
            description="These include US visa stamps in your passport, copies of any prior I-797, I-20, DS-2019, EAD cards, etc."
            tooltip={<VisaStampsTooltip />}
          >
            <SubkeyUploadArea
              onboardingNodeId={currentOnboardingNodeId ?? -1}
              subKey="visaStamps"
            />
          </Field>

          {data.appliedForUsWorkVisaPreviously?.value === true && (
            <Field
              id="formerWorkVisaApplicationOrPetition"
              label="Please upload your previous work visa petition or application"
              // tooltip={<PassportTooltip />}
            >
              <SubkeyUploadArea
                onboardingNodeId={currentOnboardingNodeId ?? -1}
                subKey="formerWorkVisaApplicationOrPetition"
              />
            </Field>
          )}
        </>
      )}
    </OnboardingForm>
  );
};
