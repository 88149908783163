import { useMutation, useQuery } from "urql";
import { useAdminStore } from "./admin";
import { getUserContext, patchUserEntityMutation } from "../queries";
import { ResultOf } from "gql.tada";
import { PhoneNumber } from "@/components/inputs";

export const useUserContext = (opts?: {
  requestPolicy?: "cache-and-network" | "cache-first" | "network-only";
}) => {
  const { overrideUserId } = useAdminStore((state) => state);

  const [{ data, fetching }] = useQuery({
    query: getUserContext,
    variables: {
      userContextInput: {
        overrideUserId,
      },
    },
    requestPolicy: opts?.requestPolicy ?? "network-only",
  });

  const doPatchUserEntity = useMutation(patchUserEntityMutation)[1];

  const doUpdateUserEntity = (
    key: keyof NonNullable<
      ResultOf<typeof getUserContext>["context"]["userEntity"]
    >,
    value: string | PhoneNumber
  ) => {
    if (data?.context.userEntity == null) return;

    doPatchUserEntity({
      input: {
        userId: data.context.contextUserId,
        data: {
          [key]: value,
        },
      },
    });
  };

  return {
    loggedInUserId: data?.context.loggedInCustomerUserId,
    userId: data?.context.contextUserId,
    fetching,
    userEntity: data?.context.userEntity,
    doUpdateUserEntity,
  };
};
