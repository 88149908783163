import { getCompanyWorkspace } from "@/lib/queries";
import { ResultOf } from "gql.tada";
import { createContext, useContext } from "react";
import { useQuery } from "urql";

export type CompanyRoutes = "home" | "company-info" | "applicants";

type CompanyRoutesContextType = {
  data?: ResultOf<typeof getCompanyWorkspace>;
  fetching: boolean;
};

const CompanyRoutesContext = createContext<CompanyRoutesContextType | null>(
  null
);

export const CompanyProvider = (props: {
  children: React.ReactNode;
  companyId?: number;
}) => {
  const [{ data, fetching }] = useQuery({
    query: getCompanyWorkspace,
    variables: { id: props.companyId ?? 0 },
    pause: props.companyId == null,
    requestPolicy: "cache-and-network",
  });

  return (
    <CompanyRoutesContext.Provider
      value={{
        data: data,
        fetching,
      }}
      key={`company-provider-${props.companyId}`}
    >
      {props.children}
    </CompanyRoutesContext.Provider>
  );
};

export const useCompany = () => {
  const context = useContext(CompanyRoutesContext);
  if (!context)
    throw new Error(
      "useCompanyRoutes must be used within CompanyRoutesProvider"
    );
  return context;
};
