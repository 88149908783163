import { Heading, Spinner } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { InputText, LabelText } from "./typography";
import { FAQModal } from "./faqModal";
import { cn } from "@/lib/cn";
import { useQuery } from "urql";
import { getOnboardingContext } from "@/lib/queries";
import { useAdminStore } from "@/lib/hooks/admin";
import { motion, AnimatePresence } from "framer-motion";

const Header = () => (
  <div className="py-6 px-8">
    <Heading as="h2" size="4">
      Lighthouse
    </Heading>
  </div>
);

const Footer = (props: { visaClass: string }) => {
  const [faqModalOpen, setFaqModalOpen] = useState(false);
  const [nextHintVisible, setNextHintVisible] = useState(false);

  useEffect(() => {
    // show hint after 1 second
    const timeout = setTimeout(() => {
      setNextHintVisible(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="flex flex-row w-full py-6 px-8 items-center">
      <FAQModal
        open={faqModalOpen}
        setOpen={setFaqModalOpen}
        visaClass={props.visaClass}
      />
      <div
        className="w-1/3 text-nowrap hover:cursor-pointer"
        onClick={() => setFaqModalOpen(true)}
      >
        <LabelText as={"span"} className="text-grey-400 font-normal">
          Need some help?
        </LabelText>
      </div>

      <div className="w-full h-full flex justify-center">
        <LabelText
          as="span"
          className={cn(
            "text-grey-400 transition-all duration-500 opacity-0",
            nextHintVisible && "opacity-100"
          )}
        >
          Press{" "}
          <kbd className="bg-grey-600 text-grey-300 py-0.5 px-1 font-sans rounded-[0.25em]">
            Enter
          </kbd>{" "}
          to continue
        </LabelText>
      </div>

      <div className="flex ml-auto w-1/3 justify-end">
        {/* <ThemePicker /> */}
      </div>
    </div>
  );
};

const NestedWordAnimation = (props: { text: string; delay?: number }) => {
  const { text, delay = 0 } = props;
  return text.split(" ").map((word, index) => (
    <AnimatePresence key={`${word}-${index}`}>
      <motion.span
        initial={{ opacity: 0, filter: "blur(1px)" }}
        animate={{ opacity: 1, filter: "blur(0px)" }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, delay: index * 0.05 + delay }}
      >
        {word}
        {index < text.split(" ").length - 1 && " "}
      </motion.span>
    </AnimatePresence>
  ));
};

const AnimatedText = (props: {
  text: string;
  className: string;
  delay?: number;
  as: "Heading" | "InputText";
}) => {
  const { text, className, delay = 0 } = props;

  const containerVariants = {
    initial: { zoom: 0.97 },
    animate: {
      zoom: 1,
      transition: { duration: 1, ease: "easeInOut" },
    },
  };

  return props.as === "Heading" ? (
    <motion.div
      variants={containerVariants}
      initial="initial"
      animate="animate"
    >
      <Heading as="h2" size="4" className={cn("relative", className)}>
        <NestedWordAnimation text={text} delay={delay} />
      </Heading>
    </motion.div>
  ) : (
    <InputText className={cn("relative", className)}>
      <NestedWordAnimation text={text} delay={delay} />
    </InputText>
  );
};

export const Welcome = (props: { next?: () => void; visaClass: string }) => {
  const { overrideUserId } = useAdminStore((state) => state);
  const [{ data, fetching }] = useQuery({
    query: getOnboardingContext,
    variables: {
      contextInput: {
        overrideUserId,
      },
    },
  });

  const name =
    data?.context.userEntity?.firstName ?? data?.context.userEntity?.legalName;

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        props.next?.();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [props]);

  return (
    <div className="w-full h-full flex flex-col grow-0 relative">
      <div className="absolute inset-0 min-w-full min-h-full z-0 overflow-hidden">
        <div className="absolute w-[100vw] h-[110vh] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[url('/backgrounds/welcome.webp')] bg-cover"></div>
      </div>
      <div className="z-10 w-full h-full flex flex-col">
        <Header />
        <div className="w-full h-full flex items-center justify-center grow-0 overflow-hidden">
          <div className="flex flex-col gap-8 items-center text-center h-full justify-center">
            {fetching && data == null && <Spinner />}

            {data != null && (
              <>
                <div className="flex flex-col gap-2 justify-center items-center">
                  <AnimatedText
                    text={`Welcome to your immigration journey, ${name ?? "[UNKNOWN FIRST NAME]"}.`}
                    as={"Heading"}
                    className="font-[600] text-[24px]"
                    delay={0.2}
                  />
                  <AnimatedText
                    text="We're delighted to support you."
                    as={"InputText"}
                    className="text-grey-300"
                    delay={1.2}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Footer visaClass={props.visaClass} />
      </div>
    </div>
  );
};
