import React, { createContext, useContext, ReactNode } from "react";
import {
  Comment,
  CommentsHookResult,
  useComments,
  useExternalComments,
} from "@/lib/hooks/comments";
import { useUserContext } from "@/lib/hooks/user";

interface CommentsContextType {
  comments: Comment[];
  createComment: CommentsHookResult["createComment"];
  createReply: CommentsHookResult["createReply"];
  deleteComment: CommentsHookResult["deleteComment"];
  updateComment?: CommentsHookResult["updateComment"];
  resolveComment: CommentsHookResult["resolveComment"];
  onCommentAdded: () => void;
  userId: string;
  userName: string;
}

// Create the context with a default undefined value
const CommentsContext = createContext<CommentsContextType | undefined>(
  undefined
);

// Props for our provider component
interface ExternalCommentsProviderProps {
  children: ReactNode;
  letterActionId: number;
  token: string;
  userId: string;
  userName: string;
  onCommentAdded: () => void;
}

interface InternalCommentsProviderProps {
  children: ReactNode;
  letterActionId: number;

  onCommentAdded: () => void;
}

// The provider component
export const ExternalCommentsProvider: React.FC<
  ExternalCommentsProviderProps
> = ({ children, letterActionId, token, onCommentAdded, userId, userName }) => {
  // Use the existing hook to get comments functionality
  const {
    comments,
    createComment,
    createReply,
    deleteComment,
    resolveComment,
    updateComment,
  } = useExternalComments({
    letterActionId,
    token,
  });

  // The value that will be available to consumers of the context
  const value: CommentsContextType = {
    comments: comments ?? [],
    createComment,
    createReply,
    deleteComment,
    updateComment,
    resolveComment,
    onCommentAdded,
    userId,
    userName,
  };

  return (
    <CommentsContext.Provider value={value}>
      {children}
    </CommentsContext.Provider>
  );
};

export const InternalCommentsProvider: React.FC<
  InternalCommentsProviderProps
> = ({ children, letterActionId, onCommentAdded }) => {
  // Use the existing hook to get comments functionality
  const {
    comments,
    createComment,
    createReply,
    deleteComment,
    resolveComment,
    updateComment,
  } = useComments({ letterActionId });

  const user = useUserContext();

  // The value that will be available to consumers of the context
  const value: CommentsContextType = {
    comments: comments ?? [],
    createComment,
    createReply,
    deleteComment,
    updateComment,
    resolveComment,
    onCommentAdded,
    userId: user?.userId?.toString() ?? "",
    userName: `${user?.userEntity?.firstName} ${user?.userEntity?.lastName}`,
  };

  return (
    <CommentsContext.Provider value={value}>
      {children}
    </CommentsContext.Provider>
  );
};

// Custom hook to use the comments context
export const useCommentsProvider = (): CommentsContextType => {
  const context = useContext(CommentsContext);
  if (context === undefined) {
    throw new Error("useComments must be used within a CommentsProvider");
  }
  return context;
};
