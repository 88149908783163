import { Field, OnboardingForm } from "@/components/form";
import { LetterSigner, LetterSigners } from "@/components/letterSigners";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";

export const SupervisorLetter = () => {
  const { currentOnboardingNodeId, nodeStatus, currentOnboardingNodeName } =
    useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      <Field
        id="signers"
        description={
          <span className="text-grey-300 text-[12px] mt-4 pr-12">
            Like all of the supporting documentation in your application, the
            USCIS will want evidence to prove any previous, current, or intended
            employment that are included in your visa application.{" "}
            <span className="text-grey-200">
              Supervisor letters are brief employment verification letters to
              verify the roles you've held as part of your professional
              accomplishments included in your application. Signers should be
              executive-level or managerial personnel (eg, HR) who currently
              work at the company as these letters serve as verification of your
              employment.
            </span>
          </span>
        }
        rejectionBorderClassName="p-2"
      >
        <LetterSigners
          value={(data.signers?.value ?? []) as LetterSigner[]}
          onChange={(signers) => doSetData("signers", signers)}
          disabled={
            nodeStatus === "submitted_for_review" || nodeStatus === "approved"
          }
        />
      </Field>
    </OnboardingForm>
  );
};
