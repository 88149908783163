import { OnboardingFrame } from "@/components/onboardingFrame";
import React, { useEffect, useRef, useState } from "react";
import { O1ChatLogs } from "./chat";
import { Button } from "@/components/button";
import { LabelText } from "@/components/typography";
import { useOnboarding } from "@/providers/onboardingProvider";
import { Welcome } from "@/components/welcome";
import { O1StartProvider, useO1StartContext, OnboardingState } from "./store";
import { MarkRoundedSquareIcon } from "@/components/icons/markRoundedSquare";
import { submitNodeForReview } from "@/lib/queries";
import { useMutation } from "urql";
import { useNavigate } from "@tanstack/react-router";
import moment from "moment";

const EnterToContinue = () => (
  <LabelText as="span" className="text-grey-400">
    Press{" "}
    <kbd className="bg-grey-600 text-grey-300 py-0.5 px-1 font-sans rounded-[0.25em]">
      Enter
    </kbd>{" "}
    to continue
  </LabelText>
);

const SpaceToBottom = () => (
  <LabelText as="span" className="text-grey-400">
    Press{" "}
    <kbd className="bg-grey-600 text-grey-300 py-0.5 px-1 font-sans rounded-[0.25em]">
      space
    </kbd>{" "}
    to jump to the latest
  </LabelText>
);

const RespondToContinue = () => (
  <LabelText as="span" className="text-grey-400">
    Answer the questions to continue
  </LabelText>
);

const validateForm = (state: OnboardingState) => {
  const { data } = state;

  // Validate responsive section
  const isResponsiveValid =
    data.canBeResponsive === true ||
    (data.canBeResponsive === false &&
      data.bestDates?.startDate != null &&
      data.bestDates.endDate != null &&
      moment(data.bestDates.endDate).isAfter(
        moment(data.bestDates.startDate)
      ) &&
      moment(data.bestDates.endDate).isAfter(
        moment(data.bestDates.startDate).add(2, "weeks")
      ));

  // Validate travel section
  const isTravelValid =
    data.travelWithin2Weeks === false ||
    (data.travelWithin2Weeks === true &&
      data.travelDetails != null &&
      data.travelDetails.trim().length !== 0);

  return isResponsiveValid && isTravelValid;
};

export const InnerStep = (props: {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  next: () => void;
}) => {
  const { setProgress } = props;
  const {
    currentOnboardingNodeId,
    onboardingId,
    refetchOnboarding,
    availableGroups,
  } = useOnboarding();
  const { state, nextMessage } = useO1StartContext();
  const messages = state.messages;
  const { disableScroll } = state.ui;

  const isFormValid = validateForm(state);

  const submitMutation = useMutation(submitNodeForReview)[1];
  const nav = useNavigate();
  const [isNearBottom, setIsNearBottom] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messages[messages.length - 1] === "documents") {
      setProgress(100);
      return;
    }
    setProgress((messages.length / 8) * 100);
  }, [messages, setProgress]);

  const doScrollToBottom = async () => {
    await new Promise((resolve) => setTimeout(resolve, 5));
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const doProgress = () => {
    if (messages[messages.length - 1] === "documents") {
      props.next();
      return;
    }

    nextMessage();
    doScrollToBottom();
  };

  const doMarkAsCompleteAndGoToNextStep = async (dashboard?: boolean) => {
    if (currentOnboardingNodeId == null || onboardingId == null) return;

    submitMutation({
      nodeId: currentOnboardingNodeId,
    });

    refetchOnboarding();

    if (dashboard) {
      nav({ to: "/home" });
      return;
    }

    const firstGroup = availableGroups.filter((g) => g !== "gate")[0];

    nav({
      to: `/group/$groupId`,
      params: { groupId: firstGroup },
    });
  };

  // Handle Enter key for progression
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Ignore if target is a form element
      if (
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLInputElement
      ) {
        return;
      }

      if (e.key === "Enter") {
        e.preventDefault();
        if (messages[messages.length - 1] === "documents") {
          doMarkAsCompleteAndGoToNextStep();
          return;
        }
        nextMessage();
        setTimeout(() => {
          doScrollToBottom();
        }, 1500);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [messages, nextMessage, doMarkAsCompleteAndGoToNextStep]);

  // Handle Space key for scrolling
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === " " && !isNearBottom && !disableScroll) {
        messagesEndRef.current?.scrollIntoView({
          behavior: "smooth",
          inline: "nearest",
        });
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableScroll, isNearBottom]);

  // Setup intersection observer for scroll position
  useEffect(() => {
    const currentRef = messagesEndRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => setIsNearBottom(entry.isIntersecting),
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="max-h-full min-h-full w-full flex gap-12 flex-col items-center no-scrollbar overflow-y-scroll">
      <div className="flex-1 w-full no-scrollbar flex items-end">
        <div className="w-full flex flex-row gap-3 no-scrollbar justify-center">
          <div className="flex h-full mt-auto">
            <MarkRoundedSquareIcon />
          </div>
          <div className="flex flex-col max-w-fit h-full w-full">
            <O1ChatLogs doAddMessage={doProgress} />
            <div ref={messagesEndRef} className="h-[1px]" />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row justify-center items-center gap-2 pb-4">
        {messages[messages.length - 1] === "documents" ? (
          <div className="flex flex-row gap-2">
            <Button
              variant="primary"
              onClick={() => doMarkAsCompleteAndGoToNextStep()}
              disabled={!isFormValid}
            >
              Start your onboarding
            </Button>
          </div>
        ) : (
          <>
            {!isNearBottom && !disableScroll && <SpaceToBottom />}
            {(messages[messages.length - 1] === "travel-plan" &&
              state.data.travelWithin2Weeks === undefined) ||
            (messages[messages.length - 1] === "can-be-responsive" &&
              state.data.canBeResponsive === undefined) ||
            (messages[messages.length - 1] === "travel-details" &&
              (!state.data.travelDetails ||
                state.data.travelDetails.trim().length < 10)) ||
            (messages[messages.length - 1] === "date-range" &&
              (!state.data.bestDates?.startDate ||
                !state.data.bestDates?.endDate)) ? (
              <RespondToContinue />
            ) : (
              <EnterToContinue />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const O1Start = () => {
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState<"welcome" | "chat">("welcome");
  const { currentOnboardingNodeId, visaClass } = useOnboarding();

  const nextStep = () => {
    if (page === "welcome") {
      setPage("chat");
    }
  };

  return (
    <>
      {page === "welcome" && (
        <Welcome next={() => setPage("chat")} visaClass={visaClass} />
      )}
      {page === "chat" && currentOnboardingNodeId != null && (
        <div className="w-full h-full max-h-screen flex flex-col grow-0 relative">
          <div className="absolute inset-0 min-w-full min-h-full z-0 overflow-hidden">
            <div className="absolute w-[100vw] h-[110vh] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[url('/backgrounds/welcome.webp')] bg-cover"></div>
          </div>
          <div className="z-10 w-full h-full flex flex-col">
            <O1StartProvider onboardingNodeId={currentOnboardingNodeId} visaClass={visaClass}>
              <OnboardingFrame progress={progress}>
                <InnerStep setProgress={setProgress} next={nextStep} />
              </OnboardingFrame>
            </O1StartProvider>
          </div>
        </div>
      )}
    </>
  );
};
