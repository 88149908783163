import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { Input, TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";

export const HighCompensation = () => {
  const { currentOnboardingNodeId, currentOnboardingNodeName } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        Our goal is to show that{" "}
        <span className="text-grey-200">
          you were highly paid in your role, relative to your region and role
          type.
        </span>{" "}
        We will help determine the most favorables comparable ranges based on
        the information you can share below.
      </p>

      <Field
        id="workLocation"
        label="Your primary work location"
        description="Enter the City, Country you were earning income in at the time of employment."
      >
        <Input
          type="text"
          placeholder="City, Country"
          value={data.workLocation?.value as string}
          onValueChange={(val) => doSetData("workLocation", val)}
        />
      </Field>

      <Field id="annualSalary" label="Your annual salary">
        <Input
          type="text"
          placeholder="USD 500,000"
          value={data.annualSalary?.value as string}
          onValueChange={(val) => doSetData("annualSalary", val)}
        />
      </Field>

      <Field id="equityReceived" label="How much did you receive in equity?">
        <Input
          type="text"
          placeholder="e.g. 5% equity with a company valuation of $10 million"
          value={data.equityReceived?.value as string}
          onValueChange={(val) => doSetData("equityReceived", val)}
        />
      </Field>

      <Field id="payStubs" label="Upload last 4 pay stubs">
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="payStubs"
        />
      </Field>

      <Field
        id="taxReturn"
        label="Upload the most recent tax return for the year you were employed at the company"
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="taxReturn"
        />
      </Field>

      <Field
        id="equityEvidence"
        label="Upload screenshot of evidence of your equity"
        description="Equity offer letter, share purchase agreement, Carta screenshot or equivalent"
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="equityEvidence"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="Please leave any additional comments you'd like the Lighthouse team to review as it relates to this criteria"
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="high-compensation"
          name="high compensation"
          description="We included this role to advocate for High Remuneration based on your case strategy. If you think that another High Remuneration is important to include, please add and the Lighthouse team will verify."
        />
      )}
    </OnboardingForm>
  );
};
