import { CompanyInfo, CompanyInfoTabs } from "@/components/companyInfo";
import { SidebarFrame } from "@/components/sidebarFrame";
import { useWorkspaces } from "@/lib/hooks/workspaces";
import { getOnboardingFounderCompanyInfo } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import { OnboardingDataProvider } from "@/providers/onboardingDataProvider";

import { Spinner } from "@radix-ui/themes";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useQuery } from "urql";

export const Route = createFileRoute("/_portal/founder-company-info/")({
  component: () => <CompanyInfoPage />,
  validateSearch: (
    search: Record<string, unknown>
  ): { tab?: CompanyInfoTabs } => {
    return {
      tab: (search.tab as CompanyInfoTabs | undefined) ?? undefined,
    };
  },
});

const CompanyInfoPage = () => {
  const { selectedWorkspace, fetching } = useWorkspaces();
  const nav = useNavigate();
  const { tab } = Route.useSearch();

  const onboardingId = selectedWorkspace?.selectedOnboardingId;

  const [{ data, fetching: fetchingCompanyInfo }] = useQuery({
    query: getOnboardingFounderCompanyInfo,
    variables: { id: onboardingId ?? -1 },
    pause: onboardingId == null,
    requestPolicy: "cache-first",
  });

  const companyId = data?.onboarding.companyId;

  useEffect(() => {
    if (fetching || fetchingCompanyInfo) return;
    if (
      companyId == null ||
      !data?.onboarding.hasCompanyDetailsAccess ||
      onboardingId == null
    ) {
      nav({ to: "/home", replace: true });
    }
  }, [
    companyId,
    data?.onboarding.hasCompanyDetailsAccess,
    onboardingId,
    fetching,
    fetchingCompanyInfo,
  ]);

  if (fetching || fetchingCompanyInfo) {
    return <Spinner />;
  }

  return (
    <CompanyProvider
      companyId={companyId as number}
      key={`company-info-${selectedWorkspace?.id}`}
    >
      <OnboardingDataProvider onboardingId={onboardingId as number}>
        <SidebarFrame>
          <CompanyInfo
            tab={tab}
            onTabSelectOverride={(tab) => {
              nav({ to: "/founder-company-info", search: { tab } });
            }}
            showLinkedWorkspaceCopy
            linkedWorkspaceId={companyId ?? undefined}
          />
        </SidebarFrame>
      </OnboardingDataProvider>
    </CompanyProvider>
  );
};
