import { ApplicantStatusBadge } from "@/components/applicantStatusBadge";
import { Avatar } from "@/components/avatar";
import { Button } from "@/components/button";

import { PetitionerRepSelector } from "@/components/petitionerRepresentativeSelector";
import { CompanySidebarFrame } from "@/components/sidebarFrame";
import { LabelText } from "@/components/typography";
import { VisaClassBadge } from "@/components/visaClassBadge";
import { ResultOf } from "@/lib/graphql";
import { useWorkspaces } from "@/lib/hooks/workspaces";
import { getCase, getCompanyWorkspace, getOnboarding } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import {
  OnboardingDataProvider,
  useOnboardingData,
} from "@/providers/onboardingDataProvider";

import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Spinner } from "@radix-ui/themes";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { useQuery } from "urql";

import { PetitionPreviewCard } from "@/components/petitionPreview";
import { LetterActions } from "@/components/letterActions";
import { FormActions } from "@/components/formActions";
import { EmployerDetailsOnboardingNode } from "@/components/onboarding/companyOnboarding/employerDetails";

const CompanyApplicantDashboard = (props: { onboardingId: number }) => {
  return (
    <OnboardingDataProvider onboardingId={props.onboardingId}>
      <div className="w-full flex flex-col pr-12">
        <ApplicantDashboardContent />
      </div>
    </OnboardingDataProvider>
  );
};

const KeyValue = (props: { label: string; value: React.ReactNode }) => {
  return (
    <div className="flex flex-row items-center gap-8 text-xs h-[25px] text-nowrap">
      <span className="text-grey-300 font-[460] w-[150px] text-nowrap">
        {props.label}
      </span>
      {props.value}
    </div>
  );
};

const ApplicantInfo = (props: { data: ResultOf<typeof getCase> }) => {
  const { data } = props;

  const kvPairs = [
    {
      label: "Application Status",
      value: <ApplicantStatusBadge status={data.getEmployeeCase.status} />,
    },
    {
      label: "Application in progress",
      value: <VisaClassBadge visaClass={data.getEmployeeCase.visaClass} />,
    },
    {
      label: "Employer Representative",
      value: (
        <PetitionerRepSelector
          caseId={data.getEmployeeCase.id}
          companyId={data.getEmployeeCase.company.id}
        />
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col gap-2 pr-12">
      <div className="flex flex-row items-center gap-2 text-sm text-grey-100 font-[525]">
        <Avatar
          username={data.getEmployeeCase.beneficiaryName}
          className="rounded-full w-[20px] h-[20px]"
        />
        {data.getEmployeeCase.beneficiaryName}
      </div>
      <div className="flex flex-col gap-2 mt-2 px-0.5">
        {kvPairs.map((kv) => (
          <KeyValue key={kv.label} label={kv.label} value={kv.value} />
        ))}
      </div>

      <div className="border-b-[1px] border-grey-600 w-full my-2" />
    </div>
  );
};

const ReviewCase = (props: {
  petition: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
}) => {
  const { petition } = props;

  return (
    <div className="w-full flex flex-col gap-2 px-2 mb-2">
      <div className="flex flex-col gap-1">
        <LabelText className="text-sm text-grey-300" as="span">
          Review your visa application
        </LabelText>

        <LabelText className="text-xs text-grey-300" as="span">
          {petition.status === "pending"
            ? "This visa application is ready for review."
            : petition.status === "changes_requested"
              ? "You have suggested changes to this application. We are working on them, and a new version will be available soon."
              : "You have approved this application. No further action is needed."}
        </LabelText>
      </div>
      <PetitionPreviewCard compiledCaseAction={petition} />
    </div>
  );
};

const ReceiptNoticeCompany = () => {
  const { receiptNotice } = useOnboardingData();
  return (
    <div className="w-full h-full flex flex-col justify-center rounded-lg py-6 px-4 bg-grey-200 gap-6">
      <p className="text-grey-500 text-xs">
        USCIS will send a receipt notice to the employer representative's email
        address. Forward this email to{" "}
        <a href="mailto:notices@lighthousehq.com" className="text-[#6893F1]">
          notices@lighthousehq.com
        </a>{" "}
        so we can keep you informed of your status.
      </p>

      <div className="flex flex-col gap-2">
        <LabelText as="span" className="text-xs text-grey-500">
          Your 13-character receipt number
        </LabelText>

        <div className="rounded-md bg-grey-150 bg-opacity-[72%] py-0.5 px-3 h-[36px] text-grey-300 items-center flex text-sm">
          {receiptNotice == null ||
          receiptNotice.trim.length === 0 ||
          receiptNotice.includes("[UNKNOWN")
            ? "Forward receipt notice email to notices@lighthousehq.com"
            : receiptNotice}
        </div>
      </div>
    </div>
  );
};

export const ApplicantDashboardContent = () => {
  const { caseStatus, onboardingId, publishedCase } = useOnboardingData();
  return (
    <>
      <LetterActions onboardingId={onboardingId} recipient="company" />
      <FormActions onboardingId={onboardingId} recipient="company" />

      {publishedCase != null && <ReviewCase petition={publishedCase} />}

      {["submitted", "shipped", "delivered", "in_review_uscis"].includes(
        caseStatus ?? ""
      ) && (
        <>
          <ReceiptNoticeCompany />
        </>
      )}

      {/* TODO: Approved / Denied / RFE for Employer */}
      {caseStatus === "approved" && <></>}
      {caseStatus === "denied" && <></>}
      {caseStatus === "rfe" && <></>}
    </>
  );
};

const ApplicationPage = () => {
  const { caseId } = Route.useParams();

  const { selectedWorkspace } = useWorkspaces();

  const companyId =
    selectedWorkspace?.id != null
      ? parseInt(selectedWorkspace?.id.split("-")[1] ?? "-1")
      : undefined;

  const [{ data: companyWorkspaceData }] = useQuery({
    query: getCompanyWorkspace,
    variables: { id: companyId ?? -1 },
    pause: companyId == null,
    requestPolicy: "cache-and-network",
  });

  const [{ data }] = useQuery({
    query: getCase,
    variables: {
      caseId: Number(caseId),
    },
    requestPolicy: "cache-and-network",
  });

  const nav = useNavigate();

  const currIndex = companyWorkspaceData?.getCompanyWorkspace.cases.findIndex(
    (x) => x.id === Number(caseId)
  );

  const nextAllowed =
    currIndex == null
      ? false
      : currIndex <
        (companyWorkspaceData?.getCompanyWorkspace.cases.length ?? 0) - 1;

  const previousAllowed = currIndex == null ? false : currIndex > 0;

  const onNext = () => {
    if (nextAllowed) {
      const nextCaseId =
        companyWorkspaceData?.getCompanyWorkspace.cases[Number(currIndex) + 1]
          ?.id;

      if (nextCaseId == null) return;

      nav({
        to: `/applicants/$caseId`,
        params: { caseId: nextCaseId.toString() },
      });
    }
  };

  const onPrevious = () => {
    if (previousAllowed) {
      const previousCaseId =
        companyWorkspaceData?.getCompanyWorkspace.cases[Number(currIndex) - 1]
          ?.id;

      if (previousCaseId == null) return;
      nav({
        to: `/applicants/$caseId`,
        params: { caseId: previousCaseId.toString() },
      });
    }
  };
  return (
    <CompanyProvider companyId={companyId}>
      <CompanySidebarFrame>
        <div className="w-full h-full flex bg-grey-800 pl-6 ">
          <div className="w-full h-full relative flex overflow-hidden">
            {previousAllowed && (
              <Button
                variant="secondary"
                className="absolute bottom-2 left-5 shadow-border bg-grey-700 z-10"
                onClick={onPrevious}
              >
                Previous Applicant
              </Button>
            )}
            {nextAllowed && (
              <Button
                variant="secondary"
                className="absolute bottom-2 right-5 shadow-border bg-grey-700 z-10"
                onClick={onNext}
              >
                Next Applicant
              </Button>
            )}
            <div className="pt-16 w-[25%]">
              <div
                className="flex flex-row gap-2 items-center text-grey-200 text-xs hover:underline cursor-pointer"
                onClick={() => nav({ to: "/home" })}
              >
                <ArrowLeftIcon />
                Back to all applicants
              </div>
            </div>

            <div className="flex flex-col gap-4 w-[50%] py-6 overflow-scroll">
              {data != null && data.getEmployeeCase != null ? (
                <ApplicantInfo data={data} />
              ) : (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <Spinner />
                </div>
              )}

              <div>
                {data?.getEmployeeCase.companyOnboarding && (
                  <CompanyApplicantDashboard
                    onboardingId={data.getEmployeeCase.companyOnboarding.id}
                  />
                )}

                {data?.getEmployeeCase.companyOnboarding?.id != null &&
                  data.getEmployeeCase.companyOnboarding
                    .employerDetailsNodeId != null && (
                    <EmployerDetailsOnboardingNode
                      onboardingNodeId={
                        data.getEmployeeCase.companyOnboarding
                          .employerDetailsNodeId
                      }
                      onboardingId={data.getEmployeeCase.companyOnboarding.id}
                      status={
                        data.getEmployeeCase.companyOnboarding
                          .employerDetailsNodeStatus ?? undefined
                      }
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

export const Route = createFileRoute("/_portal/applicants/$caseId")({
  beforeLoad: async ({ context, params }) => {
    if (params.caseId != null) {
      context.client.query(getCase, {
        caseId: Number(params.caseId),
      });
    }
  },
  component: () => <ApplicationPage />,
});
