import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { RadioSelect } from "@/components/radio";
import { HighlightedText } from "@/components/typography";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";

const EducationDocuments = () => {
  const { currentOnboardingNodeId, currentOnboardingNodeName, visaClass } =
    useOnboarding();

  const { data, fetching, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      {fetching && data == null && <Spinner />}

      {data != null && !fetching && (
        <>
          <Field
            id="diplomasCertificates"
            label="Copies of university diplomas and degree certificates"
            required
            description="If you do not have this on hand, please request it from your university or equivalent at your earliest convenience."
          >
            <SubkeyUploadArea
              onboardingNodeId={currentOnboardingNodeId ?? -1}
              subKey="diplomasCertificates"
            />
          </Field>

          <Field
            id="transcripts"
            label="Official transcripts from all universities attended"
            required
            description="If you do not have this on hand, please request it from your university or equivalent at your earliest convenience."
          >
            <SubkeyUploadArea
              onboardingNodeId={currentOnboardingNodeId ?? -1}
              subKey="transcripts"
            />
          </Field>

          <Field
            id="foreignEducation"
            label="Did you attend a school outside the United States?"
            required
          >
            <RadioSelect
              orientation="vertical"
              value={data.foreignEducation?.value}
              onChange={(value) => doSetData("foreignEducation", value)}
              options={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
            />
          </Field>

          {data.foreignEducation?.value === true && visaClass === "h-1b" && (
            <Field
              id="academicEquivalency"
              label="Credential evaluation or translations needed"
              description={
                <p>
                  Since you obtained your degree outside the United States,
                  you'll need a professional evaluation to determine its U.S.
                  equivalency. Email copies of your degree(s) and official
                  transcript(s) to{" "}
                  <a href="mailto:info@trustforte.com">
                    <HighlightedText>info@trustforte.com</HighlightedText>
                  </a>{" "}
                  with the following message: <br /> <br />
                  <div className="p-4 w-full h-full bg-grey-800 rounded-md text-grey-300 leading-1 shadow-border">
                    Hello Trustforte Team, <br /> <br />I am requesting an
                    Academic Equivalency Evaluation for the attached documents.
                    <br />
                    Please provide a quote for your services and any additional
                    information required to proceed with the evaluation.
                    <br /> <br />
                    Please let me know if you need any further information or
                    documentation. <br /> <br />
                    Thank you for your assistance.
                  </div>
                  <br />
                  Trustforte will send you the academic equivalency evaluation
                  once ready; please upload once available.
                </p>
              }
              required
            >
              <SubkeyUploadArea
                onboardingNodeId={currentOnboardingNodeId ?? -1}
                subKey="academicEquivalency"
              />
            </Field>
          )}
        </>
      )}
    </OnboardingForm>
  );
};

export { EducationDocuments };
