import { createFileRoute } from "@tanstack/react-router";

import { useAuth, useClerk } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useNavigate } from "@tanstack/react-router";
import { Spinner } from "@radix-ui/themes";

const Logout = () => {
  const { signOut } = useClerk();
  const { isSignedIn } = useAuth();
  const nav = useNavigate();
  useEffect(() => {
    if (isSignedIn) {
      signOut();
      nav({ to: "/", replace: true });
    } else {
      nav({ to: "/", replace: true });
    }
  }, [isSignedIn, nav, signOut]);

  return <Spinner />;
};

function RouteComponent() {
  return (
    <div className="flex w-full h-screen items-center justify-center">
      <Logout />
    </div>
  );
}

export const Route = createFileRoute("/logout")({
  component: RouteComponent,
});
