import {
  getPendingLetterReviews,
  getRecipientNameByLetterId,
  inviteExternalReviewer,
  toggleShouldLighthouseManageExternalComms,
} from "@/lib/queries";
import { ResultOf } from "gql.tada";
import { useMutation, useQuery } from "urql";

import { cn } from "@/lib/cn";
import { graphql } from "@/lib/graphql";
import { Select, Spinner } from "@radix-ui/themes";
import { useState } from "react";
import { Avatar } from "./avatar";

import {
  CheckIcon,
  ExclamationTriangleIcon,
  ExternalLinkIcon,
} from "@radix-ui/react-icons";
import { AnimatePresence, motion } from "framer-motion";

type LetterActionStatus = Exclude<
  ReturnType<typeof graphql.scalar<"LetterActionStatus">>,
  "draft"
>;

const letterActionStatus: {
  value: LetterActionStatus;
  label: string;
}[] = [
  {
    value: "pending",
    label: "Not Invited",
  },
  {
    value: "changes_requested",
    label: "Changes Requested",
  },
  {
    value: "approved",
    label: "Approved",
  },
  {
    value: "sent",
    label: "Pending Review",
  },
];

const letterActionStatusColorMapping: Record<
  LetterActionStatus,
  { dot: string; status: string }
> = {
  sent: {
    dot: "bg-[#ebb306]",
    status: "bg-[#fff9c3] text-[#a3783e] ring-[#ebb306]",
  },
  pending: {
    dot: "bg-[#575757]",
    status: "bg-[#dddddd] text-[#6e6e6e] ring-[#575757]",
  },

  approved: {
    dot: "bg-[#98dc2d]",
    status: "bg-[#e8ffc5] text-[#7fab3b] ring-[#98dc2d]",
  },
  changes_requested: {
    dot: "bg-[#ef4344]",
    status: "bg-[#fee2e1] text-[#c94a48] ring-[#ef4344]",
  },
};

export const LetterActionStatusBadge = (props: {
  status: LetterActionStatus;
}) => {
  const { status } = props;

  return (
    <div
      className={cn(
        "group flex w-fit flex-row gap-1 items-center rounded-full bg-gray-50 px-2 py-1 text-xs text-gray-600 flex-nowrap whitespace-nowrap ring-1 ring-inset ring-opacity-30",
        letterActionStatusColorMapping[status].status
      )}
    >
      {letterActionStatus.find((x) => x.value === status)?.label ?? ""}
    </div>
  );
};

const ShareMethodSelector = (props: {
  value: boolean;
  onChange: (value: boolean) => void;
}) => {
  const { value, onChange } = props;

  return (
    <Select.Root
      value={value.toString()}
      onValueChange={(value) => onChange(value === "true")}
      size={"1"}
    >
      <Select.Trigger className="rounded-md text-grey-200 ring-0 gap-3 text-xs items-center justify-center" />
      <Select.Content className="bg-grey-800">
        <Select.Item
          className="text-xs hover:bg-grey-700 hover:text-grey-100"
          value="true"
        >
          I want Lighthouse to reach out on my behalf
        </Select.Item>
        <Select.Item
          className="text-xs hover:bg-grey-700 hover:text-grey-100"
          value="false"
        >
          I will reach out to the reviewer myself
        </Select.Item>
      </Select.Content>
    </Select.Root>
  );
};

const ReviewRow = (props: {
  action: ResultOf<
    typeof getPendingLetterReviews
  >["getPendingLetterReviews"][number];
  refetch: () => void;
}) => {
  const { action, refetch } = props;

  const [commsOptimistic, setCommsOptimistic] = useState(
    action.isLighthouseManagingExternalComms
  );

  const [copyStatus, setCopyStatus] = useState<
    "pending" | "loading" | "success" | "error"
  >("pending");

  const inviteExternalReviewerMutation = useMutation(inviteExternalReviewer)[1];
  const toggleShouldLighthouseManageExternalCommsMutation = useMutation(
    toggleShouldLighthouseManageExternalComms
  )[1];

  const [{ data }] = useQuery({
    query: getRecipientNameByLetterId,
    variables: {
      letterActionId: action.id,
    },
  });

  const copyLink = async () => {
    if (disableActions || copyStatus === "loading") return;

    setCopyStatus("loading");

    const res = await inviteExternalReviewerMutation({
      letterActionId: action.id,
      shouldLighthouseManageExternalComms: false,
    });

    if (res.error || res.data?.inviteExternalReviewer == null) {
      setCopyStatus("error");
      return;
    }

    navigator.clipboard.writeText(res.data.inviteExternalReviewer);
    setCopyStatus("success");

    setTimeout(() => {
      setCopyStatus("pending");
    }, 1500);
  };

  const toggleLighthouseManageExternalComms = async (v: boolean) => {
    const original = commsOptimistic;
    setCommsOptimistic(v);

    const res = await toggleShouldLighthouseManageExternalCommsMutation({
      letterActionId: action.id,
      shouldLighthouseManageExternalComms: v,
    });

    if (res.error) {
      setCommsOptimistic(original);
      return;
    }

    refetch();
  };

  const disableActions =
    action.recipientType !== "external" ||
    action.status === "approved" ||
    action.status === "changes_requested";

  return (
    <div
      key={action.id}
      className="flex flex-col w-full gap-3 bg-white rounded-md px-3 py-2 shadow-border justify-center"
    >
      <div className="flex flex-row gap-2 items-center w-full h-full">
        <Signer
          name={data?.getLetterActionRecipientName ?? ""}
          email={action.externalEmail ?? ""}
        />
        <div className="ml-auto h-full flex items-center">
          <ShareMethodSelector
            value={commsOptimistic}
            onChange={toggleLighthouseManageExternalComms}
          />
        </div>
      </div>

      <div>
        {!commsOptimistic && (
          <span className="inline-flex flex-row gap-1 items-center text-xs text-grey-300">
            <div className="w-fit h-fit inline-flex flex-row gap-1 items-center relative">
              <AnimatePresence>
                {copyStatus === "success" && (
                  <motion.div
                    className="absolute top-0  bg-grey-200 px-2 py-1 rounded-md flex items-center text-grey-800 text-nowrap text-xs"
                    initial={{ opacity: 0, y: -25 }}
                    animate={{ opacity: 1, y: -30 }}
                    exit={{ opacity: 0, y: -25 }}
                    transition={{ type: "spring", stiffness: 500, damping: 25 }}
                  >
                    Link copied
                  </motion.div>
                )}

                {copyStatus === "error" && (
                  <motion.div
                    className="absolute top-0  bg-grey-200 px-2 py-1 rounded-md flex items-center text-grey-800 text-nowrap text-xs gap-1"
                    initial={{ opacity: 0, y: -25 }}
                    animate={{ opacity: 1, y: -30 }}
                    exit={{ opacity: 0, y: -25 }}
                    transition={{ type: "spring", stiffness: 500, damping: 25 }}
                  >
                    <ExclamationTriangleIcon />
                    Failed to copy link
                  </motion.div>
                )}
              </AnimatePresence>
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={copyLink}
                className={cn(
                  "text-blue font-normal inline-flex flex-row gap-1 items-center cursor-pointer transition-opacity relative",
                  copyStatus === "loading" && "opacity-70 cursor-wait"
                )}
              >
                <ExternalLinkIcon />
                Click here{" "}
              </motion.button>{" "}
            </div>
            to copy a link to share with your signer. This link is unique to
            each recipient.
          </span>
        )}
        {commsOptimistic && (
          <span className="text-xs text-grey-300">
            The Lighthouse team will reach out to the signer in the next 24
            hours.
          </span>
        )}
      </div>
    </div>
  );
};

const Signer = (props: { name: string; email: string }) => {
  const { name, email } = props;

  return (
    <div className="flex flex-row gap-2 items-center">
      <Avatar username={email} className="rounded-full w-7 h-7" />
      <div className="flex flex-col">
        <span className="text-sm text-grey-100">{name}</span>
        <span className="text-xs text-grey-300">{email}</span>
      </div>
    </div>
  );
};

const PendingSignersTable = (props: {
  pendingReviews: ResultOf<
    typeof getPendingLetterReviews
  >["getPendingLetterReviews"];
  refetchReviews: () => void;
}) => {
  const { pendingReviews, refetchReviews } = props;
  return (
    <div className="flex flex-col max-w-full">
      <div className="flex flex-col gap-2 text-md text-grey-200 p-4 pb-0 rounded-t-md">
        <span className="font-medium">Invite your signers</span>
        <p className="text-xs text-grey-300">
          Your signatories need to review and sign this letter. Please select an
          option below. You can copy a unique link to send yourself or select
          Lighthouse to send on your behalf.
        </p>
      </div>

      <div className="w-full flex flex-col gap-3 p-3">
        {pendingReviews?.map((action) => (
          <ReviewRow action={action} refetch={refetchReviews} />
        ))}
      </div>
    </div>
  );
};

const EverythingCompleteMessage = () => {
  return (
    <div className="p-4 flex flex-row items-center gap-3 text-sm text-grey-200">
      <div className="rounded-full p-0.5 border-[2px] border-positive">
        <CheckIcon className="w-5 h-5 text-positive" />
      </div>
      You've completed all the necessary steps to include this letter in your
      case
    </div>
  );
};

export const LetterReviewsTable = (props: { letterId: number }) => {
  const { letterId } = props;

  const [{ data, fetching }, refetch] = useQuery({
    query: getPendingLetterReviews,
    variables: {
      letterId: letterId,
    },
    requestPolicy: "network-only",
  });

  const refetchReviews = () => {
    refetch({ requestPolicy: "network-only" });
  };

  const pendingReviews = data?.getPendingLetterReviews.filter(
    (el) => el.status !== "approved"
  );

  return (
    <div className="flex flex-col rounded-md max-w-full bg-grey-800 shadow-border">
      {pendingReviews != null && pendingReviews.length > 0 && (
        <PendingSignersTable
          pendingReviews={pendingReviews}
          refetchReviews={refetchReviews}
        />
      )}

      {pendingReviews == null ||
        (pendingReviews.length === 0 && <EverythingCompleteMessage />)}

      {fetching && pendingReviews == null && <Spinner />}
    </div>
  );
};
