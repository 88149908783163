import React, { useState } from "react";
import { Editor } from "@tiptap/react";
import * as Popover from "@radix-ui/react-popover";
import { ActiveEditorComment } from "@lighthouse/editor";

import toast from "react-hot-toast";
import { ArrowUpIcon, TrashIcon } from "@radix-ui/react-icons";

import uniqolor from "uniqolor";
import { RichTextEditor } from "./textEditor";

import { Button } from "./button";
import { useEditorContext } from "@/providers/editorProvider";

interface CommentComposerPopoverProps {
  editor: Editor;
  activeComment: ActiveEditorComment | null;
  onDiscard: () => void;
  open: boolean;
  id: number;
  userId?: string;
  altId?: string;
  userName?: string;
}

type PopoverElementAnchorProps = Omit<
  Popover.PopoverAnchorProps,
  "children" | "virtualRef"
> & {
  element: HTMLElement | null;
};

function PopoverElementAnchor({ element, ...rest }: PopoverElementAnchorProps) {
  const ref = React.useRef<any>(element);

  ref.current = element;

  return <Popover.PopoverAnchor virtualRef={ref} {...rest} />;
}

const Controls = (props: {
  isSubmitting: boolean;
  isValid: boolean;
  onSubmit: () => void;
  onDiscard: () => void;
}) => {
  const { isSubmitting, isValid, onSubmit, onDiscard } = props;
  return (
    <div className="flex flex-row items-center justify-end gap-2">
      <Button
        variant="secondary"
        className="h-7 w-7 flex items-center justify-center p-0 bg-grey-600"
        onClick={onDiscard}
      >
        <TrashIcon width={30} />
      </Button>

      <Button
        variant="primary"
        className="h-7 w-7 flex items-center justify-center p-0 disabled:bg-grey-400"
        onClick={onSubmit}
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
      >
        <ArrowUpIcon width={30} />
      </Button>
    </div>
  );
};

export const commentElement = (
  id: string,
  isNewComment: boolean = false,
  dom: HTMLElement | Document = document
) => {
  const selector = isNewComment
    ? `[optimisticId="${id}"]`
    : `[commentid="${id}"]`;

  return dom.querySelector(selector) as HTMLElement | null;
};

export const CommentComposerPopover: React.FC<CommentComposerPopoverProps> = ({
  editor,
  activeComment,
  onDiscard,
  open,
}) => {
  const [commentContent, setCommentContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createComment, userName, onCommentAdded } = useEditorContext();

  const handleDiscard = () => {
    editor.commands.unsetNewComment();

    setCommentContent("");
    onDiscard();
  };

  const handleSubmit = async () => {
    if (
      !commentContent.trim() ||
      activeComment == null ||
      !activeComment.newCommentRange
    )
      return;

    try {
      setIsSubmitting(true);

      const htmlContentInRange = editor.state.doc.textBetween(
        activeComment.newCommentRange.from,
        activeComment.newCommentRange.to
      );

      const res = await createComment(commentContent, htmlContentInRange);

      console.log(res);

      // Create the comment in the database
      if (res.data?.id != null) {
        // Remove the temporary comment decoration
        editor.commands.unsetNewComment();

        // Add the permanent comment mark
        editor.commands.setComment(
          res.data.id.toString(),
          activeComment.newCommentRange
        );

        setCommentContent("");
        onCommentAdded();
      } else {
        toast.error("Failed to create comment");
      }
    } catch (error) {
      toast.error("Error creating comment");
    } finally {
      setIsSubmitting(false);
    }
  };

  const anchorElement = commentElement(
    activeComment?.id ?? "",
    true,
    editor.view.dom
  );

  const name = userName ?? "Unknown";

  return (
    <Popover.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          handleDiscard();
        }
      }}
      modal
    >
      <PopoverElementAnchor element={anchorElement} />

      <Popover.Portal>
        <Popover.Content
          className="z-100 bg-white rounded-lg overflow-hidden h-fit w-[400px] group/container shadow-border ring-inset"
          sideOffset={8} // Space between the anchor and the popover
          align="center"
          side="top"
          avoidCollisions
          collisionPadding={16}
          onInteractOutside={handleDiscard}
          onEscapeKeyDown={handleDiscard}
          onCloseAutoFocus={(evt) => {
            evt.preventDefault();
            editor.chain().focus().run();
          }}
        >
          <div className="max-w-[400px] min-w-[400px] flex flex-col gap-3 p-3">
            <div className="flex flex-row gap-2">
              <div
                className="flex items-center justify-center h-[22px] w-[22px] rounded-full font-bold text-white text-xs flex-shrink-0"
                style={{
                  backgroundColor: uniqolor(userName).color,
                }}
              >
                <span>{userName[0]}</span>
              </div>

              <div className="flex flex-col">
                <span className="font-medium text-neutral-600 text-sm">
                  {name}
                </span>
              </div>
            </div>
            <RichTextEditor
              placeholder="Write your comment..."
              onUpdate={setCommentContent}
              autoFocus
              editorClassName="break-all text-wrap"
            />

            <Controls
              isSubmitting={isSubmitting}
              isValid={commentContent.trim() !== ""}
              onSubmit={handleSubmit}
              onDiscard={handleDiscard}
            />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
