import { createFileRoute } from "@tanstack/react-router";

import { useState } from "react";
import { Spinner } from "@radix-ui/themes";

import { customerApi } from "@lighthouse/api";

import { ExternalLandingPage } from "@/components/externalLandingPage";
import { ExternalTokenExpiredPage } from "@/components/externalTokenExpiredPage";

import { useQuery } from "@tanstack/react-query";
import { LetterActionNotFoundPage } from "@/components/letterActionNotFoundPage";
import { ExternalLetterEditor } from "@/components/externalLetterEditor";

const LetterSignaturePage = () => {
  const { letterActionId } = Route.useParams();
  const { token, reviewed } = Route.useSearch();

  const [showModal, setShowModal] = useState(false);

  const hideLanding = () => {
    setShowModal(true);
  };

  const { data, isPending, error, refetch } = useQuery({
    retry: 1,
    queryKey: ["externalLetterAction", letterActionId],
    queryFn: async () => {
      const res = await customerApi.getLetterAction({
        letterActionId: parseInt(letterActionId),
        token: token,
      });

      if (res.success && res.data != null && res.data.type === "sign") {
        return res.data;
      }

      if (res.message === "403") {
        throw new Error("token expired");
      }

      throw new Error("not found");
    },
  });

  if (error?.message === "token expired")
    return (
      <ExternalTokenExpiredPage
        letterActionId={parseInt(letterActionId)}
        token={token}
      />
    );

  if (error != null) return <LetterActionNotFoundPage external />;

  if (isPending || data == null)
    return (
      <div className="flex flex-col items-center text-center h-screen w-screen justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="h-screen w-screen overflow-y-auto">
      {!reviewed && <ExternalLandingPage hide={hideLanding} />}
      <ExternalLetterEditor
        data={data}
        hideInitialInfoModal={!showModal}
        token={token}
        reviewed={reviewed}
        refetch={refetch}
      />
    </div>
  );
};

type SignSearch = { token: string; reviewed: boolean };

export const Route = createFileRoute("/external/letter/sign/$letterActionId")({
  component: () => <LetterSignaturePage />,
  validateSearch: (search: Record<string, unknown>): SignSearch => {
    return {
      token: (search.token as string) || "",
      reviewed: search.reviewed === true,
    };
  },
});
