import {
  Client,
  fetchExchange,
  gql,
  Provider,
  subscriptionExchange,
} from "urql";
import { retryExchange } from "@urql/exchange-retry";

import { authExchange } from "@urql/exchange-auth";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import * as schema from "@/codegen/schema.json";
import { useMemo } from "react";
import { ClerkLoaded, useAuth } from "@clerk/clerk-react";
import { parseFormValues } from "@/lib/formValues";
import { createClient as createWSClient } from "graphql-ws";
import { debounceExchange } from "@/lib/debounceExchange";

const url =
  import.meta.env.VITE_CUSTOMER_BACKEND_API_URL ??
  "https://customer-backend-prod.onrender.com";
const fetchUrl = `${url}/graphql`;
const wsUrl = fetchUrl.replace("http", "ws");

const isTokenInvalid = (
  token: string | null | undefined,
  isSignedIn: boolean
) => {
  if (!isSignedIn) return true;
  if (!token) return true;

  try {
    const [header, payload] = token.split(".");
    if (!header || !payload) return true;

    const decoded = JSON.parse(atob(payload));
    const expired = Date.now() >= decoded.exp * 1000;
    return expired;
  } catch {
    return true;
  }
};

const debouncedDefinitionSet = new Set<string>([
  "updateOnboardingNode",
  "patchUserEntity",
  "patchCompanyEntity",
  "updateCompanyData",
  "updateUserEntity",
]);
const debounce = debounceExchange({
  debounceDuration: 1000, // Set debounce duration
  filterFn: (op) => {
    if (op.kind !== "mutation") return false;

    return (
      op.kind === "mutation" &&
      op.query.definitions.filter((def) =>
        debouncedDefinitionSet.has(
          (def as { name?: { value: string } }).name?.value ?? ""
        )
      ).length > 0
    );
  },
});

const cache = cacheExchange({
  schema,
  resolvers: {
    OnboardingNode: {
      content: (parent) => {
        return parseFormValues(parent.content ?? "{}");
      },
    },
  },
  optimistic: {
    updateOnboardingNode(args: Record<string, unknown>) {
      const argsTyped = args as {
        onboardingUpdate: { onboardingNodeId: number; data: string };
      };
      return {
        __typename: "OnboardingNode",
        id: argsTyped.onboardingUpdate.onboardingNodeId,
        content: parseFormValues(argsTyped.onboardingUpdate.data),
      };
    },

    updateCompanyEntityData(args: Record<string, unknown>) {
      const argsTyped = args as {
        entityDataUpdateInput: {
          data: Record<string, unknown>;
          companyId: number;
        };
      };

      const returnObj = {
        __typename: "CompanyWorkspace",
        id: argsTyped.entityDataUpdateInput.companyId,
        entityData: {
          ...argsTyped.entityDataUpdateInput.data,
        },
      };
      return returnObj;
    },

    completeOnboardingNode(args: Record<string, unknown>) {
      const argsTyped = args as {
        onboardingNodeId: number;
      };

      return {
        __typename: "OnboardingNode",
        id: argsTyped.onboardingNodeId,
        completed: true,
      };
    },

    submitNodeForReview(args: Record<string, unknown>, cache) {
      const argsTyped = args as {
        nodeId: number;
      };

      const fragment = gql`
        fragment _ on OnboardingNode {
          id
          internalStatus
        }
      `;

      const readFragment: {
        id: number;
        internalStatus: string;
      } = cache.readFragment(fragment, {
        id: argsTyped.nodeId,
      });

      if (readFragment.internalStatus === "approved") return readFragment;

      return {
        __typename: "OnboardingNode",
        id: argsTyped.nodeId,
        internalStatus: "submitted_for_review",
      };
    },

    patchUserEntity(args: Record<string, unknown>, cache) {
      const argsTyped = args as {
        patchUserEntityInput: {
          userId: number;
          data: Record<string, unknown>;
        };
      };

      const fragment = gql`
        fragment _ on BasicUserContext {
          userEntity {
            id
            legalName
            role
            phoneNumber
            firstName
            lastName
          }
        }
      `;

      const readFragment: {
        userEntity?: {
          id?: number | null;
          legalName?: string | null;
          role?: string | null;
          phoneNumber?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null = cache.readFragment(fragment, {
        id: argsTyped.patchUserEntityInput.userId,
      });

      const finalObject = {
        ...readFragment?.userEntity,
        ...argsTyped.patchUserEntityInput.data,
      };

      return finalObject;
    },
  },
});

const InnerGraphqlProvider = ({ children }: { children: React.ReactNode }) => {
  const { getToken, isSignedIn, isLoaded } = useAuth();

  const graphqlClient = useMemo(() => {
    const wsClient = createWSClient({
      url: async () => {
        const token = await getToken();
        if (isTokenInvalid(token, isSignedIn ?? false)) return wsUrl;
        return wsUrl + "?token=" + token;
      },
    });

    return new Client({
      url: fetchUrl,
      exchanges: [
        devtoolsExchange,
        // refocusExchange(),
        requestPolicyExchange({
          shouldUpgrade: (op) => op.context.requestPolicy !== "cache-only",
        }),
        cache,
        debounce,
        retryExchange({
          initialDelayMs: 1000,
          maxDelayMs: 10000,
          randomDelay: true,
          maxNumberAttempts: 3,
          retryIf: (error) => {
            return !!error.networkError || error.response.status === 401;
          },
        }),
        authExchange(async (utils) => {
          let token = await getToken();

          return {
            addAuthToOperation(operation) {
              if (isTokenInvalid(token, isSignedIn ?? false)) {
                return operation;
              }
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
              });
            },
            didAuthError(error) {
              return (
                error.response?.status === 401 ||
                error.message.includes("Unauthorized")
              );
            },
            refreshAuth: async () => {
              token = await getToken({ skipCache: true });
            },
            willAuthError: () => {
              if (!isLoaded) return true;
              if (isTokenInvalid(token, isSignedIn ?? false)) {
                return true;
              }
              return false;
            },
          };
        }),
        subscriptionExchange({
          forwardSubscription(request) {
            const input = { ...request, query: request.query || "" };
            return {
              subscribe(sink) {
                const unsubscribe = wsClient.subscribe(input, sink);
                return { unsubscribe };
              },
            };
          },
        }),
        fetchExchange,
      ],
    });
  }, [getToken, isLoaded, isSignedIn]);

  return <Provider value={graphqlClient}>{children}</Provider>;
};

export const GraphqlProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ClerkLoaded>
      <InnerGraphqlProvider>{children}</InnerGraphqlProvider>
    </ClerkLoaded>
  );
};
