import { Field, OnboardingForm } from "@/components/form";
import { LetterSigner, LetterSigners } from "@/components/letterSigners";
import { SupportLetterPlaceholder } from "@/components/supportLetterPreview";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";

export const ExpertLetter = () => {
  const { currentOnboardingNodeId, nodeStatus, currentOnboardingNodeName } =
    useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      <Field
        id="signers"
        description={
          <span className="text-grey-300 text-[12px]">
            Expert letters are intended to speak directly to specific
            achievements or roles you've held that correspond to your visa
            application eligibility and strategy. Expert letters are typically
            signed by those at the respective companies or organizations
            affiliated with the criteria included in your application. Signers
            for expert letters do not have to be US-based. However, they should
            be sufficiently senior to speak directly to your role,
            responsibilities, or achievements as it relates to specific criteria
            in your visa application strategy.
          </span>
        }
        rejectionBorderClassName="p-2"
      >
        <LetterSigners
          value={(data.signers?.value ?? []) as LetterSigner[]}
          onChange={(signers) => doSetData("signers", signers)}
          disabled={
            nodeStatus === "submitted_for_review" || nodeStatus === "approved"
          }
        />
      </Field>

      <div className="w-full pr-12 flex items-center">
        <SupportLetterPlaceholder />
      </div>
    </OnboardingForm>
  );
};
