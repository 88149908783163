import { cn } from "@/lib/cn";
import React from "react";
import { motion } from "framer-motion";

export const ChatBubble = React.forwardRef(
  (
    props: {
      id?: string;
      children: React.ReactNode;
      className?: string;
    },
    ref?: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.35, ease: "easeInOut" }}
        className={cn(
          "min-w-[628px] max-w-[628px] h-fit rounded-lg last:rounded-bl-[4px] py-6 px-8 gap-6 bg-grey-800 shadow-bubble mx-1",
          props.className
        )}
        ref={ref}
        id={props.id}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut", delay: 0.2 }}
        >
          {props.children}
        </motion.div>
      </motion.div>
    );
  }
);
