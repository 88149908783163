import { PlusCircledIcon } from "@radix-ui/react-icons";
import { Field } from "./form";
import { TrashIcon } from "./icons/trash";
import { Input, PhoneNumber, PhoneNumberInput } from "./inputs";
import { RadioSelect } from "./radio";

export type LetterSigner = {
  signerFullName: string;
  signerEmail: string;
  signerPhoneNumber: PhoneNumber;
  contactSelfPreference: boolean;
  linkedInUrl?: string;
};

const LetterSignerForm = (props: {
  signer: LetterSigner;
  onChange: (signer: LetterSigner) => void;
  onDelete?: () => void;
  disabled?: boolean;
}) => {
  const { signer, onChange, onDelete, disabled } = props;

  const doSetData = (
    key: keyof LetterSigner,
    value: string | boolean | Record<string, string> | string[]
  ) => {
    onChange({
      ...signer,
      [key]: value,
    });
  };

  return (
    <div className="pt-1.5 px-3 pb-4 gap-1 flex flex-col bg-grey-700 rounded-lg relative">
      {onDelete != null && (
        <button
          onClick={() => onDelete?.()}
          className="absolute right-3 top-5"
          disabled={disabled}
        >
          <TrashIcon className="fill-grey-300 w-3.5 h-3.5" />
        </button>
      )}

      <Field
        label="Signer's full name"
        // description="Name a US-based expert that would sign this letter."
        className="pr-0"
      >
        <Input
          type="text"
          placeholder="Full name"
          value={signer.signerFullName as string}
          onValueChange={(val) => doSetData("signerFullName", val)}
        />
      </Field>

      <Field label="Signer's email address" className="pr-0">
        <Input
          type="email"
          placeholder="name@domain.com"
          value={signer.signerEmail as string}
          onValueChange={(val) => doSetData("signerEmail", val)}
        />
      </Field>

      <Field label="Signer's phone number" className="pr-0">
        <PhoneNumberInput
          value={signer.signerPhoneNumber}
          onValueChange={(val) => doSetData("signerPhoneNumber", val)}
          placeholder="Enter phone number"
        />
      </Field>

      <Field
        label="LinkedIn URL"
        description="Please provide the signer's LinkedIn profile URL if available"
        className="pr-0"
      >
        <Input
          placeholder="LinkedIn URL"
          type="text"
          className="w-full"
          value={signer.linkedInUrl ?? ""}
          onValueChange={(val) => doSetData("linkedInUrl", val)}
        />
      </Field>

      <Field
        label="Who do you want to be responsible for the contact with the signer?"
        description="Lighthouse can take over your communication with the signer to get the signature. On your end you will only have to review the draft letter."
        className="pr-0"
      >
        <RadioSelect
          value={signer.contactSelfPreference as boolean}
          onChange={(val) => doSetData("contactSelfPreference", val)}
          orientation="vertical"
          options={[
            { value: true, label: "I want to contact the signer personally" },
            {
              value: false,
              label: "I prefer Lighthouse to take over communications",
            },
          ]}
        />
      </Field>
    </div>
  );
};

const defaultSigner: LetterSigner = {
  signerFullName: "",
  signerEmail: "",
  signerPhoneNumber: {
    country: "US",
    phoneNumber: "",
  },
  contactSelfPreference: false,
};

export const LetterSigners = (props: {
  value: LetterSigner[];
  onChange: (value: LetterSigner[]) => void;
  disabled?: boolean;
}) => {
  const { value, onChange, disabled } = props;

  const doAddSigner = () => {
    onChange([...value, defaultSigner]);
  };

  const doRemoveSigner = (index: number) => {
    const newSigners = [...value];
    newSigners.splice(index, 1);
    onChange(newSigners);
  };

  const doChangeSigner = (index: number, signer: LetterSigner) => {
    const newSigners = [...value];
    newSigners[index] = signer;
    onChange(newSigners);
  };

  return (
    <div className="flex flex-col gap-2">
      <LetterSignerForm
        signer={value.length === 0 ? defaultSigner : value[0]}
        onChange={(signer) => doChangeSigner(0, signer)}
        disabled={disabled}
      />

      {value.length > 1 && (
        <>
          {value.slice(1).map((signer, index) => (
            <div
              key={index}
              className="flex flex-row gap-2 items-center w-full"
            >
              <LetterSignerForm
                signer={signer}
                onChange={(signer) => doChangeSigner(index + 1, signer)}
                onDelete={() => doRemoveSigner(index + 1)}
                disabled={disabled}
              />
            </div>
          ))}
        </>
      )}

      <button
        className="text-xs text-blue flex flex-row gap-1.5 items-center mt-2"
        onClick={doAddSigner}
        disabled={disabled}
      >
        <PlusCircledIcon className="w-3 h-3" />
        Add another signer
      </button>
      <p className="text-xs text-grey-400">
        You can add multiple signers if you are not sure and we can find the
        best fit.
      </p>
    </div>
  );
};
