import { useState } from "react";
import { Modal } from "./modal";
import { Select } from "./select";
import { Button } from "./button";
import { createOnboardingNodeMutation } from "@/lib/queries";
import { useMutation } from "urql";
import { customToast } from "./toast";
import { WarningIcon } from "./icons/warning";
import { Input } from "./inputs";

const options = [
  { value: "critical-role", label: "Critical Role" },
  { value: "authorship", label: "Authorship" },
  { value: "award", label: "Award" },
  { value: "membership", label: "Membership" },
  { value: "judging", label: "Judging" },
  { value: "original-contribution", label: "Original Contribution" },
  { value: "high-compensation", label: "High Compensation" },
  { value: "press", label: "Press" },
];

export const AddNextCriteriaModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onboardingId: number;
}) => {
  const [selectedOption, setSelectedOption] = useState<string>();
  const [name, setName] = useState("");

  const createNodeMutation = useMutation(createOnboardingNodeMutation)[1];
  const [loading, setLoading] = useState(false);

  const doCreateNode = async () => {
    if (
      options.find((option) => option.value === selectedOption) == null ||
      selectedOption == null ||
      props.onboardingId == null
    ) {
      return;
    }

    setLoading(true);
    const res = await createNodeMutation({
      input: {
        nodeType: selectedOption,
        onboardingId: props.onboardingId,
        name: name,
      },
    });

    if (res.error) {
      customToast(
        "Failed to add criteria",
        <WarningIcon />,
        "Failed to add a criteria, please try again."
      );
      setLoading(false);
      return;
    }

    setLoading(false);
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onOpenChange={(value) => props.setOpen(value)}
      title="Add a criteria"
      fitContent
      contentClassName="w-[514px] z-5"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-100">
          Add criteria to your case strategy that you believe will significantly
          impact your application. Our team of experts will verify them and
          incorporate them as appropriate into your application preparation.
        </p>

        <Select
          modal
          value={selectedOption}
          onValueChange={setSelectedOption}
          placeholder="Select a criteria"
          options={options}
          hideSearch
        />

        <Input
          placeholder="Name of a company, role, award or location linked to the criteria"
          value={name}
          onValueChange={setName}
        />

        <div className="flex flex-row items-center mt-2">
          <Button variant="secondary" onClick={() => props.setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={doCreateNode}
            className="ml-auto"
            loading={loading}
            disabled={
              selectedOption == null ||
              loading ||
              options.find((option) => option.value === selectedOption) ==
                null ||
              name.trim().length === 0
            }
          >
            Add new criteria
          </Button>
        </div>
      </div>
    </Modal>
  );
};
