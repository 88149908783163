import { CompanyDashboard, Dashboard } from "@/components/dashboard";
import { EmployerTutorialModal } from "@/components/tutorials/employerTutorial";
import { NoOnboardings } from "@/components/noOnboardings";
import { CompanySidebarFrame, SidebarFrame } from "@/components/sidebarFrame";
import { useWorkspaces, useWorkspaceStore } from "@/lib/hooks/workspaces";
import { getOnboarding, getWorkspaces } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import { OnboardingDataProvider } from "@/providers/onboardingDataProvider";

import {
  createFileRoute,
  redirect,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import { useEffect } from "react";
import { useQuery } from "urql";
import { Spinner } from "@radix-ui/themes";
import { PageLoaderSkeleton } from "@/components/skeletons";
import { useAdminStore } from "@/lib/hooks/admin";

const CompanyHome = () => {
  const { rawId } = useWorkspaces();

  return (
    <CompanyProvider companyId={rawId}>
      <CompanySidebarFrame>
        <EmployerTutorialModal />
        <CompanyDashboard />
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

const UserHome = (props: { onboardingId: number }) => {
  const [{ data }] = useQuery({
    query: getOnboarding,
    variables: { id: props.onboardingId },
    requestPolicy: "cache-and-network",
  });
  const nav = useNavigate();
  const router = useRouter();

  useEffect(() => {
    if (data == null) return;

    for (const group of data.onboarding.groups) {
      if (group.nodes.length === 0) continue;

      const firstNode = group.nodes[0];
      try {
        router.preloadRoute({
          to: "/group/$groupId",
          params: { groupId: group.id },
          search: { nodeId: firstNode.id },
        });
      } catch (e) {
        // console.error(e);
      }
    }
  }, [data, router]);

  if (data == null) {
    return (
      <OnboardingDataProvider onboardingId={props.onboardingId}>
        <SidebarFrame>
          <div className="w-full h-full flex items-center justify-center">
            <Spinner />
          </div>
        </SidebarFrame>
      </OnboardingDataProvider>
    );
  }

  const gatedOnboarding = data?.onboarding.groups.find((x) => x.id === "gate");

  if ((gatedOnboarding?.nodes.filter((x) => !x.completed) ?? []).length > 0) {
    nav({ to: "/group/$groupId", params: { groupId: "gate" } });
    return;
  }

  return (
    <OnboardingDataProvider onboardingId={props.onboardingId}>
      <SidebarFrame>
        <Dashboard onboardingId={props.onboardingId} />
      </SidebarFrame>
    </OnboardingDataProvider>
  );
};

const WorkspaceHome = () => {
  const { selectedWorkspace, fetching } = useWorkspaces();
  const onboardingList = selectedWorkspace?.onboardings ?? [];

  return (
    <div className="w-screen h-screen max-w-screen">
      {selectedWorkspace == null && fetching && <PageLoaderSkeleton />}

      {selectedWorkspace != null && (
        <>
          {selectedWorkspace.id.startsWith("company") && <CompanyHome />}

          {selectedWorkspace.id.startsWith("user") && (
            <>
              {onboardingList.length > 0 &&
              selectedWorkspace.selectedOnboardingId != null ? (
                <UserHome
                  onboardingId={selectedWorkspace.selectedOnboardingId}
                />
              ) : (
                <NoOnboardings />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export const Route = createFileRoute("/_portal/home")({
  preload: true,
  beforeLoad: async ({ context }) => {
    const overrideUserId = useAdminStore.getState().overrideUserId;
    const res = await context.client.query(getWorkspaces, { overrideUserId });

    const localSelectedWorkspace =
      useWorkspaceStore.getState().selectedWorkspace;

    if (res.data == null || res.data.getWorkspaces.length === 0) {
      return;
    }

    // validate if cached workspace is on server
    if (
      localSelectedWorkspace != null &&
      res.data.getWorkspaces.find((x) => x.id === localSelectedWorkspace.id) ==
        null
    ) {
      const firstWorkspace = res.data.getWorkspaces[0];

      useWorkspaceStore.setState({
        selectedWorkspace: {
          ...firstWorkspace,
          selectedOnboardingId: firstWorkspace.name.startsWith("user")
            ? firstWorkspace.onboardings[0].id
            : undefined,
        },
      });
    }

    const finalSelectedWorkspace =
      useWorkspaceStore.getState().selectedWorkspace;

    if (finalSelectedWorkspace == null) return;
    if (finalSelectedWorkspace?.onboardings.length === 0) return;

    const firstOnboarding = finalSelectedWorkspace.onboardings[0];

    if (finalSelectedWorkspace.name.startsWith("user")) {
      const onboardingData = await context.client.query(getOnboarding, {
        id: firstOnboarding.id,
      });

      const gateNodes = onboardingData.data?.onboarding.groups.find(
        (x) => x.id === "gate"
      )?.nodes;

      if (gateNodes == null || gateNodes.length === 0) return;

      const incompleteNodes = gateNodes.filter((x) => !x.completed);

      if (incompleteNodes.length === 0) return;

      throw redirect({
        to: "/group/$groupId",
        params: { groupId: "gate" },
        search: { nodeId: incompleteNodes[0].id },
      });
    }
  },
  component: () => <WorkspaceHome />,
});
