import { useMutation, useQuery } from "urql";
import {
  useQueryClient,
  useMutation as useTanstackMutation,
  useQuery as useTanstackQuery,
} from "@tanstack/react-query";
import { customerApi } from "@lighthouse/api";
import {
  createComment as createCommentMutation,
  createReply as createReplyMutation,
  deleteComment as deleteCommentMutation,
  getLetterComments,
  resolveComment as resolveCommentMutation,
  updateComment as updateCommentMutation,
} from "../queries";

export type Comment = {
  id: number;
  authorName: string;
  contentHtml: string;
  highlightedTextHtml?: string;
  createdAt: string;
  showAsLighthouseAuthor: boolean;
  resolved: boolean;
  replies: Reply[];
  authorId: string;
};

export type Reply = {
  id: number;
  authorName: string;
  contentHtml: string;
  highlightedTextHtml?: string;
  createdAt: string;
  showAsLighthouseAuthor: boolean;
  authorId: string;
};

export interface CommentsHookResult {
  comments: Comment[];
  isLoading: boolean;
  error: Error | null;
  createComment: (
    content: string,
    highlightedTextHtml?: string
  ) => Promise<{
    data?: {
      id: number | undefined;
    };
    error?: Error;
  }>;
  createReply: (
    parentId: number,
    content: string,
    highlightedTextHtml?: string
  ) => Promise<{ data?: any; error?: Error }>;
  updateComment: (
    commentId: number,
    content: string
  ) => Promise<{ data?: any; error?: Error }>;
  deleteComment: (commentId: number) => Promise<{ data?: any; error?: Error }>;
  resolveComment: (
    commentId: number,
    resolved: boolean
  ) => Promise<{ data?: any; error?: Error }>;
  refetch: () => void;
}

/**
 * Hook for interacting with external comments API using tanstack query
 */
export const useExternalComments = (params: {
  letterActionId: number;
  token: string;
}): CommentsHookResult => {
  const { letterActionId, token } = params;
  const queryClient = useQueryClient();

  // Query to fetch comments
  const { data, isLoading, error, refetch } = useTanstackQuery({
    queryKey: ["externalComments", letterActionId],
    queryFn: async () => {
      const response = await customerApi.getLetterComments({
        letterActionId,
        token,
      });

      if (!response.success) {
        throw new Error(response.message || "Failed to fetch comments");
      }

      return response.data?.comments || [];
    },
  });

  // Mutation to create a comment
  const createCommentMutation = useTanstackMutation({
    mutationFn: async ({
      content,
      highlightedTextHtml,
    }: {
      content: string;
      highlightedTextHtml?: string;
    }) => {
      const response = await customerApi.createComment({
        contentHtml: content,
        highlightedTextHtml,
        letterActionId,
        token,
      });

      if (!response.success) {
        throw new Error(response.message || "Failed to create comment");
      }

      return response.data?.id || 0;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["externalComments", letterActionId],
      });
    },
  });

  // Mutation to create a reply
  const createReplyMutation = useTanstackMutation({
    mutationFn: async ({
      parentId,
      content,
      highlightedTextHtml,
    }: {
      parentId: number;
      content: string;
      highlightedTextHtml?: string;
    }) => {
      const response = await customerApi.createReply({
        parentId,
        contentHtml: content,
        highlightedTextHtml,
        letterActionId,
        token,
        letterId: 0, // This is required by the API but not used for external comments
      });

      if (!response.success) {
        throw new Error(response.message || "Failed to create reply");
      }

      return response.data?.id || 0;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["externalComments", letterActionId],
      });
    },
  });

  // Mutation to update a comment
  const updateCommentMutation = useTanstackMutation({
    mutationFn: async ({
      commentId,
      content,
    }: {
      commentId: number;
      content: string;
    }) => {
      const response = await customerApi.editComment({
        commentId,
        contentHtml: content,
        letterActionId,
        token,
      });

      if (!response.success) {
        throw new Error(response.message || "Failed to update comment");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["externalComments", letterActionId],
      });
    },
  });

  // Mutation to delete a comment
  const deleteCommentMutation = useTanstackMutation({
    mutationFn: async ({ commentId }: { commentId: number }) => {
      const response = await customerApi.deleteComment({
        commentId,
        letterActionId,
        token,
      });

      if (!response.success) {
        throw new Error(response.message || "Failed to delete comment");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["externalComments", letterActionId],
      });
    },
  });

  // Mutation to resolve a comment
  const resolveCommentMutation = useTanstackMutation({
    mutationFn: async ({
      commentId,
      resolved,
    }: {
      commentId: number;
      resolved: boolean;
    }) => {
      const response = await customerApi.resolveComment({
        commentId,
        resolved,
        letterActionId,
        token,
      });

      if (!response.success) {
        throw new Error(response.message || "Failed to resolve comment");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["externalComments", letterActionId],
      });
    },
  });

  return {
    comments: data || [],
    isLoading,
    error: error as Error | null,
    createComment: async (content: string, highlightedTextHtml?: string) => {
      try {
        const id = await createCommentMutation.mutateAsync({
          content,
          highlightedTextHtml,
        });
        return {
          data: { id },
          error: undefined,
        };
      } catch (error) {
        return {
          data: undefined,
          error: error instanceof Error ? error : new Error(String(error)),
        };
      }
    },
    createReply: async (
      parentId: number,
      content: string,
      highlightedTextHtml?: string
    ) => {
      try {
        const id = await createReplyMutation.mutateAsync({
          parentId,
          content,
          highlightedTextHtml,
        });
        return {
          data: { createReply: { id, contentHtml: content } },
          error: undefined,
        };
      } catch (error) {
        return {
          data: undefined,
          error: error instanceof Error ? error : new Error(String(error)),
        };
      }
    },
    updateComment: async (commentId: number, content: string) => {
      try {
        await updateCommentMutation.mutateAsync({ commentId, content });
        return {
          data: { editComment: { id: commentId, contentHtml: content } },
          error: undefined,
        };
      } catch (error) {
        return {
          data: undefined,
          error: error instanceof Error ? error : new Error(String(error)),
        };
      }
    },
    deleteComment: async (commentId: number) => {
      try {
        await deleteCommentMutation.mutateAsync({ commentId });
        return {
          data: { deleteComment: true },
          error: undefined,
        };
      } catch (error) {
        return {
          data: undefined,
          error: error instanceof Error ? error : new Error(String(error)),
        };
      }
    },
    resolveComment: async (commentId: number, resolved: boolean) => {
      try {
        await resolveCommentMutation.mutateAsync({ commentId, resolved });
        return {
          data: { resolveComment: { id: commentId } },
          error: undefined,
        };
      } catch (error) {
        return {
          data: undefined,
          error: error instanceof Error ? error : new Error(String(error)),
        };
      }
    },
    refetch,
  };
};

/**
 * Hook for interacting with internal comments API using urql
 */
export const useComments = (params: {
  letterActionId: number;
}): CommentsHookResult => {
  const { letterActionId } = params;

  // Query to fetch comments
  const [{ data, fetching, error }, refetch] = useQuery({
    query: getLetterComments,
    variables: { letterActionId },
  });

  // Mutation to create a comment
  const [_, createCommentMutate] = useMutation(createCommentMutation);

  // Mutation to create a reply
  const [__, createReplyMutate] = useMutation(createReplyMutation);

  // Mutation to update a comment
  const [___, updateCommentMutate] = useMutation(updateCommentMutation);

  // Mutation to delete a comment
  const [____, deleteCommentMutate] = useMutation(deleteCommentMutation);

  // Mutation to resolve a comment
  const [_____, resolveCommentMutate] = useMutation(resolveCommentMutation);

  // Transform the GraphQL response to match our common Comment type
  const comments: Comment[] =
    data?.getCommentsByLetterActionId?.map((comment) => ({
      id: comment.id,
      authorName: comment.authorName ?? "",
      contentHtml: comment.contentHtml ?? "",
      highlightedTextHtml: comment.highlightedTextHtml ?? undefined,
      createdAt: comment.createdAt,
      showAsLighthouseAuthor: comment.showAsLighthouseAuthor,
      resolved: comment.resolved,
      authorId: comment.authorId,
      replies:
        comment.replies?.map((reply: any) => ({
          id: reply.id,
          authorName: reply.authorName,
          contentHtml: reply.contentHtml,
          highlightedTextHtml: reply.highlightedTextHtml ?? undefined,
          createdAt: reply.createdAt,
          showAsLighthouseAuthor: reply.showAsLighthouseAuthor,
          authorId: reply.authorId,
        })) || [],
    })) || [];

  return {
    comments,
    isLoading: fetching,
    error: error ? new Error(error.message) : null,
    createComment: async (content: string, highlightedTextHtml?: string) => {
      // Add type assertion to handle the GraphQL input type
      const result = await createCommentMutate({
        input: {
          letterActionId,
          contentHtml: content,
          highlightedTextHtml,
        } as any, // Type assertion to bypass type checking for the GraphQL input
      });
      refetch({ requestPolicy: "network-only" });
      return {
        data: { id: result.data?.createComment.id as number | undefined },
        error: result.error,
      };
    },
    createReply: async (
      parentId: number,
      content: string,
      highlightedTextHtml?: string
    ) => {
      // Add type assertion to handle the GraphQL input type
      const result = await createReplyMutate({
        input: {
          parentId,
          contentHtml: content,
          highlightedTextHtml,
        } as any, // Type assertion to bypass type checking for the GraphQL input
      });
      refetch({ requestPolicy: "network-only" });
      return { data: result.data, error: result.error };
    },
    updateComment: async (commentId: number, content: string) => {
      const result = await updateCommentMutate({
        input: {
          commentId,
          contentHtml: content,
        },
      });
      refetch({ requestPolicy: "network-only" });
      return { data: result.data, error: result.error };
    },
    deleteComment: async (commentId: number) => {
      const result = await deleteCommentMutate({
        input: {
          commentId,
        },
      });
      refetch({ requestPolicy: "network-only" });
      return { data: result.data, error: result.error };
    },
    resolveComment: async (commentId: number, resolved: boolean) => {
      const result = await resolveCommentMutate({
        input: {
          commentId,
          resolved,
        },
      });
      refetch({ requestPolicy: "network-only" });
      return { data: result.data, error: result.error };
    },
    refetch: () => refetch({ requestPolicy: "network-only" }),
  };
};
