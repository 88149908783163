import { getFileById } from "@/lib/queries";
import { Spinner } from "@radix-ui/themes";
import { useQuery } from "urql";
import { Modal } from "./modal";

export const FileViewer = (props: { id: number; className?: string }) => {
  const { id } = props;

  const [{ data, fetching }] = useQuery({
    query: getFileById,
    variables: { id: id },
    requestPolicy: "network-only",
  });

  return (
    <div className="w-full h-full flex items-center justify-center rounded-sm p-1">
      {fetching && <Spinner />}
      {!fetching && data?.getFile?.presignedUrl != null && (
        <object
          data={data.getFile.presignedUrl}
          type={data.getFile.mimeType}
          className={`w-full h-full rounded-sm object-contain ${data.getFile.mimeType?.startsWith("image") && "max-w-fit max-h-fit"}`}
          name={data.getFile.name}
        />
      )}
    </div>
  );
};

export const FileViewerModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  fileId: number;
}) => {
  const [{ data, fetching }] = useQuery({
    query: getFileById,
    variables: { id: props.fileId },
  });

  return (
    <Modal
      open={props.open}
      onOpenChange={props.setOpen}
      title={fetching ? "Loading..." : (data?.getFile?.name ?? "Unknown File")}
    >
      <div className="w-full h-full flex flex-col items-center justify-center pr-12">
        <FileViewer id={props.fileId} />
      </div>
    </Modal>
  );
};
