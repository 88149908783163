import { createFileRoute } from "@tanstack/react-router";
import { SignIn } from "@clerk/clerk-react";

import { useEffect, useState } from "react";
import { Logo } from "@/components/logo";

const useClerkErrorIndicator = () => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const checkForError = () => {
      const errorContainer = document.querySelector(
        ".cl-formFieldErrorText__identifier"
      );
      if (errorContainer instanceof HTMLElement) {
        const parentDiv = errorContainer.closest(".cl-internal-wl6txu");
        if (parentDiv instanceof HTMLElement) {
          parentDiv.style.display = "none";
          setHasError(true);
        }

        errorContainer.remove();
      }
    };

    const observer = new MutationObserver(() => {
      checkForError();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return hasError;
};

const LoginErrorMessage = () => (
  <div className="text-xs text-negative">
    We couldn't recognize your email address. Check if it's correct, or try a
    different one. If you need help{" "}
    <a
      href="mailto:support@lighthousehq.com"
      className="underline underline-offset-[3px]"
    >
      contact us
    </a>
    .
  </div>
);

const Login = () => {
  const errorElement = useClerkErrorIndicator();

  return (
    <div className="flex flex-col w-full h-screen bg-[url(/backgrounds/login2.webp)] bg-cover bg-center">
      <div className="flex flex-col py-6 px-8 w-full">
        <Logo size={128} />
      </div>

      <div className="w-full h-2/3 flex flex-col items-center justify-center gap-2">
        <p className="text-center w-72">
          <span className="text-grey-300 font-serif text-2xl">
            Welcome to Lighthouse
          </span>
          <br />
          <span className="text-grey-300 text-sm">
            Start your immigration journey today
          </span>
        </p>

        <SignIn
          appearance={{
            elements: {
              footer: {
                display: "none",
              },
              card: {
                backgroundColor: "transparent",
                boxShadow: "none",
                paddingTop: "0",
              },
              formFieldLabel: {
                font: "Inter",
                color: "#666666",
                fontSize: "0.5 rem",
                display: "none",
              },
              formField: {
                margin: "none",
              },
              cardBox: {
                boxShadow: "none",
              },
              header: {
                display: "none",
              },
              form: {
                gap: "1rem",
              },
            },
          }}
        />
        {errorElement && (
          <div className="-translate-y-[60%] w-[320px]">
            <LoginErrorMessage />
          </div>
        )}
      </div>
    </div>
  );
};

export const Route = createFileRoute("/_login/$")({
  component: Login,
});
