import { cn } from "@/lib/cn";
import { motion } from "framer-motion";

export const TypingIndicator = () => {
  return (
    <div
      className={cn(
        "w-fit h-fit rounded-lg rounded-bl-[4px] py-4 px-6 gap-6 bg-grey-800 shadow-bubble"
      )}
    >
      <div className="flex space-x-1.5 px-2">
        {[0, 1, 2].map((i) => (
          <motion.div
            key={i}
            className="w-2 h-2 bg-grey-400 rounded-full"
            animate={{
              opacity: [0.4, 1, 0.4],
            }}
            transition={{
              duration: 0.8,
              repeat: Infinity,
              delay: i * 0.4,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>
    </div>
  );
};
