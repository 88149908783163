import { useEditorContext } from "@/providers/editorProvider";

import { useState } from "react";

import uniqolor from "uniqolor";
import moment from "moment";
import { FiCheck, FiMoreHorizontal } from "react-icons/fi";
import { cn } from "@/lib/cn";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { Button } from "./button";
import { Mark } from "./mark";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

import { type Comment, type Reply } from "@/lib/hooks/comments";
import { RichTextEditor } from "./textEditor";
import { ArrowUpIcon, CheckCircledIcon } from "@radix-ui/react-icons";
import { AnimatePresence, motion } from "framer-motion";

const CommentMenu = (props: {
  handleDelete: () => void;
  handleEdit: () => void;
}) => {
  const { handleDelete, handleEdit } = props;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="text-xl text-grey-400 hover:text-grey-200">
        <FiMoreHorizontal />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        side="left"
        sideOffset={10}
        className="rounded-md ring-1 ring-grey-200 ring-opacity-5 focus:outline-none bg-white"
      >
        <div className="py-0.5">
          <DropdownMenu.Item>
            <button
              onClick={handleEdit}
              className="flex flex-row items-center gap-2 text-xs text-grey-300 px-3 py-1 w-full"
            >
              Edit
            </button>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <button
              onClick={handleDelete}
              className="flex flex-row items-center gap-2 text-xs text-[#d36161] hover:bg-[#fee2e2] px-3 py-1 w-full"
            >
              Delete
            </button>
          </DropdownMenu.Item>
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const CommentViewer = (props: { comment: Comment | Reply }) => {
  const { comment } = props;

  return (
    <div className="flex flex-col gap-2 px-1 pt-2">
      {comment.highlightedTextHtml && (
        <div className="border-l-2 border-grey-600 px-2 py-1 text-sm text-grey-400 italic">
          <div
            dangerouslySetInnerHTML={{ __html: comment.highlightedTextHtml }}
          />
        </div>
      )}
      <div
        className={cn(
          "overflow-hidden text-sm text-grey-300 whitespace-pre-wrap break-words prose prose-sm"
        )}
      >
        <div dangerouslySetInnerHTML={{ __html: comment.contentHtml }} />
      </div>
    </div>
  );
};

const CommentEditor = (props: {
  comment: Comment | Reply;
  parentCommentId?: string;
  setIsEditing: (value: boolean) => void;
}) => {
  const { comment, setIsEditing } = props;

  const [content, setContent] = useState(comment.contentHtml);

  const { updateComment } = useEditorContext();

  const saveComment = () => {
    updateComment?.(comment.id, content);
    setIsEditing(false);
  };

  const discard = () => {
    setContent("");
    setIsEditing(false);
  };

  return (
    <div className="flex flex-col gap-3 mt-2">
      <RichTextEditor content={content} onUpdate={setContent} />

      <div className="flex flex-row items-center justify-end gap-2">
        <Button variant="secondary" onClick={discard}>
          <span className="text-xs">Discard</span>
        </Button>

        <Button
          variant="primary"
          disabled={content === ""}
          onClick={saveComment}
        >
          <ArrowUpIcon />
        </Button>
      </div>
    </div>
  );
};

const Reply = (props: { comment: Reply }) => {
  const { comment } = props;

  const { activeComment, userId, userName } = useEditorContext();
  const { deleteComment } = useEditorContext();

  const [isEditing, setIsEditing] = useState(false);

  const isCurrentUserAuthor = comment.authorId === userId;
  const isLighthouseAuthor = comment.showAsLighthouseAuthor;
  const authorName = isLighthouseAuthor
    ? "Lighthouse"
    : isCurrentUserAuthor
      ? userName
      : (comment.authorName ?? "Anonymous");

  return (
    <div className="flex flex-row gap-2">
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex flex-row items-center gap-2 w-full">
          {isLighthouseAuthor && <Mark />}

          {!isLighthouseAuthor && (
            <div
              className="flex items-center justify-center h-[22px] w-[22px] rounded-full font-bold text-white text-xs"
              style={{
                backgroundColor: uniqolor(authorName).color,
              }}
            >
              <span>{authorName[0].toUpperCase()}</span>
            </div>
          )}

          <span className="font-medium text-[13px] text-grey-200">
            {authorName}
          </span>
          <span className="font-medium text-xs text-grey-400 ml-auto">
            {moment(comment.createdAt).fromNow()}
          </span>

          <div className="flex flex-row items-center">
            <div
              className={cn(
                "flex flex-row items-center gap-3 opacity-0 transition-opacity group-hover/container:opacity-100",
                activeComment?.id === comment.id.toString() && "opacity-100"
              )}
            >
              {userId === comment.authorId &&
                !isEditing &&
                activeComment?.id === comment.id.toString() && (
                  <CommentMenu
                    handleDelete={() => deleteComment(comment.id)}
                    handleEdit={() => setIsEditing(true)}
                  />
                )}
            </div>
          </div>
        </div>

        {!isEditing && <CommentViewer comment={comment} />}

        {isEditing && (
          <CommentEditor comment={comment} setIsEditing={setIsEditing} />
        )}
      </div>
    </div>
  );
};

const NewReply = (props: { parentCommentId: number }) => {
  const { parentCommentId } = props;

  const { createReply } = useEditorContext();

  const [content, setContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [replyCount, setReplyCount] = useState(0);

  const saveReply = async () => {
    setIsSubmitting(true);
    await createReply(parentCommentId, content);
    setIsSubmitting(false);
    setContent("");

    // force re-render to clear reply editor
    setReplyCount((cnt) => cnt + 1);
  };

  return (
    <div className="flex flex-col gap-3">
      <RichTextEditor
        content={content}
        onUpdate={setContent}
        key={replyCount.toString()}
        placeholder="Add a reply"
      />

      <div className="flex flex-row items-center justify-end gap-2">
        <Button
          variant="primary"
          disabled={content.trim() === "" || isSubmitting}
          onClick={saveReply}
          loading={isSubmitting}
        >
          <ArrowUpIcon />
        </Button>
      </div>
    </div>
  );
};

const CommentComponent = (props: { comment: Comment }) => {
  const { comment } = props;

  const {
    userId,
    activeComment,
    onCommentActivated,
    deleteComment,
    resolveComment,
    userName,
  } = useEditorContext();

  const [isEditing, setIsEditing] = useState(false);

  const isLighthouseAuthor = comment.showAsLighthouseAuthor;

  const isActive = activeComment?.id === comment.id.toString();
  const isCurrentUserAuthor = comment.authorId === userId;
  const commentAuthorName = isLighthouseAuthor
    ? "Lighthouse"
    : isCurrentUserAuthor
      ? userName
      : comment.authorName;

  return (
    <div
      id={`comment-box-${comment.id}`}
      onClick={() =>
        onCommentActivated(
          {
            id: comment.id.toString(),
          },
          false
        )
      }
      className={cn(
        "max-w-[400px] min-w-[400px] flex flex-col gap-1 bg-grey-800 p-3 rounded-lg transition-all group/container shadow-border ring-inset",
        isActive && "bg-white shadow-md mb-2"
      )}
    >
      <div className="flex flex-row gap-2">
        <div className="flex flex-col gap-1 flex-1">
          <div className="flex flex-row items-center gap-2 w-full">
            {isLighthouseAuthor && <Mark />}

            {!isLighthouseAuthor && (
              <div
                className="flex items-center justify-center h-[22px] w-[22px] rounded-full font-bold text-white text-xs"
                style={{
                  backgroundColor: uniqolor(commentAuthorName).color,
                }}
              >
                <span>{commentAuthorName[0].toUpperCase()}</span>
              </div>
            )}

            <span className="font-medium text-[13px] text-grey-200">
              {commentAuthorName}
            </span>
            <span className="font-medium text-xs text-grey-400 ml-auto">
              {moment(comment.createdAt).fromNow()}
            </span>

            <div className="flex flex-row items-center">
              {comment.resolved && (
                <div className="flex items-center gap-1.5 text-[10px] text-blue font-medium">
                  <Tooltip>
                    <TooltipTrigger>
                      <CheckCircledIcon />
                    </TooltipTrigger>
                    <TooltipContent className="p-2">Resolved</TooltipContent>
                  </Tooltip>
                </div>
              )}
              <div
                className={cn(
                  "flex flex-row items-center gap-3 opacity-0 transition-opacity group-hover/container:opacity-100",
                  isActive && "opacity-100"
                )}
              >
                <AnimatePresence>
                  {!isEditing &&
                    !comment.resolved &&
                    isCurrentUserAuthor &&
                    isActive && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            onClick={() => resolveComment(comment.id, true)}
                            className="text-blue text-lg"
                            variant="tooltip"
                          >
                            <FiCheck />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="p-2">
                          Resolve comment
                        </TooltipContent>
                      </Tooltip>
                    )}
                </AnimatePresence>

                {isCurrentUserAuthor &&
                  !isEditing &&
                  isActive &&
                  !comment.resolved && (
                    <CommentMenu
                      handleDelete={() => deleteComment(comment.id)}
                      handleEdit={() => setIsEditing(true)}
                    />
                  )}
              </div>
            </div>
          </div>

          {!isEditing && <CommentViewer comment={comment} />}

          {isEditing && (
            <CommentEditor comment={comment} setIsEditing={setIsEditing} />
          )}
        </div>
      </div>

      {comment.replies.length > 0 && (
        <div className="flex flex-col gap-3 mt-2">
          {comment.replies.map((reply) => (
            <Reply key={reply.id} comment={reply} />
          ))}
        </div>
      )}

      {isCurrentUserAuthor &&
        !comment.resolved &&
        !isEditing &&
        activeComment?.id === comment.id.toString() && (
          <AnimatePresence>
            <motion.div
              onClick={(e) => e.stopPropagation()}
              className="mt-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <NewReply parentCommentId={comment.id} />
            </motion.div>
          </AnimatePresence>
        )}
    </div>
  );
};

export const TextEditorComments = (props: { className?: string }) => {
  const { comments } = useEditorContext();

  return (
    <div
      className={cn(
        "flex h-full flex-col gap-3 flex-shrink-0 w-full overflow-y-auto",
        props.className
      )}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {comments?.map((comment) => (
            <CommentComponent key={comment.id} comment={comment} />
          ))}
        </div>
      </div>
    </div>
  );
};
