export const getImmediateScrollableNode = (node: HTMLElement | null) => {
  let currentNode = node;

  while (currentNode) {
    if (["scroll", "auto"].includes(getComputedStyle(currentNode).overflowY)) {
      return currentNode;
    }

    currentNode = currentNode.parentElement;
  }

  // we should always have a #main but this allows us to fail gracefully
  return document.getElementById("main") || document.body;
};
