import { useAuth } from "@clerk/clerk-react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

declare global {
  interface Window {
    lighthouse: {
      overrideUserId: (userId: number) => void;
      clearOverrideUserId: () => void;
      token: () => void;
    };
  }
}

type AdminStateType = {
  overrideUserId: number | null;
  setOverrideUserId: (value: number | null) => void;
};

export const useAdminStore = create(
  persist<AdminStateType>(
    (set) => ({
      overrideUserId: null,
      setOverrideUserId: (value: number | null) =>
        set({ overrideUserId: value }),
    }),
    {
      name: "lighthouse-admin-store", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useAdminFunctions = () => {
  const { getToken } = useAuth();
  const overrideUserId = useAdminStore((state) => state.setOverrideUserId);

  // eslint-disable-next-line react-compiler/react-compiler
  window.lighthouse = {
    overrideUserId: (userId: number) => {
      overrideUserId(userId);
    },
    clearOverrideUserId: () => {
      overrideUserId(null);
    },
    token: async () => {
      const token = await getToken();
      if (token == null) return null;
      console.log(token);
    },
  };
};

export const useAdminOverrideUserId = () => {
  const { overrideUserId } = useAdminStore((state) => state);
  return overrideUserId;
};
