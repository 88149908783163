import { cn } from "@/lib/cn";
import {
  SidebarProgressCompleteIcon,
  SidebarProgressInProgress1Icon,
  SidebarProgressInProgress2Icon,
  SidebarProgressNotStartedIcon,
} from "./icons/sidebarProgress";
import { ComponentProps, useState } from "react";
import {
  AboutIcon,
  CompanyInfoIcon,
  DashboardIcon,
  EmployerDocumentsIcon,
  LockIcon,
  OfflineIcon,
  SupportLetterIcon,
  WorkIcon,
} from "./icons/sidebar";
import { OnboardingNodeGroups } from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";
import { LabelText } from "./typography";

import {
  CheckCircledIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  InfoCircledIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@radix-ui/react-icons";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import {
  WorkplaceSelectorCollapsed,
  WorkplaceSelectorExpanded,
} from "./workspaceSelector";
import { ResultOf } from "gql.tada";
import { getCompanyWorkspace, getOnboarding } from "@/lib/queries";
import { WarningIcon } from "./icons/warning";
import { useCompany } from "@/providers/companyProvider";
import { isMissingOrEmpty } from "@/lib/utils";
import { useOnboardingData } from "@/providers/onboardingDataProvider";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { FAQModal } from "./faqModal";
import { AnimatePresence, motion } from "framer-motion";
import { AddNextCriteriaModal } from "./addNextCriteriaModal";

import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

type SidebarStateType = {
  open: boolean;
  expandedOptions: Record<string, boolean>;
  toggle: () => void;
  toggleExpanded: (id: string, isExpanded: boolean) => void;
};

export const useSidebarStore = create(
  persist<SidebarStateType>(
    (set) => ({
      open: true,
      expandedOptions: {},
      toggle: () => set((state) => ({ open: !state.open })),
      toggleExpanded: (id, isExpanded) =>
        set((state) => ({
          expandedOptions: {
            ...state.expandedOptions,
            [id]: isExpanded,
          },
        })),
    }),
    {
      name: "lighthouse-sidebar-store",
    }
  )
);

type Option = {
  label: string;
  id: OnboardingNodeGroups;
  icon: React.ReactNode;
  status?: "completed" | "in-progress-1" | "not-started" | "in-progress-2";
};

const options: Option[] = [
  {
    label: "About you",
    id: "about_you",
    icon: <AboutIcon />,
  },
  {
    label: "Your case strategy",
    id: "work_experience",
    status: "in-progress-2",
    icon: <WorkIcon />,
  },
  {
    label: "Employer Documents",
    id: "employer_documents",
    icon: <EmployerDocumentsIcon />,
  },
  {
    label: "Support letters",
    id: "support_letters",
    icon: <SupportLetterIcon />,
  },
];

const StatusIcon = (props: {
  status:
    | "in-progress-1"
    | "in-progress-2"
    | "completed"
    | "not-started"
    | "approved"
    | "rejected"
    | "submitted_for_review";
}) => {
  const { status } = props;

  return (
    <>
      {status === "in-progress-1" && (
        <span className="mr-[1px]">
          <SidebarProgressInProgress1Icon />
        </span>
      )}
      {status === "in-progress-2" && (
        <span className="mr-[1px]">
          <SidebarProgressInProgress2Icon />
        </span>
      )}
      {status === "completed" && (
        <span className="mr-[1px]">
          <SidebarProgressCompleteIcon />
        </span>
      )}
      {status === "not-started" && (
        <span className="mr-[1px]">
          <SidebarProgressNotStartedIcon />
        </span>
      )}
      {status === "approved" && (
        <CheckCircledIcon className="text-positive w-3 h-3 mr-[0.22em]" />
      )}
      {status === "rejected" && (
        <span className="mr-[1px]">
          <WarningIcon />
        </span>
      )}
      {status === "submitted_for_review" && (
        <div className="w-3 h-3 flex items-center justify-center mr-[0.22em]">
          <LockIcon />
        </div>
      )}
    </>
  );
};

const PulseCircleIcon = () => (
  <div className="w-[8px] h-[8px] rounded-full bg-positive/30 items-center">
    <div className="w-[4px] h-[4px] rounded-full bg-positive relative left-[2px] top-[2px]" />
  </div>
);

const AutoSaveBadge = (props: { status?: "pending" | "success" | "idle" }) => {
  const { status } = props;

  return (
    <div className="py-1 pr-2 pl-2 gap-2 flex flex-row items-center bg-positive bg-opacity-[8%] text-positive rounded-full w-full justify-center">
      {status === "pending" ? (
        <>
          <Spinner className="animate-spin h-4 w-4" />
          <LabelText as="span" className="text-[12px]">
            Saving
          </LabelText>
        </>
      ) : (
        <>
          <PulseCircleIcon />
          <LabelText as="span" className="text-[12px] text-nowrap">
            Your progress is auto-saved
          </LabelText>
        </>
      )}
    </div>
  );
};

export const OfflineBadge = () => (
  <div className="py-1 pr-2 pl-2 gap-1 flex flex-row items-center bg-grey-600 text-grey-300 rounded-full w-fit">
    <OfflineIcon />

    <LabelText as="span" className="text-[12px] text-nowrap text-grey-200">
      You are offline
    </LabelText>

    <InfoCircledIcon />
  </div>
);

const SaveStatusIndicator = () => {
  return (
    <div className="w-full px-2">
      <AutoSaveBadge status={"idle"} />
    </div>
  );
};

const SubOption = (props: {
  icon: React.ReactNode;
  label: string;
  status?:
    | "completed"
    | "in-progress-1"
    | "not-started"
    | "in-progress-2"
    | "approved"
    | "rejected"
    | "submitted_for_review";
  selected?: boolean;
  onClick?: () => void;
}) => {
  const { label, status, selected, onClick } = props;

  return (
    <span
      className={cn(
        "flex flex-row items-center gap-2 p-1 rounded-md hover:bg-grey-700/80 w-full overflow-hidden cursor-default",
        selected && "bg-grey-700"
      )}
      onClick={onClick}
    >
      <span className="text-[13px] text-grey-200/90 whitespace-nowrap text-ellipsis overflow-hidden flex-1 min-w-0">
        {label}
      </span>

      <div className="flex flex-row gap-1 items-center flex-shrink-0">
        {status && <StatusIcon status={status} />}
      </div>
    </span>
  );
};

const SidebarButton = (props: {
  icon: React.ReactNode;
  label: string;
  collapsed?: boolean;
  selected?: boolean;
  onClick?: () => void;
  buttonClassName?: string;
  groupId?: string;
  status?: "completed" | "in-progress-1" | "not-started" | "in-progress-2";
}) => {
  const {
    icon,
    label,
    collapsed = false,
    selected,
    onClick,
    buttonClassName,
    status,
  } = props;

  return (
    <div className="flex flex-col gap-1 w-full overflow-hidden justify-center items-center cursor-default">
      <div
        className={cn(
          `flex flex-row items-center p-2 rounded-md hover:bg-grey-700 text-sm overflow-hidden font-[400]`,
          selected && "bg-grey-700",
          collapsed ? "w-fit" : "w-full",
          buttonClassName
        )}
        onClick={onClick}
      >
        <div className="flex flex-row items-center gap-1.5 flex-shrink-0 w-full">
          {icon}

          {!collapsed && (
            <span
              className={cn(
                collapsed
                  ? "hidden"
                  : "whitespace-nowrap text-ellipsis overflow-hidden"
              )}
            >
              {label}
            </span>
          )}

          {status && (
            <div className="flex flex-row gap-1 items-center flex-shrink-0 ml-auto">
              <StatusIcon status={status} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ExpandableSidebarOption = (props: {
  icon: React.ReactNode;
  label: string;
  internalStatus?: ResultOf<
    typeof getOnboarding
  >["onboarding"]["groups"][0]["nodes"][0]["internalStatus"];
  status?: "completed" | "in-progress-1" | "not-started" | "in-progress-2";
  collapsed?: boolean;
  selected?: boolean;
  rejectedFields?: boolean;
  nodes: ResultOf<typeof getOnboarding>["onboarding"]["groups"][0]["nodes"];
  onNodeClick?: (nodeId: number) => void;
  buttonClassName?: string;
  groupId: string;
  nodeProgress?: {
    [id: number]: {
      totalFields: number;
      completedFields: number;
      rejectedFields: {
        key: string;
        fieldName: string;
        rejectionReason: string | undefined;
      }[];
    };
  };
  onCreateClick?: () => void;
}) => {
  const {
    icon,
    label,
    collapsed = false,
    selected,
    internalStatus,
    nodes,
    onNodeClick,
    buttonClassName,
    groupId,
    nodeProgress,
    onCreateClick,
  } = props;

  const { expandedOptions, toggleExpanded } = useSidebarStore();
  const isExpanded = expandedOptions[groupId] ?? false;
  const hasNodes = nodes && nodes.length > 0;

  const handleExpandToggle = () => {
    toggleExpanded(groupId, !isExpanded);
  };

  // Define animation variants
  const contentVariants = {
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        height: { duration: 0.2, ease: "easeInOut" },
        opacity: { duration: 0.2, ease: "easeInOut" },
      },
    },
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        height: { duration: 0.2, ease: "easeInOut" },
        opacity: { duration: 0.2, ease: "easeInOut" },
      },
    },
  };

  return (
    <div className="flex flex-col gap-1 w-full overflow-hidden">
      <div
        className={cn(
          `flex flex-row items-center rounded-md overflow-hidden font-[400] relative`,
          collapsed ? "w-fit" : "w-full"
        )}
      >
        <button
          className={cn(
            `flex flex-row items-center p-2 rounded-md hover:bg-grey-700 text-xs overflow-hidden font-[400] flex-grow gap-1.5 cursor-default`,
            selected && "bg-grey-700",
            buttonClassName
          )}
          onClick={handleExpandToggle}
        >
          <div
            className={cn(
              "flex flex-row items-center gap-1.5 flex-shrink-0",
              internalStatus === "submitted_for_review" && "opacity-50"
            )}
          >
            {icon}

            <span
              className={cn(
                collapsed
                  ? "hidden"
                  : "whitespace-nowrap text-ellipsis overflow-hidden"
              )}
            >
              {label}
            </span>
          </div>
          {!collapsed && hasNodes && (
            <>
              <motion.div
                initial={false}
                animate={{ rotate: isExpanded ? 90 : 0 }}
                transition={{ duration: 0.1 }}
              >
                <ChevronRightIcon className="text-grey-400 w-3 h-3" />
              </motion.div>
            </>
          )}
        </button>
        {onCreateClick && (
          <Tooltip>
            <TooltipTrigger asChild>
              <button
                className="flex items-center justify-center p-1.5 text-blue/20 hover:text-blue/90 transition-all duration-200"
                onClick={onCreateClick}
              >
                <PlusIcon
                  className="w-[14px] h-[14px]"
                  strokeWidth={1}
                  stroke="currentColor"
                />
              </button>
            </TooltipTrigger>
            <TooltipContent className="px-2 py-1 text-xs rounded-md">
              Add a new case strategy criteria
            </TooltipContent>
          </Tooltip>
        )}
      </div>

      <div className="overflow-hidden">
        <AnimatePresence initial={false}>
          {hasNodes && isExpanded && !collapsed && (
            <motion.div
              className="flex flex-col w-full pl-1 gap-[0.05em]"
              variants={contentVariants}
              initial="closed"
              animate="open"
              exit="closed"
              layoutId={`sidebar-group-${groupId}`}
            >
              <div className="flex flex-col w-full pb-2">
                {nodes?.map((node) => {
                  const isSelected =
                    window.location.pathname.endsWith(`/group/${groupId}`) &&
                    window.location.search === `?nodeId=${node.id}`;

                  const rejectedFields =
                    nodeProgress?.[node.id]?.rejectedFields ?? [];

                  const nodeStatus =
                    node.internalStatus === "approved"
                      ? "approved"
                      : node.internalStatus === "rejected"
                        ? "rejected"
                        : node.internalStatus === "submitted_for_review"
                          ? "submitted_for_review"
                          : nodeProgress?.[node.id]?.completedFields === 0
                            ? "not-started"
                            : nodeProgress?.[node.id]?.completedFields ===
                                nodeProgress?.[node.id]?.totalFields
                              ? "completed"
                              : (nodeProgress?.[node.id]?.completedFields ??
                                  0 >
                                    (nodeProgress?.[node.id]?.totalFields ??
                                      0) *
                                      0.75)
                                ? "in-progress-2"
                                : "in-progress-1";

                  const enforceRejectedStatus =
                    rejectedFields?.length > 0 ? "rejected" : nodeStatus;

                  const formattedNodeType = node.type
                    .split("-")
                    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                    .join(" ");

                  const shouldShowPrefix = groupId === "work_experience";

                  const label =
                    node.name.trim().length === 0
                      ? formattedNodeType
                      : shouldShowPrefix
                        ? `${formattedNodeType} - ${node.name}`
                        : node.name;

                  return (
                    <SubOption
                      key={`${groupId}-${node.id}`}
                      icon={<AboutIcon />}
                      label={label}
                      status={enforceRejectedStatus}
                      selected={isSelected}
                      onClick={() => onNodeClick?.(node.id)}
                    />
                  );
                })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

// Keep this for backward compatibility
const SidebarOption = (props: {
  icon: React.ReactNode;
  label: string;
  internalStatus?: ResultOf<
    typeof getOnboarding
  >["onboarding"]["groups"][0]["nodes"][0]["internalStatus"];
  status?: "completed" | "in-progress-1" | "not-started" | "in-progress-2";
  collapsed?: boolean;
  selected?: boolean;
  rejectedFields?: boolean;
  onClick?: () => void;
  nodes?: ResultOf<typeof getOnboarding>["onboarding"]["groups"][0]["nodes"];
  onNodeClick?: (nodeId: number) => void;
  buttonClassName?: string;
  groupId: string;
}) => {
  const { nodes, ...rest } = props;

  if (nodes && nodes.length > 0) {
    return <ExpandableSidebarOption {...rest} nodes={nodes} />;
  }

  return <SidebarButton {...rest} />;
};

const SidebarToggle = () => {
  const open = useSidebarStore((state) => state.open);
  const toggle = useSidebarStore((state) => state.toggle);

  return (
    <button
      className={cn(
        "transition-all duration-100 animate-slide-in ease-in-out z-[11]",
        "h-[28px] w-[28px] absolute top-[30px] translate-x-[17.5em] rounded-r-[56px] bg-grey-800 flex items-center justify-center shadow-border",
        !open && "translate-x-[3.9em]"
      )}
      onClick={toggle}
    >
      {open ? (
        <DoubleArrowLeftIcon className="text-grey-300 w-3 h-3 font-bold" />
      ) : (
        <DoubleArrowRightIcon className="text-grey-300 w-3 h-3 font-bold" />
      )}
    </button>
  );
};

export const Sidebar = () => {
  const open = useSidebarStore((state) => state.open);
  const {
    progress,
    allOnboardingGroups,
    visaClass,
    hasCompanyDetailsAccess,
    onboardingId,
  } = useOnboardingData();
  const nav = useNavigate();

  const [faqModalOpen, setFaqModalOpen] = useState(false);
  const [addNextCriteriaModalOpen, setAddNextCriteriaModalOpen] =
    useState(false);

  const availableOptions = options.filter((option) => {
    return allOnboardingGroups
      .filter((x) => x.nodes.length > 0)
      .map((x) => x.id)
      .includes(option.id);
  });

  const getStatus = (group: OnboardingNodeGroups) => {
    const groupProgress = progress[group];
    if (groupProgress == null)
      return {
        status: "not-started",
        rejectedFields: false,
      };

    if (
      groupProgress.submittedNodes === 0 &&
      groupProgress.approvedNodes === 0 &&
      groupProgress.rejectedNodes === 0
    ) {
      return {
        status: "not-started",
        rejectedFields: false,
      };
    }

    if (
      groupProgress.completedNodes === groupProgress.totalNodes ||
      groupProgress.approvedNodes === groupProgress.totalNodes
    )
      return {
        status: "completed",
        rejectedFields: groupProgress.rejectedNodes > 0,
      };

    return {
      status:
        groupProgress.submittedNodes > 0.5 * groupProgress.totalNodes ||
        groupProgress.approvedNodes > 0.5 * groupProgress.totalNodes
          ? "in-progress-2"
          : "in-progress-1",

      rejectedFields: groupProgress.rejectedNodes > 0,
    };
  };

  return (
    <div
      className={cn(
        "flex flex-col gap-2 h-full w-[22em] max-w-[22em] overflow-hidden",
        !open && "w-16 max-w-16",
        open && "px-2"
      )}
    >
      <FAQModal
        open={faqModalOpen}
        setOpen={setFaqModalOpen}
        visaClass={visaClass}
      />

      <AddNextCriteriaModal
        open={addNextCriteriaModalOpen}
        setOpen={setAddNextCriteriaModalOpen}
        onboardingId={onboardingId}
      />
      <div
        className={cn(
          "h-full flex flex-col pt-4 overflow-hidden",
          !open && "hidden"
        )}
      >
        <SidebarToggle />

        <div className="flex flex-col gap-1 px-2 text-[14px] font-normal overflow-hidden">
          <div className="gap-1 flex flex-col w-full overflow-hidden">
            <div className="w-full">
              <WorkplaceSelectorExpanded />

              <div className="border-b-[0.8px] border-b-grey-600 m-1" />
            </div>

            <SidebarButton
              icon={<DashboardIcon />}
              label="Home"
              selected={window.location.pathname.endsWith("/home")}
              onClick={() => nav({ to: "/home" })}
              groupId="home"
            />

            {hasCompanyDetailsAccess && (
              <SidebarButton
                icon={<CompanyInfoIcon />}
                label="Company Details"
                selected={window.location.pathname.endsWith(
                  "/founder-company-info"
                )}
                onClick={() => nav({ to: "/founder-company-info" })}
                groupId="company-info"
              />
            )}
          </div>

          <div className="h-[1px] bg-grey-600 w-full m-1" />

          <div className="flex flex-col gap-2 overflow-y-scroll">
            {availableOptions.map((option) => {
              const { status, rejectedFields } = getStatus(option.id);

              const groupProgress = progress[option.id];

              const allApproved =
                groupProgress?.approvedNodes === groupProgress?.totalNodes;
              const anyRejected = (groupProgress?.rejectedNodes ?? 0) > 0;
              const allSubmitted =
                groupProgress?.submittedNodes === groupProgress?.totalNodes;

              const internalStatus = anyRejected
                ? "rejected"
                : allSubmitted
                  ? "submitted_for_review"
                  : allApproved
                    ? "approved"
                    : "pending";

              const nodes = allOnboardingGroups.find(
                (x) => x.id === option.id
              )?.nodes;

              if (nodes && nodes.length > 0) {
                return (
                  <ExpandableSidebarOption
                    key={option.id}
                    icon={option.icon}
                    label={option.label}
                    status={
                      status as ComponentProps<
                        typeof ExpandableSidebarOption
                      >["status"]
                    }
                    internalStatus={internalStatus}
                    rejectedFields={rejectedFields}
                    nodes={nodes}
                    onNodeClick={(nodeId) =>
                      nav({
                        to: `/group/$groupId`,
                        params: { groupId: option.id },
                        search: { nodeId },
                      })
                    }
                    groupId={option.id}
                    buttonClassName="p-1 rounded-sm text-grey-300"
                    nodeProgress={groupProgress?.nodeProgress}
                    onCreateClick={
                      option.id === "work_experience"
                        ? () => setAddNextCriteriaModalOpen(true)
                        : undefined
                    }
                  />
                );
              }

              return (
                <SidebarButton
                  key={option.id}
                  icon={option.icon}
                  label={option.label}
                  selected={window.location.pathname.endsWith(
                    `/group/${option.id}`
                  )}
                  onClick={() => nav({ to: `/group/${option.id}` })}
                  buttonClassName="p-1 rounded-sm text-grey-300"
                  groupId={option.id}
                />
              );
            })}
          </div>
        </div>

        <div className="mt-auto flex-col pb-6 gap-4 w-full flex justify-center">
          <div className="flex flex-col justify-center items-center">
            <SaveStatusIndicator />
          </div>
          <div className="w-full flex flex-row justify-between items-center pl-3">
            <div
              onClick={() => setFaqModalOpen(true)}
              className="cursor-pointer"
            >
              <LabelText as="span" className="text-[12px] text-grey-400">
                Need some help?
              </LabelText>
            </div>

            {/* <ThemePicker /> */}
          </div>
        </div>
      </div>

      <div
        className={cn(
          "h-full w-[60px] pt-4 flex-col hidden",
          !open && "inline"
        )}
      >
        <SidebarToggle />

        <div className="flex flex-col w-full items-center gap-1 px-2 text-[14px] font-normal">
          <div className="flex flex-col gap-1">
            <div className="w-full">
              <WorkplaceSelectorCollapsed />

              <div className="border-b-[0.8px] border-b-grey-600 m-1" />
            </div>

            <SidebarButton
              icon={<DashboardIcon />}
              label="Home"
              collapsed
              selected={window.location.pathname.endsWith("/home")}
              onClick={() => nav({ to: "/home" })}
              groupId="home"
            />
            {hasCompanyDetailsAccess && (
              <SidebarButton
                icon={<CompanyInfoIcon />}
                label="Company Details"
                collapsed
                selected={window.location.pathname.endsWith(
                  "/founder-company-info"
                )}
                onClick={() => nav({ to: "/founder-company-info" })}
                groupId="company-info"
              />
            )}

            <div className="border-b-[0.8px] border-b-grey-600  mx-1" />
          </div>

          {availableOptions.map((option) => {
            return (
              <SidebarButton
                key={option.id}
                icon={option.icon}
                label={option.label}
                collapsed
                selected={window.location.pathname.endsWith(
                  `/group/${option.id}`
                )}
                onClick={() =>
                  nav({ to: `/group/$groupId`, params: { groupId: option.id } })
                }
                groupId={option.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const companySidebarOptions = [
  {
    label: "Home",
    path: "/home",
    icon: <AboutIcon />,
  },
  {
    label: "Company Details",
    path: "/company-info",
    icon: <CompanyInfoIcon />,
  },
  {
    label: "Applicants",
    path: "/applicants",
    icon: <AboutIcon />,
  },
];

export const getCompletedFields = (
  data: ResultOf<typeof getCompanyWorkspace>
): {
  files: { total: number; completed: number };
  entityData: { total: number; completed: number };
} => {
  const entityData = data?.getCompanyWorkspace.entityData;
  const files = data?.getCompanyWorkspace.files;

  const keysToCheck = [
    "legalName",
    "ein",
    "currentNumberOfUSFullTimeEmployees",
    "grossAnnualIncome",
    "netAnnualIncome",
    "mailingAddress",
  ];

  const filesToCheck = [
    ["articles-of-incorporation", "company-bylaws"],
    ["tax-id-fein-document"],
    ["bank-statement"],
    ["safe-documentation"],
    ["company-overview"],
  ];

  let fieldsCompleted = 0;
  let filesCompleted = 0;

  for (const key of keysToCheck) {
    if (isMissingOrEmpty(entityData[key])) continue;
    if (isMissingOrEmpty(entityData[key])) continue;
    fieldsCompleted += 1;
  }

  for (const orFiles of filesToCheck) {
    const fileFiltered = files.filter((file) =>
      orFiles.includes(file.subKey ?? "")
    );

    if (fileFiltered.length > 0) {
      filesCompleted += 1;
    }
  }

  return {
    files: {
      total: filesToCheck.length,
      completed: filesCompleted,
    },
    entityData: {
      total: keysToCheck.length,
      completed: fieldsCompleted,
    },
  };
};

const checkCompanyInfoStatus = (
  data: ResultOf<typeof getCompanyWorkspace>
): "completed" | "in-progress-2" | "not-started" => {
  const entityData = data?.getCompanyWorkspace.entityData;
  const files = data?.getCompanyWorkspace.files;

  const keysToCheck = [
    "legalName",
    "ein",
    "currentNumberOfUSFullTimeEmployees",
    "grossAnnualIncome",
    "netAnnualIncome",
    "mailingAddress",
  ];

  const filesToCheck = [
    ["articles-of-incorporation", "company-bylaws"],
    ["tax-id-fein-document"],
    ["bank-statement"],
    ["safe-documentation"],
    ["company-overview"],
  ];

  let totalCompleted = 0;

  for (const key of keysToCheck) {
    if (!isMissingOrEmpty(entityData[key])) {
      totalCompleted += 1;
    }
  }

  for (const orFiles of filesToCheck) {
    const fileFiltered = files.filter((file) =>
      orFiles.includes(file.subKey ?? "")
    );
    if (fileFiltered.length > 0) {
      totalCompleted += 1;
    }
  }

  if (totalCompleted >= keysToCheck.length + filesToCheck.length)
    return "completed";

  return "in-progress-2";
};

export const CompanySidebar = () => {
  const open = useSidebarStore((state) => state.open);
  const { data } = useCompany();

  const router = useRouterState();
  const nav = useNavigate();

  return (
    <div
      className={cn(
        "flex flex-col gap-2 h-full w-[22em] max-w-[22em] overflow-hidden",
        !open && "w-16 max-w-16",
        open && "px-2"
      )}
    >
      <div
        className={cn(
          "h-full flex flex-col overflow-hidden pt-4",
          !open && "hidden"
        )}
      >
        <SidebarToggle />

        <div className="flex flex-col gap-1 px-2 text-[14px] font-normal overflow-hidden">
          <div className="flex flex-col gap-1 w-full overflow-hidden">
            <WorkplaceSelectorExpanded />

            <div className="border-b-[0.8px] border-b-grey-600 m-1" />
          </div>
          {companySidebarOptions.map((option) => {
            return (
              <SidebarOption
                key={option.path}
                {...option}
                selected={router.location.pathname == option.path}
                onClick={() => nav({ to: option.path })}
                status={
                  option.path === "/company-info" && data != null
                    ? checkCompanyInfoStatus(data)
                    : undefined
                }
                groupId={option.path}
              />
            );
          })}
        </div>

        <div className="mt-auto flex flex-col pb-6 gap-4 w-full justify-center">
          <div className="flex flex-col justify-center items-center">
            <SaveStatusIndicator />
          </div>
          <div className="w-full flex flex-row justify-between items-center pl-3">
            <div className="cursor-pointer">
              <a href="mailto:support@lighthousehq.com">
                <LabelText as="span" className="text-[12px] text-grey-400">
                  Need some help?
                </LabelText>
              </a>
            </div>

            {/* <ThemePicker /> */}
          </div>
        </div>
      </div>
      <div
        className={cn(
          "h-full w-[60px] flex-col hidden overflow-hidden pt-4",
          !open && "inline"
        )}
      >
        <SidebarToggle />

        <div className="flex flex-col w-full items-center gap-1 px-2 text-[14px] font-normal overflow-hidden">
          <div className="flex flex-col gap-1 w-full justify-center overflow-hidden">
            <WorkplaceSelectorCollapsed />
            <div className="border-b-[0.8px] border-b-grey-600 m-1" />
          </div>

          <div className="flex flex-col w-full justify-center items-center gap-1">
            {companySidebarOptions.map((option) => {
              return (
                <SidebarOption
                  key={option.path}
                  {...option}
                  selected={router.location.pathname == option.path}
                  onClick={() => nav({ to: option.path })}
                  collapsed
                  groupId={option.path}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
