import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircledIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import { Modal } from "./modal";
import { useState } from "react";
import { ChevronDownIcon } from "@radix-ui/themes";
import { cn } from "@/lib/cn";
import { Input, TextArea } from "./inputs";
import { Button } from "./button";
import { useMutation } from "urql";
import { createSupportRequest } from "@/lib/queries";

const H1BQuestions = [
  {
    title: "What is an H-1B visa?",
    content:
      "The H-1B visa is designed for specialty occupations requiring theoretical and practical application of highly specialized knowledge.",
  },
  {
    title: "What qualifies as a 'specialty occupation?'",
    content: (
      <ol className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
        <li>A bachelor's or higher degree is normally the minimum entry requirement for the position</li>
        <li>The degree requirement is common in the industry for similar positions</li>
        <li>The job is so complex or unique that it can only be performed by someone with a degree</li>
        <li>The employer normally requires a degree for this position</li>
        <li>The duties are so specialized that the knowledge required is usually associated with a bachelor's or higher degree</li>
      </ol>
    ),
  },
  {
    title: "Who can sponsor an H-1B visa?",
    content:
      "Only employers can sponsor H-1B visas. Self-petitioning is not allowed. The employer must be able to 'hire, pay, fire, supervise, or otherwise control' the employee.",
  },
  {
    title: "What documentation is needed to prove a position is a specialty occupation?",
    content: (
      <div>
        Employers, please be prepared to provide a job description that details:
        <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1 mt-2">
          <li>Bachelor's degree or equivalent for work experience</li>
          <li>Any complex and specialized duties for the position</li>
          <li>How the position relates to the company's business needs</li>
        </ul>
      </div>
    ),
  },
  {
    title: "What forms will be filed to secure an H-1B visa?",
    content: (
      <div>
        Lighthouse will manage the drafting and submission of the following forms:
        <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1 mt-2">
          <li>Labor Condition Application (LCA) - must be wet-ink signed post-certification by the DOL</li>
          <li>Form I-129 (Petition for a Nonimmigrant Worker) - signed electronically for online submissions</li>
        </ul>
      </div>
    ),
  },
  {
    title: "How must the LCA notice be posted?",
    content:
      "The notice must be posted for 10 consecutive business days in at least two (2) conspicuous locations at each place of employment (i.e. break room, office lobby, etc.). Alternatively, electronic posting is permitted through means by which the employer normally communicates with employees (e.g., team-wide slack channel, intranet)",
  },
  {
    title: "What are the employer compliance requirements for the Labor Condition Application?",
    content: (
      <ol className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
        <li>Pay the required wage (higher of prevailing or actual wage)</li>
        <li>Provide working conditions that won't adversely affect similarly employed U.S. workers</li>
        <li>Verify there is no strike or lockout in the occupational classification</li>
        <li>Notify employees or their bargaining representative about the LCA filing</li>
        <li>Have informed U.S. workers of the intent to hire a foreign worker by posting an LCA notice within 30 days preceding the LCA submission</li>
      </ol>
    ),
  },
  {
    title: "What wage must be paid to H-1B workers?",
    content: (
      <div className="flex flex-col gap-2">
        <p>Employers must pay H-1B workers at least the required wage, defined as the higher of:</p>
        <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
          <li>The local "prevailing wage" for the occupation in the geographic area</li>
          <li>The employer's "actual wage" paid to workers with similar experience and qualifications</li>
        </ul>
      </div>
    ),
  },
  {
    title: "How does an employer determine the prevailing wage for the occupation?",
    content: (
      <div className="flex flex-col gap-2">
        <p>The prevailing wage can be determined through:</p>
        <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
          <li>A Prevailing Wage Determination (PWD) from the Department of Labor using Form ETA-9141</li>
          <li>Self-determination using acceptable wage surveys that meet DOL criteria</li>
          <li>The Occupational Employment Statistics (OES) wage library</li>
        </ul>
      </div>
    ),
  },
  {
    title: "What constitutes a material change requiring notification?",
    content: (
      <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
        <li>Significant changes in job duties or percent of time spent in intended job duties</li>
        <li>Worksite changes to outside of the original MSA geographic area</li>
        <li>Material changes to title, salary, or department</li>
      </ul>
    ),
  },
  {
    title: "What documents must employers maintain to demonstrate compliance?",
    content: (
      <ol className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
        <li>Maintain accurate Public Access Files documenting compliance with all LCA requirements</li>
        <li>Keep records of LCA postings at all worksites, including dates and locations of postings</li>
        <li>Document any analysis determining whether a new worksite falls within the same MSA or area of intended employment</li>
      </ol>
    ),
  },
  {
    title: "Does an employee's remote work location need to be included in the LCA?",
    content: (
      <div className="flex flex-col gap-2">
        <p>Yes. All actual worksite locations must be listed on the LCA, and LCA notices must be posted at all actual worksite locations. Important notes:</p>
        <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
          <li>If an H-1B employee moves to a different home outside the original MSA, an H-1B amendment is required</li>
          <li>For remote workers, employers must still comply with all LCA posting requirements</li>
          <li>For hybrid work arrangements, the LCA notice should be posted at all worksite locations</li>
        </ul>
      </div>
    ),
  },
  {
    title: "When is an H-1B Amendment required for worksite changes?",
    content:
      "An H-1B employer must file an amended H-1B petition if the employee changes their place of employment to a worksite location more than 60 miles from the metropolitan statistical area (MSA) or 'area of intended employment' covered by the existing approved H-1B petition.",
  },
  {
    title: "When is an H-1B Amendment NOT Required?",
    content: (
      <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
        <li>Same MSA or Area of Intended Employment - If the new worksite is within the same MSA or within 60 miles</li>
        <li>Short-term Placements - Under certain circumstances, for up to 30 days, and in some cases up to 60 days</li>
        <li>Non-worksite Locations - If the H-1B worker is going to a non-worksite location</li>
        <li>Temporary Work - If the work required at the second worksite is temporary</li>
      </ul>
    ),
  },
  {
    title: "What constitutes a material change requiring notification?",
    content: (
      <ul className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
        <li>Significant changes in job duties or percent of time spent in intended job duties</li>
        <li>Worksite changes to outside of the original MSA geographic area</li>
        <li>Material changes to title, salary, or department</li>
      </ul>
    ),
  },
  {
    title: "When is an H-1B Amendment required for worksite changes?",
    content:
      "An H-1B employer must file an amended H-1B petition if the employee changes their place of employment to a worksite location more than 60 miles from the metropolitan statistical area (MSA) or 'area of intended employment' covered by the existing approved H-1B petition.",
  },
];

const O1Questions = [
  {
    title: "How long will the O-1A process take?",
    content: (
      <ol className="list-disc list-outside ml-5 pr-10 flex flex-col gap-1">
        <li>Your onboarding will take 30-45 minutes to complete.</li>
        <li>
          Once you submit your onboarding, the Lighthouse team will review your
          submission and get started on drafting your forms, letters, and
          supporting documentation.
        </li>
        <li>
          The end-to-end preparation process will be about 30 days or fewer,
          with your collaboration, and with premium processing there is a 15
          business day turnaround time from USCIS.
        </li>
        <li>
          Overall, the O-1A process will be about 5-8 weeks including government
          time.
        </li>
      </ol>
    ),
  },
  {
    title: "How long is an O-1A visa good for?",
    content:
      "The visa has an initial validity period of 3 years and can be renewed annually in perpetuity.",
  },
  {
    title: "I have a change in my employer? What should I do?",
    content:
      "If you are changing your employer on your O-1A, reach out to success@lighthousehq.com to discuss how to pursue a refiling",
  },
  {
    title: "Can an O-1A visa holder apply for a Green Card?",
    content:
      "Yes! The O-1A can be a great stepping stone to a permanent residency pathway. At Lighthouse, we support the EB-1A and EB-2 National Interest Waiver green card pathways. To discuss your eligibility and timeline, reach out to success@lighthousehq.com.",
  },
  {
    title: "May a spouse of an O-1A visa holder work?",
    content:
      "No, the O-1A has a dependent visa available called the O-3. However, O-3 holders do not qualify for work authorization with this dependent visa.",
  },
];

const FAQDropdown = (props: { title: string; content: React.ReactNode }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div
        className="flex flex-row items-center px-2 py-1 cursor-pointer"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span className="text-grey-200 font-medium text-sm">{props.title}</span>
        {open ? (
          <ChevronDownIcon className="ml-auto" />
        ) : (
          <ChevronRightIcon className="ml-auto" />
        )}
      </div>
      {
        <span
          className={cn(
            "text-grey-300 text-sm pl-2 pr-4 py-1 break-words",
            !open && "hidden"
          )}
        >
          {props.content}
        </span>
      }
    </div>
  );
};

const FAQ = (props: {
  visaClass?: string;
  setPage: (page: "faq" | "message") => void;
}) => {
  return (
    <div className="w-full flex flex-col gap-4 overflow-hidden">
      <div
        className="bg-grey-200 rounded-[8px] flex flex-col gap-2 py-3 px-4 w-full cursor-pointer"
        onClick={() => props.setPage("message")}
      >
        <div className="w-full flex flex-row items-center">
          <p className="text-grey-800 font-[525] text-[14px]">
            Send us a message
          </p>
          <div className="ml-auto text-grey-800">
            <ArrowRightIcon />
          </div>
        </div>
        <p className="text-grey-500 text-[14px]">
          We typically reply in under 3 days.
        </p>
      </div>

      {props.visaClass === "o-1a" && (
        <div className="flex flex-col overflow-scroll pb-4 gap-2">
          {O1Questions.map((question) => (
            <FAQDropdown
              key={question.title}
              title={question.title}
              content={question.content}
            />
          ))}
        </div>
      )}
      {props.visaClass === "h-1b" && (
        <div className="flex flex-col overflow-scroll pb-4 gap-2">
          {H1BQuestions.map((question) => (
            <FAQDropdown
              key={question.title}
              title={question.title}
              content={question.content}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Message = (props: { setPage: (page: "faq" | "message") => void }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const createSupportRequestMutation = useMutation(createSupportRequest)[1];

  const doSendMessage = async () => {
    if (subject.trim().length === 0 || message.trim().length === 0) return;

    setStatus("loading");
    const res = await createSupportRequestMutation({
      input: {
        subject,
        message,
      },
    });

    if (res.error) {
      setStatus("error");
      return;
    }

    setMessage("");
    setSubject("");
    setStatus("success");
  };

  return (
    <div className="flex flex-col gap-4 w-full px-0.5 pb-4">
      <div className="flex flex-col gap-2 w-full">
        <span className="text-grey-300 text-xs font-medium">Subject</span>
        <Input
          placeholder="Subject"
          className="w-full"
          value={subject}
          onValueChange={(e) => setSubject(e)}
        />
      </div>

      <div className="flex flex-col gap-2 w-full">
        <span className="text-grey-300 text-xs font-medium">Your message</span>
        <TextArea
          placeholder="Enter your message here"
          className="w-full"
          value={message}
          onChange={(e) => setMessage(e)}
        />
      </div>
      {status === "success" && (
        <div className="flex flex-row items-center w-full bg-positive/10 border border-positive px-3 py-2 rounded-md gap-2">
          <CheckCircledIcon className="text-positive w-4 h-4" />
          <span className="text-positive text-sm">
            Your support request has been sent. We will get back to you soon.
          </span>
        </div>
      )}

      <div className="flex flex-row items-center w-full gap-2 mt-4">
        <Button
          variant="secondary"
          onClick={() => {
            props.setPage("faq");
            setSubject("");
            setMessage("");
            setStatus("idle");
          }}
          className="shadow-border"
        >
          <ArrowLeftIcon /> Back to FAQ
        </Button>
        <Button
          variant="primary"
          onClick={doSendMessage}
          className="ml-auto"
          disabled={subject.trim().length === 0 || message.trim().length === 0}
          loading={status === "loading"}
        >
          Send Message
        </Button>
      </div>
    </div>
  );
};

export const FAQModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  visaClass?: string;
}) => {
  const { open, setOpen, visaClass } = props;

  const [page, setPage] = useState<"faq" | "message">("faq");

  return (
    <Modal
      open={open}
      onOpenChange={() => {
        setOpen(false);
        setPage("faq");
      }}
      title="Help & Support"
      contentClassName={page === "faq" ? "h-[60vh]" : ""}
      childrenClassName="pr-6 pb-0"
      fitContent={page === "message"}
    >
      <div className="w-full h-full flex flex-col overflow-hidden">
        {page === "faq" && <FAQ visaClass={visaClass} setPage={setPage} />}
        {page === "message" && <Message setPage={setPage} />}
      </div>
    </Modal>
  );
};
