import { FAQModal } from "@/components/faqModal";
import { Progress } from "@/components/progress";
import { LabelText } from "@/components/typography";
import { Heading } from "@radix-ui/themes";
import { useState } from "react";

const Header = () => (
  <div className="py-6 px-8">
    <Heading as="h2" size="4">
      Lighthouse
    </Heading>
  </div>
);

const Footer = (props: { progress?: number }) => {
  const [faqModalOpen, setFaqModalOpen] = useState(false);

  const { progress } = props;

  return (
    <div className="flex flex-row w-full py-6 px-8 items-center">
      <FAQModal open={faqModalOpen} setOpen={setFaqModalOpen} />
      <div
        className="w-1/3 text-nowrap hover:cursor-pointer"
        onClick={() => setFaqModalOpen(true)}
      >
        <LabelText as={"span"} className="text-grey-400 font-normal">
          Need some help?
        </LabelText>
      </div>

      {progress != null && (
        <div className="flex w-full h-full items-center">
          <div className="w-[118px] h-[3px] ml-auto mr-auto">
            <Progress percent={progress} />
          </div>
        </div>
      )}

      <div className="flex ml-auto w-1/3 justify-end">
        {/* <ThemePicker /> */}
      </div>
    </div>
  );
};

export const OnboardingFrame = (props: {
  children: React.ReactNode;
  progress?: number;
}) => {
  return (
    <div className="w-full h-full flex flex-col grow-0">
      <Header />
      <div className="w-full h-full flex items-center justify-center grow-0 overflow-scroll">
        {props.children}
      </div>

      <Footer progress={props.progress} />
    </div>
  );
};
