import { useOnboarding } from "@/providers/onboardingProvider";
import { FormValues, parseFormValues } from "../formValues";
import {
  getOnboardingNode,
  updateOnboardingNode,
  subscribeToOnboardingNodeChange,
} from "../queries";
import { useQuery, useMutation, useSubscription } from "urql";

export const useOnboardingNodeData = (id: number) => {
  const [{ data, error, fetching }, refetch] = useQuery({
    query: getOnboardingNode,
    variables: { id: id },
    pause: id == null || id === -1,
  });

  const [, mutation] = useMutation(updateOnboardingNode);

  const contentData = parseFormValues(data?.onboardingNode.content);

  const doSetData = async (
    key: string,
    value?:
      | string
      | boolean
      | number
      | Record<string, string>
      | string[]
      | object,
    clear?: boolean
  ) => {
    if (data == null || fetching) return;

    const wasFieldPreviouslyRejected = contentData[key]?.status === "rejected";
    const wasFieldNotSubmitted =
      contentData[key]?.status === "not-submitted" ||
      contentData[key]?.status == undefined;

    const rejectedValue = wasFieldPreviouslyRejected
      ? contentData[key]?.value
      : contentData[key]?.rejectedValue;

    const newKeyValue: FormValues[string] = {
      value,
      status: wasFieldPreviouslyRejected
        ? "submitted"
        : wasFieldNotSubmitted
          ? "submitted"
          : contentData[key]?.status,
      rejectedValue,
    };

    const newContent: FormValues = clear
      ? {
          [key]: newKeyValue,
        }
      : {
          ...contentData,
          [key]: newKeyValue,
        };

    mutation({
      input: {
        onboardingNodeId: id,
        data: newContent,
      },
    });
  };

  useSubscription(
    {
      query: subscribeToOnboardingNodeChange,
      variables: { id: id },
      pause: id == null,
    },
    () => {
      refetch({ requestPolicy: "cache-and-network" });
    }
  );

  return {
    data: contentData,
    fetching,
    error,
    doSetData,
    customerCreated: data?.onboardingNode.customerCreated ?? false,
  };
};

export const useDataField = (
  key: string
): FormValues[string] & { disabled: boolean } => {
  const { currentOnboardingNodeId, nodeStatus, rejectedFields } = useOnboarding();
  const { data } = useOnboardingNodeData(currentOnboardingNodeId ?? -1);

  return {
    value: data[key]?.value,
    status: data[key]?.status,
    rejectionReason: data[key]?.rejectionReason,
    disabled: nodeStatus === "submitted_for_review" && rejectedFields.length === 0,
  };
};
