import {
  approveLetterAction,
  getCompanyMembers,
  getFollowUpAction,
  getLetterActionById,
  requestLetterActionChanges,
} from "@/lib/queries";
import {
  EditorContextProvider,
  useEditorContext,
} from "@/providers/editorProvider";

import { useClient, useMutation, useQuery } from "urql";
import { TextEditorComments } from "@/components/textEditorComments";
import { TextEditor } from "@/components/textEditor";
import { useState } from "react";
import { useTutorialModalState } from "@/lib/hooks/tutorialModalState";
import { useUserContext } from "@/lib/hooks/user";
import { ResultOf } from "gql.tada";
import { ArrowRightIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { Modal } from "@/components/modal";
import { Button } from "@/components/button";
import { FaRegCommentDots } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { cn } from "@/lib/cn";
import { LoadingOverlay } from "@/components/loadingOverlay";
import { SupportLettersTutorialModal } from "@/components/tutorials/supportLettersTutorial";

import { LetterReviewsTable } from "@/components/letterReviewsTable";

import { LetterTimeline } from "../timeline";
import {
  InternalCommentsProvider,
  useCommentsProvider,
} from "@/providers/commentsProvider";
import { Comment } from "@/lib/hooks/comments";

export const FailiureModal = (props: {
  open: boolean;
  setOpen: (alue: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Oops! Something Went Wrong"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Looks like something went wrong. Please try again.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export const ConfirmSuggestedChangesApprovalModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onApprove: () => void;
}) => {
  const { open, setOpen, onApprove } = props;

  const [disabled, setDisabled] = useState(false);

  const approve = () => {
    if (disabled) return;

    setDisabled(true);
    setOpen(false);
    onApprove();
    setDisabled(false);
  };

  return (
    <Modal
      open={open}
      onOpenChange={() => setOpen(false)}
      title="Confirm Approval"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          You have suggested changes to this letter. Are you sure you want to
          approve it?
        </p>
      </div>

      <div className="flex flex-row justify-end gap-2">
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={disabled}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={approve}
        >
          Approve
        </Button>
      </div>
    </Modal>
  );
};

export const hasUserSubmittedComment = (comments: Comment[]) => {
  return comments.length > 0;
};

const SubmitReviewButton = (props: {
  letterActionId: number;
  hasSuggestedChanges: boolean;
  status: string;
  onNextLetterAction: (letterActionId: number) => void;
  refetch?: () => void;
}) => {
  const {
    letterActionId,
    hasSuggestedChanges,
    status,
    onNextLetterAction,
    refetch,
  } = props;

  const client = useClient();

  const [loading, setLoading] = useState(false);
  const [
    confirmSuggestedChangesApprovalModalOpen,
    setConfirmSuggestedChangesApprovalModalOpen,
  ] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);

  const { provider, editor } = useEditorContext();

  const approveLetterActionMutation = useMutation(approveLetterAction)[1];

  const approve = async () => {
    if (loading || provider?.document == null || editor == null) return;
    setLoading(true);

    const res = await approveLetterActionMutation({
      letterActionId: letterActionId,
    });

    if (res.error) {
      setLoading(false);
      setFailureModalOpen(true);
      return;
    }

    refetch?.();

    const followUpRes = await client.query(getFollowUpAction, {
      letterActionId: letterActionId,
    });

    if (followUpRes.data?.getFollowUpAction != null) {
      setLoading(false);
      onNextLetterAction(followUpRes.data.getFollowUpAction);
      return;
    }

    setLoading(false);
  };

  return (
    <>
      <LoadingOverlay isLoading={loading} />

      <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />

      <ConfirmSuggestedChangesApprovalModal
        open={confirmSuggestedChangesApprovalModalOpen}
        setOpen={setConfirmSuggestedChangesApprovalModalOpen}
        onApprove={approve}
      />

      {status === "approved" && (
        <Button
          variant="primary"
          disabled
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button py-2 px-3"
        >
          Letter Approved
        </Button>
      )}

      {status !== "approved" && hasSuggestedChanges && (
        <Button
          onClick={() => setConfirmSuggestedChangesApprovalModalOpen(true)}
          variant="primary"
          disabled={loading}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button py-2 px-3"
        >
          Send for Signature
        </Button>
      )}

      {status !== "approved" && !hasSuggestedChanges && (
        <Button
          onClick={approve}
          variant="primary"
          disabled={loading}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button py-2 px-3"
        >
          Approve Letter
        </Button>
      )}
    </>
  );
};

const getCommentUserIdWhitelist = (
  data: ResultOf<typeof getLetterActionById>,
  companyMembers?: ResultOf<typeof getCompanyMembers>
) => {
  if (data.getLetterAction.recipientType === "beneficiary") {
    if (data.getLetterAction.beneficiaryId == null) return [];
    return [data.getLetterAction.beneficiaryId.toString()];
  }

  if (data.getLetterAction.recipientType === "company") {
    return (
      companyMembers?.getCompanyWorkspace?.members?.members?.map((el) =>
        el.id.toString()
      ) ?? []
    );
  }

  return [];
};

export const InformationModal = (props: {
  isBeneficiary: boolean;
  beneficiaryName: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, isBeneficiary, beneficiaryName, setOpen } = props;

  const formattedBeneficiaryName =
    beneficiaryName[beneficiaryName.length - 1] == "s"
      ? beneficiaryName + "'"
      : beneficiaryName + "'s";

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Reviewing a letter"
      fitContent
      contentClassName="w-[650px] max-w-[80%] z-5 py-2 px-4"
      modal={false}
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        {!isBeneficiary && (
          <p className="text-sm text-grey-300">
            Your review is needed regarding this letter in support of{" "}
            {formattedBeneficiaryName} upcoming work visa application.
          </p>
        )}

        <p className="text-sm text-grey-300">
          Please review carefully the facts presented in this letter. This
          letter will be submitted to USCIS, the government agency responsible
          for citizenship and immigration.
        </p>

        <p className="text-sm text-grey-200">A few key points about USCIS:</p>

        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-start justify-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              They prefer a grandiose tone in these letters. Although this may
              seem unusual, it is standard practice and enhances the likelihood
              of approval.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Since USCIS may not be familiar with the specific industry, it is
              crucial to clearly outline the applicant's achievements and
              expertise in related or adjacent fields.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Processing times can be lengthy and the stakes are high. Prompt
              review is highly recommended.
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() => setOpen(false)}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export const CommentHintPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <div className="flex flex-row items-center bg-grey-600 px-4 py-3 gap-2.5 rounded-lg shadow-border max-w-[400px]">
        <FaRegCommentDots className="w-6 h-6" color="#3a87c2" />

        <span className="text-sm text-grey-300">
          Highlight any text to leave a comment and propose changes
        </span>

        <button onClick={() => setOpen(false)}>
          <FiX width={10} className="text-grey-400" />
        </button>
      </div>
    )
  );
};

export const ChangesSuggestedPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    <div
      className={cn(
        "z-10 flex flex-row bg-grey-600 p-4 gap-4 absolute rounded-lg bottom-4 right-4 shadow-border w-[300px] text-grey-300 text-xs",
        !open && "hidden"
      )}
    >
      <p>
        <span className="font-medium">
          You have requested changes to this letter.
        </span>
        <br /> <br />
        Once all requested changes are addressed to your satisfaction, click
        "send for signature" to confirm the letter signer's contact information
        and send the letter.
      </p>
      <button onClick={() => setOpen(false)} className="mb-auto mt-0.5">
        <FiX width={10} className="text-grey-400" />
      </button>
    </div>
  );
};

const EditorRenderer = (props: {
  letterName: string;
  setInformationModalOpen: (value: boolean) => void;
  isBeneficiary: boolean;
  letterActionId: number;
  letterId: number;
  additionalNotes: string | null;
  onNextLetterAction: (letterActionId: number) => void;
  refetch?: () => void;
  status: string;
}) => {
  const {
    setInformationModalOpen,
    letterName,
    letterActionId,
    letterId,
    isBeneficiary,
    onNextLetterAction,
    refetch,
    status,
  } = props;

  const { comments } = useCommentsProvider();

  const hasSuggestedChanges = comments.length > 0;

  return (
    <div className="relative w-full h-full flex flex-col items-center">
      {status === "changes_requested" && <ChangesSuggestedPopup />}

      <div className="w-full flex flex-row items-center px-6 h-20 my-6">
        <div className="text-sm text-grey-200 w-1/3">{letterName}</div>

        <LetterTimeline
          status={status === "approved" ? "signature" : "review"}
        />

        <div className="flex flex-row items-center gap-3 ml-auto w-1/3 justify-end">
          <button
            onClick={() => setInformationModalOpen(true)}
            className="bg-grey-200 shadow-action-button h-8 w-8 rounded-full flex items-center justify-center"
          >
            <InfoCircledIcon color="white" width={20} />
          </button>

          {hasSuggestedChanges != null && (
            <SubmitReviewButton
              letterActionId={letterActionId}
              hasSuggestedChanges={hasSuggestedChanges}
              status={status}
              onNextLetterAction={onNextLetterAction}
              refetch={refetch}
            />
          )}
        </div>
      </div>
      <div
        className={cn(
          "flex flex-row w-full justify-center gap-2 xs:flex-col overflow-y-sroll"
        )}
      >
        <div className={cn("flex flex-col gap-4")}>
          {status === "approved" && isBeneficiary && (
            <div className="max-w-[700px] px-1">
              <LetterReviewsTable letterId={letterId} />
            </div>
          )}

          <div
            className={cn(
              "no-scrollbar w-full pb-5",
              status !== "approved" && "overflow-y-scroll"
            )}
          >
            <TextEditor
              key={letterId + status}
              disabled={status === "approved"}
              size="sm"
            />
          </div>
        </div>

        {status !== "approved" && (
          <div className="flex flex-col gap-4 px-2 h-full overflow-scroll pb-4">
            {hasSuggestedChanges == false && status === "sent" && (
              <CommentHintPopup />
            )}
            <TextEditorComments className="h-full overflow-visible" />
          </div>
        )}
      </div>
    </div>
  );
};

export const LetterReviewEditor = (props: {
  data: ResultOf<typeof getLetterActionById>;
  onNextLetterAction: (letterActionId: number) => void;
  refetch?: () => void;
}) => {
  const { data, onNextLetterAction, refetch } = props;

  const { loggedInUserId, userEntity } = useUserContext();

  const [informationModalOpen, setInformationModalOpen] = useState(
    data.getLetterAction.status === "sent"
  );

  const [{ data: companyMembers }] = useQuery({
    query: getCompanyMembers,
    variables: {
      id: data.getLetterAction.companyId ?? -1,
    },
    pause: data.getLetterAction.companyId == null,
  });

  const userName =
    userEntity != null
      ? userEntity.firstName + " " + userEntity.lastName
      : "You";

  const commentUserIdWhitelist = getCommentUserIdWhitelist(
    data,
    companyMembers
  );

  const letterTutorialModalShown = useTutorialModalState(
    (state) => state.letter.modalShown
  );

  const [status, setStatus] = useState(data.getLetterAction.status);
  const [_, requestLetterActionChangesMutation] = useMutation(
    requestLetterActionChanges
  );

  const onCommentAdded = async () => {
    if (status === "sent") {
      const res = await requestLetterActionChangesMutation({
        letterActionId: data.getLetterAction.id,
      });

      if (res.error) return;

      setStatus("changes_requested");
    }
  };

  return (
    <div className="w-full h-full bg-grey-700">
      <SupportLettersTutorialModal />
      {letterTutorialModalShown && (
        <InformationModal
          isBeneficiary={data.getLetterAction.recipientType === "beneficiary"}
          beneficiaryName={data.getLetterAction.beneficiaryName}
          open={informationModalOpen}
          setOpen={setInformationModalOpen}
        />
      )}
      {data?.getLetterAction.letterId != null && (
        <EditorContextProvider
          letterId={data.getLetterAction.letterId}
          userId={loggedInUserId?.toString() ?? ""}
          userName={userName}
          commentUserIdWhitelist={commentUserIdWhitelist}
          mode="review"
          letterActionId={data.getLetterAction.id}
          onCommentAdded={onCommentAdded}
        >
          <InternalCommentsProvider
            letterActionId={data.getLetterAction.id}
            onCommentAdded={onCommentAdded}
          >
            <EditorRenderer
              letterName={data.getLetterAction.letterName}
              letterActionId={data.getLetterAction.id}
              letterId={data.getLetterAction.letterId}
              additionalNotes={data.getLetterAction.additionalNotes}
              setInformationModalOpen={setInformationModalOpen}
              isBeneficiary={
                data.getLetterAction.recipientType === "beneficiary"
              }
              status={status}
              onNextLetterAction={onNextLetterAction}
              refetch={refetch}
            />
          </InternalCommentsProvider>
        </EditorContextProvider>
      )}
    </div>
  );
};
