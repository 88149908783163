import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from "react";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { Editor, Extensions, NodeViewProps } from "@tiptap/react";
import { SignatureBox } from "@/lib/extensions/signature";
import {
  ActiveEditorComment,
  getPortalExtensions,
  useCollaborationProvider,
} from "@lighthouse/editor";
import { getImmediateScrollableNode } from "@/lib/scroll";
import { commentElement } from "@/components/commentComposerPopover";
import {
  Comment,
  CommentsHookResult,
  useComments,
  useExternalComments,
} from "@/lib/hooks/comments";
import { useUserContext } from "@/lib/hooks/user";

const SYNC_SERVER_BASE_URL =
  import.meta.env.VITE_SYNC_SERVER_BASE_URL ??
  ("sync-server-bzr4.onrender.com" as const);

interface CommentsContextType {
  comments?: Comment[];
  createComment: CommentsHookResult["createComment"];
  createReply: CommentsHookResult["createReply"];
  deleteComment: CommentsHookResult["deleteComment"];
  updateComment?: CommentsHookResult["updateComment"];
  resolveComment: CommentsHookResult["resolveComment"];
  onCommentAdded: () => void;
  userId: string;
  userName: string;
}

export interface EditorContextType extends CommentsContextType {
  provider: HocuspocusProvider | null;
  editor: Editor | null;
  setEditor: (value: Editor | null) => void;
  extensions: Extensions | null;
  ready: boolean | null;
  mode: "sign" | "review";

  activeComment: ActiveEditorComment | null;
  onCommentActivated: (
    comment: ActiveEditorComment | null,
    scroll?: boolean
  ) => void;
  letterActionId: number;
}

const EditorContext = createContext<EditorContextType>({} as EditorContextType);

const scrollToCommentBox = (commentId: string | undefined) => {
  if (commentId == null) return;
  const commentBoxElement = document.getElementById(`comment-box-${commentId}`);

  console.log("scrollging to comment box", commentBoxElement);

  // scroll comment box
  commentBoxElement?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

interface ExternalEditorContextProviderProps {
  children: ReactNode;
  userId: string;
  userName: string;
  commentUserIdWhitelist: string[];
  letterId: number;
  mode: "sign" | "review";
  letterActionId: number;
  signatureLetterActionId?: number;
  onSignatureAdded?: () => void;
  disableSignatures?: boolean;
  token: string;
  onCommentAdded: () => void;
}

interface InternalEditorContextProviderProps {
  children: ReactNode;
  userId?: string;
  userName?: string;
  commentUserIdWhitelist: string[];
  letterId: number;
  mode: "sign" | "review";
  letterActionId: number;
  signatureLetterActionId?: number;
  onSignatureAdded?: () => void;
  disableSignatures?: boolean;
  onCommentAdded: () => void;
}

export const ExternalEditorContextProvider = (
  props: ExternalEditorContextProviderProps
) => {
  const {
    children,
    userName,
    userId,
    commentUserIdWhitelist,
    mode,
    letterId,
    letterActionId,
    signatureLetterActionId,
    onSignatureAdded,
    disableSignatures,
    token,
    onCommentAdded,
  } = props;

  const [activeComment, setActiveComment] =
    useState<ActiveEditorComment | null>(null);
  const prevActiveCommentId = useRef<string | null>(null);

  const [editor, setEditor] = useState<Editor | null>(null);

  const [provider, syncState, _] = useCollaborationProvider({
    type: "letter",
    docId: letterId.toString(),
    token: undefined,
    isLoggedIn: true,
    syncServerUrl: `wss://${SYNC_SERVER_BASE_URL}/collaboration`,
    userId: userId,
    userName: userName,
  });

  // Use the external comments hook
  const {
    comments,
    createComment,
    createReply,
    deleteComment,
    resolveComment,
    updateComment,
  } = useExternalComments({
    letterActionId,
    token,
  });

  const onCommentActivated = (
    comment: ActiveEditorComment | null,
    scrollCommentBox: boolean | undefined = true
  ) => {
    // Prevent infinite loop by checking if this is the same comment
    if (comment?.id === prevActiveCommentId.current) {
      return; // Skip update if it's the same comment
    }

    // Update state only if it's a different comment
    setActiveComment(comment);
    prevActiveCommentId.current = comment?.id ?? null;

    if (comment == null) return;

    if (scrollCommentBox) {
      scrollToCommentBox(comment.id);
    }

    // Handle scrolling if needed
    const commentMarkElement = commentElement(
      comment.id,
      false,
      editor?.view.dom
    );

    const commentMarkElementRect = commentMarkElement?.getBoundingClientRect();
    const top = commentMarkElementRect?.top ?? 0;

    const viewBottom = Math.max(100, window.innerHeight / 4);
    // const markIsOutOfView = top < 0 || top > viewBottom;

    const scrollableParent = getImmediateScrollableNode(commentMarkElement);

    scrollableParent.scrollTo({
      top: scrollableParent.scrollTop + top - viewBottom,
      behavior: "smooth",
    });
  };

  const extensions = useMemo(() => {
    if (provider == null) return [];

    return getPortalExtensions({
      provider,
      comments: {
        onCommentActivated,
        visibleCommentIds: (comments ?? []).map((c) => c.id.toString()),
      },
      signature: {
        onSignatureAdded,
        signatureBoxComponent: SignatureBox as React.FC<NodeViewProps>,
        disabled: disableSignatures ?? false,
        letterActionId: (signatureLetterActionId ?? letterActionId).toString(),
      },
    });
  }, [
    provider,
    userName,
    onSignatureAdded,
    signatureLetterActionId,
    letterActionId,
    disableSignatures,
    commentUserIdWhitelist,
    comments,
  ]);

  const doResolve = async (commentId: number, resolved: boolean) => {
    const res = await resolveComment(commentId, resolved);
    if (res.error == null) {
      await editor?.commands.unsetComment(commentId.toString());
    }
    return res;
  };

  return (
    <EditorContext.Provider
      value={{
        provider,
        extensions,
        ready: syncState === "connected" && comments != null,
        editor,
        setEditor,
        activeComment,
        userId,
        userName,
        mode,
        onCommentActivated,
        letterActionId,
        comments,
        createComment,
        createReply,
        deleteComment,
        updateComment,
        resolveComment: doResolve,
        onCommentAdded,
      }}
    >
      <React.Fragment key={letterId}>
        <React.Fragment>{children}</React.Fragment>
      </React.Fragment>
    </EditorContext.Provider>
  );
};

export const InternalEditorContextProvider = (
  props: InternalEditorContextProviderProps
) => {
  const {
    children,
    userName: propUserName,
    userId: propUserId,
    mode,
    letterId,
    letterActionId,
    signatureLetterActionId,
    onSignatureAdded,
    disableSignatures,
    onCommentAdded,
  } = props;

  const [activeComment, setActiveComment] =
    useState<ActiveEditorComment | null>(null);
  const prevActiveCommentId = useRef<string | null>(null);

  const user = useUserContext();
  const userId = propUserId ?? user?.userId?.toString() ?? "";
  const userName =
    propUserName ??
    `${user?.userEntity?.firstName} ${user?.userEntity?.lastName}`;

  const [provider, syncState, _] = useCollaborationProvider({
    type: "letter",
    docId: letterId.toString(),
    token: undefined,
    isLoggedIn: true,
    syncServerUrl: `wss://${SYNC_SERVER_BASE_URL}/collaboration`,
    userId: userId,
    userName: userName,
  });

  // Use the internal comments hook
  const {
    comments,
    createComment,
    createReply,
    deleteComment,
    resolveComment,
    updateComment,
  } = useComments({ letterActionId });

  const onCommentActivated = (
    comment: ActiveEditorComment | null,
    scrollCommentBox: boolean | undefined = true
  ) => {
    // Prevent infinite loop by checking if this is the same comment
    if (comment?.id === prevActiveCommentId.current) {
      return; // Skip update if it's the same comment
    }

    // Update state only if it's a different comment
    setActiveComment(comment);
    prevActiveCommentId.current = comment?.id ?? null;

    if (comment == null) return;

    if (scrollCommentBox) {
      scrollToCommentBox(comment.id);
    }

    // Handle scrolling if needed
    const commentMarkElement = commentElement(
      comment.id,
      false,
      editorRef.current?.view.dom
    );

    const commentMarkElementRect = commentMarkElement?.getBoundingClientRect();
    const top = commentMarkElementRect?.top ?? 0;

    const viewBottom = Math.max(100, window.innerHeight / 4);
    // const markIsOutOfView = top < 0 || top > viewBottom;

    const scrollableParent = getImmediateScrollableNode(commentMarkElement);

    scrollableParent.scrollTo({
      top: scrollableParent.scrollTop + top - viewBottom,
      behavior: "smooth",
    });
  };

  const visibleCommentIds: string[] = useMemo(() => {
    return (comments ?? []).map((c) => c.id.toString());
  }, [comments]);

  const extensions = useMemo(() => {
    if (provider == null) return [];

    return getPortalExtensions({
      provider,
      comments: {
        onCommentActivated,
        visibleCommentIds,
      },
      signature: {
        onSignatureAdded,
        signatureBoxComponent: SignatureBox as React.FC<NodeViewProps>,
        disabled: disableSignatures ?? false,
        letterActionId: (signatureLetterActionId ?? letterActionId).toString(),
      },
    });
  }, [
    provider,
    onCommentActivated,
    comments,
    onSignatureAdded,
    signatureLetterActionId,
    letterActionId,
    disableSignatures,
  ]);

  const editorRef = useRef<Editor | null>(null);

  const setEditor = (editor: Editor | null) => {
    if (editorRef.current !== editor) {
      editorRef.current = editor;
    }
  };

  return (
    <EditorContext.Provider
      value={{
        provider,
        extensions,
        ready: syncState === "connected",
        editor: editorRef.current,
        setEditor,
        activeComment,
        userId,
        userName,
        mode,
        onCommentActivated,
        letterActionId,
        comments,
        createComment,
        createReply,
        deleteComment,
        updateComment,
        resolveComment,
        onCommentAdded,
      }}
    >
      <React.Fragment key={letterId}>
        <React.Fragment>{children}</React.Fragment>
      </React.Fragment>
    </EditorContext.Provider>
  );
};

// For backward compatibility
export const EditorContextProvider = InternalEditorContextProvider;

export const useEditorContext = (): EditorContextType => {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error(
      "useEditorContext must be used within an EditorContextProvider"
    );
  }
  return context;
};

// Alias for backward compatibility with useCommentsProvider
export const useCommentsProvider = useEditorContext;
