import * as Dialog from "@radix-ui/react-dialog";
import { ModalCloseIcon } from "./icons/modalClose";
import { useSidebarStore } from "./sidebar";
import { cn } from "@/lib/cn";
import { Button } from "./button";
import { useEffect, useRef, useState } from "react";
import { InReviewIcon } from "./icons/inReview";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { motion, AnimatePresence } from "framer-motion";

export const Modal = (props: {
  children: React.ReactNode;
  open: boolean;
  title?: string;
  fitContent?: boolean;
  borderUnderTitle?: boolean;
  onOpenChange: (value: boolean) => void;
  contentClassName?: string;
  childrenClassName?: string;
  titleClassName?: string;
  modal?: boolean;
}) => {
  const {
    children,
    open,
    onOpenChange,
    fitContent = false,
    borderUnderTitle = false,
    contentClassName,
    childrenClassName,
    titleClassName,
    modal,
  } = props;

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={modal}>
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0 bg-grey-600 bg-opacity-[40%]"
              />
            </Dialog.Overlay>
            <Dialog.Content asChild>
              <motion.div
                initial={{ opacity: 0, scale: 0.95, y: "-50%", x: "-50%" }}
                animate={{ opacity: 1, scale: 1, y: "-50%", x: "-50%" }}
                exit={{ opacity: 0, scale: 0.95, y: "-50%", x: "-50%" }}
                transition={{ duration: 0.2, ease: "easeOut" }}
                className={cn(
                  "bg-white border border-grey-600 shadow-modal rounded-[16px] fixed top-1/2 left-1/2 w-[90vw] max-w-[628px] max-h-[645px] overflow-hidden gap-2 z-5",
                  fitContent ? "max-h-[90vh]" : "h-[90vh]",
                  contentClassName
                )}
              >
                <div className="flex flex-col h-full w-full">
                  <div
                    className={cn(
                      "flex flex-row w-full items-center pt-6 pr-6 pb-4 pl-8",
                      borderUnderTitle && "border-b-[1px] border-b-grey-600",
                      titleClassName
                    )}
                  >
                    {props.title && <ModalTitle>{props.title}</ModalTitle>}
                    <Dialog.Close className="ml-auto">
                      <ModalCloseIcon />
                    </Dialog.Close>
                  </div>
                  <div
                    className={cn(
                      "pr-2 pl-8 pb-4 h-full w-full overflow-auto",
                      childrenClassName
                    )}
                  >
                    {children}
                  </div>
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};

export const ModalTitle = (props: { children: React.ReactNode }) => {
  return (
    <Dialog.Title className="text-base font-medium text-grey-100">
      {props.children}
    </Dialog.Title>
  );
};

export const ModalDescription = (props: { children: React.ReactNode }) => {
  return <Dialog.Description>{props.children}</Dialog.Description>;
};

export const FormModal = (props: {
  children: React.ReactNode;
  open: boolean;
  title?: string;
  onOpenChange: (value: boolean) => void;
}) => {
  const { children, open, onOpenChange } = props;

  const sidebarOpen = useSidebarStore((state) => state.open);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={true}>
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0"
              />
            </Dialog.Overlay>
            <Dialog.Content asChild>
              <motion.div
                initial={{
                  opacity: 0,
                  scale: 0.95,
                  y: "-50%",
                  x: sidebarOpen ? "calc(-50% + 128px)" : "calc(-50% + 30px)",
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  y: "-50%",
                  x: sidebarOpen ? "calc(-50% + 128px)" : "calc(-50% + 30px)",
                }}
                exit={{
                  opacity: 0,
                  scale: 0.95,
                  y: "-50%",
                  x: sidebarOpen ? "calc(-50% + 128px)" : "calc(-50% + 30px)",
                }}
                transition={{ duration: 0.2, ease: "easeOut" }}
                className={cn(
                  "bg-grey-800 border border-grey-600 shadow-modal rounded-[16px] fixed top-1/2 left-1/2 w-[90vw] max-w-[628px] max-h-[700px] overflow-hidden",
                  "shadow-border"
                )}
              >
                <div className="flex flex-col h-full w-full">
                  <div className="flex flex-row w-full items-center pt-6 pr-6 pb-4 pl-8">
                    {props.title && <ModalTitle>{props.title}</ModalTitle>}
                    <Dialog.Close className="ml-auto">
                      <ModalCloseIcon />
                    </Dialog.Close>
                  </div>
                  <div className="pr-2 pl-8 pb-4 h-full w-full overflow-auto">
                    {children}
                  </div>
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};

export const CustomRightSideModal = (props: {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  title?: React.ReactNode;
  children: React.ReactNode;
  previousAllowed?: boolean;
  nextAllowed?: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  onSubmit?: () => void;
  submissionStatus?: "rejected" | "submitted_for_review" | "pending";
}) => {
  const {
    open,
    onOpenChange,
    children,
    previousAllowed,
    nextAllowed,
    onPrevious,
    onNext,
    onSubmit,
    submissionStatus,
  } = props;
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        event.clientX < modalRef.current.offsetLeft
      ) {
        onOpenChange(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onOpenChange]);

  if (!open) return null;

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 z-10 flex"
        >
          <motion.div
            ref={modalRef}
            initial={{ x: 60, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 60, opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
            className="bg-grey-800 border border-grey-600 shadow-modal w-[90vw] max-w-[643px] h-screen flex flex-col ml-auto"
          >
            <div className="flex flex-row w-full items-center pt-6 pr-6 pb-4 pl-8">
              {props.title && (
                <h1 className="text-base font-semibold text-grey-100">
                  {props.title}
                </h1>
              )}
              <button className="ml-auto" onClick={() => onOpenChange(false)}>
                <ModalCloseIcon />
              </button>
            </div>
            <div className="pr-2 pl-8 pb-4 h-full w-full overflow-auto">
              {children}
            </div>

            <div className="flex flex-row items-center w-full border-t-[1px] border-t-grey-600 pt-6 pl-8 pr-6 pb-6">
              <div className="w-full flex flex-row items-center">
                {previousAllowed && (
                  <Button
                    variant="secondary"
                    className="shadow-border bg-grey-700"
                    onClick={onPrevious}
                  >
                    Previous Applicant
                  </Button>
                )}
                <div className="flex flex-row items-center gap-2 ml-auto">
                  {nextAllowed && (
                    <Button
                      variant="secondary"
                      className="shadow-border bg-grey-700"
                      onClick={onNext}
                    >
                      Next Applicant
                    </Button>
                  )}

                  {submissionStatus === "submitted_for_review" && (
                    <Button
                      variant="primary"
                      disabled
                      className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
                    >
                      <div className="flex flex-row items-center gap-2">
                        <InReviewIcon />
                        Submitted
                      </div>
                    </Button>
                  )}

                  {submissionStatus !== "submitted_for_review" && (
                    <Button
                      variant="primary"
                      onClick={onSubmit}
                      className={`disabled:opacity-50 disabled:cursor-not-allowed`}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export type BaseTutorialPageProps = {
  topDescription?: React.ReactNode;
  bottomDescription?: React.ReactNode;
  image?: React.ReactNode;
  title?: React.ReactNode;
};

export const TutorialPage = (props: {
  topDescription?: React.ReactNode;
  image?: React.ReactNode;
  title?: React.ReactNode;
  currentPageNumber: number;
  totalPages: number;
  bottomDescription?: React.ReactNode;
}) => {
  return (
    <div className="w-full h-full flex flex-col gap-5">
      <span className="text-sm text-grey-300">{props.topDescription}</span>
      {props.image != null && (
        <div className="flex w-full items-center justify-center">
          {props.image}
        </div>
      )}

      <div className="w-full flex flex-col gap-1 justify-center items-center">
        <span className="text-xs text-grey-400">
          {props.currentPageNumber} / {props.totalPages}
        </span>
        {props.title != null && (
          <span className="text-sm text-grey-200 font-medium">
            {props.title}
          </span>
        )}

        {props.bottomDescription != null && (
          <span className="text-xs text-grey-200 text-center">
            {props.bottomDescription}
          </span>
        )}
      </div>
    </div>
  );
};

export const TutorialModal = (props: {
  pages: Partial<BaseTutorialPageProps>[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCompleteTutorial?: () => void;
}) => {
  const { pages, open, onOpenChange, onCompleteTutorial } = props;
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const currentPage = pages[currentPageNumber];

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      titleClassName="hidden"
      contentClassName="w-[544px]"
      fitContent
      childrenClassName="p-0"
      title=""
    >
      <div className="w-full h-full flex flex-col pb-6 px-10 pt-8 gap-6">
        <TutorialPage
          topDescription={currentPage.topDescription}
          image={currentPage.image}
          title={currentPage.title}
          currentPageNumber={currentPageNumber + 1}
          totalPages={pages.length}
          bottomDescription={currentPage.bottomDescription}
        />
        <div className="flex flex-row items-center gap-2">
          {currentPageNumber > 0 && (
            <Button
              variant="secondary"
              onClick={() => setCurrentPageNumber((x) => x - 1)}
            >
              <ArrowLeftIcon />
            </Button>
          )}

          <Button
            variant="secondary"
            onClick={onCompleteTutorial}
            className="px-3"
          >
            Skip Tutorial
          </Button>

          {currentPageNumber === pages.length - 1 ? (
            <Button
              variant="primary"
              onClick={onCompleteTutorial}
              className="ml-auto px-3"
            >
              Close
            </Button>
          ) : (
            <Button
              variant="primary"
              className="ml-auto px-3"
              onClick={() => setCurrentPageNumber((x) => x + 1)}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
