import { cn } from "@/lib/cn";

export const SupportLetterIcon = (props: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn(props.className)}
  >
    <path
      d="M2 4H14V12.5C14 12.6326 13.9473 12.7598 13.8536 12.8536C13.7598 12.9473 13.6326 13 13.5 13H2.5C2.36739 13 2.24021 12.9473 2.14645 12.8536C2.05268 12.7598 2 12.6326 2 12.5V4Z"
      stroke="#CDCFD3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.8"
    />
    <path
      d="M14 4L8 9.5L2 4"
      stroke="#CDCFD3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.8"
    />
    <path
      d="M6.9093 8.5L2.1543 12.8588"
      stroke="#CDCFD3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.8"
    />
    <path
      d="M13.8458 12.8588L9.09082 8.5"
      stroke="#CDCFD3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.8"
    />
  </svg>
);
