// src/provider/hooks.ts
import {
  HocuspocusProvider
} from "@hocuspocus/provider";
import { useState } from "react";
import * as Y from "yjs";
var useCollaborationProvider = (options) => {
  const { docId, syncServerUrl, token, isLoggedIn, type, userId, userName } = options;
  const [syncState, setSyncState] = useState("connecting");
  const [syncError, setSyncError] = useState(null);
  const [provider] = useState(() => {
    const document = new Y.Doc();
    if (type === "node" || type === "letter") {
      document.gc = false;
    }
    const p = new HocuspocusProvider({
      document,
      url: syncServerUrl,
      name: `${type}:${docId}`,
      token: () => token ?? "lighthousehocuspocus",
      connect: isLoggedIn,
      onAuthenticationFailed() {
        setSyncError("error");
      },
      onAuthenticated() {
        if (syncError !== "invalid-schema") {
          setSyncError(null);
        }
      },
      onStatus(data) {
        setSyncState(data.status);
      },
      forceSyncInterval: 1e3,
      preserveConnection: true
    });
    if (type === "node" || type === "letter") {
      p.setAwarenessField("clientId", p.document.clientID);
      if (userId) {
        p.setAwarenessField("user", {
          id: userId,
          name: userName || "Anonymous"
        });
      }
      p.on("outgoingMessage", (data) => {
        if (data.message.type === 0) {
          p.setAwarenessField("lastEdit", (/* @__PURE__ */ new Date()).getTime());
        }
      });
    }
    return p;
  });
  return [provider, syncState, syncError];
};

// src/extensions/commentHighlight.ts
import { Mark } from "@tiptap/core";
var CommentHighlight = Mark.create({
  name: "comment-highlight",
  priority: 1e3,
  addStorage() {
    return {
      allMarks: null
    };
  },
  addAttributes() {
    return {
      type: {
        renderHTML: () => {
          return {
            "data-type": this.name
          };
        }
      },
      id: {
        parseHTML: (element) => element.getAttribute("data-id") ?? "",
        renderHTML: (attributes) => ({
          id: `comment-highlight-${attributes.id}`,
          "data-id": attributes.id
        })
      },
      internal: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-internal") === "true",
        renderHTML: (attributes) => {
          return {
            "data-internal": attributes.internal
          };
        }
      },
      focused: {
        default: false,
        renderHTML: (attributes) => {
          const isFocused = this.options.focusedCommentId === attributes.id;
          if (!isFocused) return {};
          const isInternal = attributes.internal === true;
          const highlightClass = isInternal ? "comment-highlight-focused" : "comment-highlight-focused-external";
          return {
            class: highlightClass,
            style: `background-color: ${isInternal ? "#fcbd06" : "#e4c1fe"}`
          };
        }
      },
      color: {
        renderHTML: (attributes) => {
          if (this.options.provider?.document == null) return {};
          const comments = this.options.provider.document.getArray("comments");
          const comment = comments.toArray().find((c) => c.get("id") === attributes.id);
          if (comment == null || comment.get("resolved") === true) {
            return {};
          }
          if (comment.get("completed") === false) return {};
          if (comment.get("internal") !== false) return {};
          if (comment.get("lighthouseAuthor") === true) {
            return {
              style: `background-color: #faeaff`
            };
          }
          if (this.options.commentUserIdWhitelist) {
            const commentUserId = comment.get("userId");
            if (commentUserId == null || !this.options.commentUserIdWhitelist.includes(commentUserId)) {
              return {};
            }
          }
          return {
            style: `background-color: #fef2d3`
          };
        }
      },
      resolved: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-resolved") === "true",
        renderHTML: (attributes) => {
          return {
            "data-resolved": attributes.resolved
          };
        }
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (element) => element.getAttribute("data-type") === "comment-highlight" && null
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["span", HTMLAttributes, 0];
  },
  addCommands() {
    return {
      setCommentHighlight: (attributes) => ({ commands }) => {
        return commands.setMark(this.name, attributes);
      },
      deleteCommentHighlightById: (id) => ({ editor, dispatch, tr }) => {
        if (dispatch == null) return false;
        editor.state.doc.descendants((node, pos) => {
          const { marks } = node;
          marks.forEach((mark) => {
            if (mark.type.name === this.name && mark.attrs.id === id) {
              const transaction = tr.removeMark(
                pos,
                pos + node.nodeSize,
                mark
              );
              dispatch(transaction);
              return true;
            }
          });
        });
        return false;
      },
      updateCommentById: (id, property, value) => ({ editor, dispatch, tr }) => {
        editor.state.doc.descendants((node, pos) => {
          if (dispatch == null) return false;
          const { marks } = node;
          marks.forEach((mark) => {
            if (mark.type.name === this.name && mark.attrs.id === id) {
              const transaction = tr.addMark(
                pos,
                pos + node.nodeSize,
                editor.schema.marks[this.name].create({
                  ...mark.attrs,
                  [property]: value
                })
              );
              dispatch(transaction);
              return true;
            }
          });
        });
        return false;
      }
    };
  },
  onUpdate() {
    try {
      if (this.options.preview) return;
      if (this.options.provider == null) return;
      if (this.editor.isDestroyed) return;
      const comments = this.options.provider.document?.getArray("comments");
      if (!comments) return;
      const validCommentIds = new Set(
        comments.toArray().map((comment) => comment.get("id"))
      );
      this.editor.state.doc.descendants((node, _) => {
        if (!node.marks.length) return;
        node.marks.forEach((mark) => {
          if (mark.type.name === "comment-highlight") {
            const markId = mark.attrs.id;
            if (!validCommentIds.has(markId)) {
              try {
                this.editor.commands.deleteCommentHighlightById(markId);
              } catch (error) {
                console.error("Error removing comment highlight:", error);
              }
            }
          }
        });
      });
    } catch (error) {
      console.error("Error in CommentHighlight onUpdate:", error);
    }
  },
  onSelectionUpdate() {
    if (this.options.preview) return;
    if (!this.editor.isActive("comment-highlight")) {
      this.options.handleFocusComment(null);
      return;
    }
    const comment = this.editor.getAttributes("comment-highlight");
    this.options.handleFocusComment(comment.id, false);
  }
});

// src/extensions/signature.ts
import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
var Signature = Node.create({
  name: "signature",
  draggable: true,
  group: "block",
  inline: false,
  addAttributes() {
    return {
      letterActionId: {
        default: "",
        parseHTML: (element) => element.getAttribute("letterActionId") ?? ""
      },
      signed: {
        default: false,
        parseHTML: (element) => element.getAttribute("signed") === "true"
      },
      src: {
        default: "",
        parseHTML: (element) => element.getAttribute("src")
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "signature"
      }
    ];
  },
  renderHTML(args) {
    if (this.options.renderHTML != null) {
      return this.options.renderHTML(args.node);
    }
    args.node;
    return ["signature", args.HTMLAttributes];
  },
  addNodeView() {
    const renderer = this.options.renderer ?? ((_) => {
      return [];
    });
    return ReactNodeViewRenderer(renderer);
  },
  addCommands() {
    return {
      createSignature: (letterActionId) => ({ commands, editor }) => {
        const res = commands.insertContentAt(editor.state.doc.content.size, {
          type: this.name,
          attrs: {
            letterActionId,
            signed: false
          }
        });
        return res;
      },
      deleteSignature: (letterActionId) => ({ editor }) => {
        editor.state.doc.descendants((node, pos) => {
          if (node.type.name === this.name && node.attrs.letterActionId === letterActionId) {
            return editor.chain().focus().deleteRange({
              from: pos,
              to: pos + node.nodeSize
            }).run();
          }
        });
        return false;
      },
      resetAllSignatures: () => ({ tr, state }) => {
        state.doc.descendants((node, pos) => {
          if (node.type.name === this.name) {
            tr.setNodeAttribute(pos, "signed", false);
            tr.setNodeAttribute(pos, "src", "");
            return false;
          }
          return true;
        });
        return true;
      }
    };
  }
});

// src/utils/hooks.ts
import { useEffect, useState as useState2 } from "react";
var useRerenderOnEditorChange = (editor, callback) => {
  const [rerendered, forceRerender] = useState2({});
  const doRerender = () => {
    forceRerender({});
    callback?.();
  };
  useEffect(() => {
    editor?.on("transaction", doRerender);
    return () => {
      editor?.off("transaction", doRerender);
    };
  }, [editor]);
  return rerendered;
};
var useRerenderOnYObjectChange = (obj, callback, deps) => {
  const [rerendered, forceRerender] = useState2({});
  const doRerender = () => {
    forceRerender({});
    callback?.();
  };
  useEffect(() => {
    doRerender();
    obj?.observeDeep(doRerender);
    return () => {
      obj?.unobserveDeep(doRerender);
    };
  }, [...deps ?? []]);
  return rerendered;
};
var useComments = (editorRef, provider) => {
  const [comments, setComments] = useState2([]);
  useRerenderOnYObjectChange(
    provider?.document.getArray("comments"),
    () => {
      const res = [];
      for (const comment of provider.document.getArray("comments")) {
        if (comment == null) continue;
        res.push(comment);
      }
      setComments(res);
    },
    [provider]
  );
  const deleteComment = (id) => {
    if (editorRef == null) return;
    const comments2 = provider.document.getArray("comments");
    editorRef.current?.commands.deleteCommentHighlightById(id);
    let index = -1;
    for (let i = 0; i < comments2.length; i++) {
      if (comments2.get(i).get("id") === id) {
        index = i;
        break;
      }
    }
    if (index === -1) return;
    comments2.delete(index);
  };
  const resolveComment = (resolved, id) => {
    if (editorRef.current == null) return;
    const comments2 = provider.document.getArray("comments");
    let index = -1;
    for (let i = 0; i < comments2.length; i++) {
      if (comments2.get(i).get("id") === id) {
        index = i;
        break;
      }
    }
    if (index === -1) return;
    const commentToUpdate = comments2.get(index);
    editorRef.current.commands.updateCommentById(
      commentToUpdate.get("id"),
      "resolved",
      resolved
    );
    commentToUpdate.set("resolved", resolved);
  };
  return {
    comments,
    deleteComment,
    resolveComment
  };
};

// src/builder.ts
import { Collaboration } from "@tiptap/extension-collaboration";
import { CollaborationCursor } from "@tiptap/extension-collaboration-cursor";
import { StarterKit } from "@tiptap/starter-kit";
import { TextAlign } from "@tiptap/extension-text-align";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Highlight } from "@tiptap/extension-highlight";
import { ImageResize } from "tiptap-extension-resize-image";
import { BubbleMenu } from "@tiptap/extension-bubble-menu";
import { Underline } from "@tiptap/extension-underline";
import uniqolor from "uniqolor";

// src/extensions/uniqueId.ts
import { UniqueID } from "@tiptap-pro/extension-unique-id";
import { isChangeOrigin } from "@tiptap/extension-collaboration";
var UniqueId = UniqueID.configure({
  types: [
    "comment-highlight",
    "signature",
    "paragraph",
    "heading",
    "bulletList",
    "orderedList",
    "image",
    "code",
    "codeBlock",
    "blockquote",
    "horizontalRule"
  ],
  filterTransaction: (transaction) => !isChangeOrigin(transaction)
});

// src/utils/isRemoteTransaction.ts
function isRemoteTransaction(tr) {
  const meta = tr.getMeta("y-sync") || tr.getMeta("y-sync$") || tr.getMeta("y-sync$1");
  return !!meta?.isChangeOrigin;
}

// src/extensions/comment.ts
import { Mark as Mark2, mergeAttributes } from "@tiptap/core";
import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { v4 as uuid } from "uuid";

// src/lib/recreate-transform/merge.js
import { ChangeSet } from "@tiptap/pm/changeset";
import { Mapping, Transform as Transform2 } from "@tiptap/pm/transform";

// src/lib/recreate-transform/recreate.js
import { diffChars, diffWordsWithSpace } from "diff";
import { ReplaceStep, Transform } from "@tiptap/pm/transform";
import { applyPatch, createPatch } from "rfc6902";
function getReplaceStep(fromDoc, toDoc) {
  let start = toDoc.content.findDiffStart(fromDoc.content);
  if (start === null) {
    return false;
  }
  let { a: endA, b: endB } = toDoc.content.findDiffEnd(fromDoc.content);
  const overlap = start - Math.min(endA, endB);
  if (overlap > 0) {
    if (
      // If there is an overlap, there is some freedom of choise in how to calculate the start/end boundary.
      // for an inserted/removed slice. We choose the extreme with the lowest depth value.
      fromDoc.resolve(start - overlap).depth < toDoc.resolve(endA + overlap).depth
    ) {
      start -= overlap;
    } else {
      endA += overlap;
      endB += overlap;
    }
  }
  return new ReplaceStep(start, endB, toDoc.slice(start, endA));
}
function removeMarks(doc) {
  const tr = new Transform(doc);
  tr.removeMark(0, doc.nodeSize - 2);
  return tr.doc;
}
var RecreateTransform = class {
  constructor(fromDoc, toDoc, complexSteps, wordDiffs) {
    this.fromDoc = fromDoc;
    this.toDoc = toDoc;
    this.complexSteps = complexSteps;
    this.wordDiffs = wordDiffs;
    this.schema = fromDoc.type.schema;
    this.tr = new Transform(fromDoc);
  }
  init() {
    if (this.complexSteps) {
      this.currentJSON = this.marklessDoc(this.fromDoc).toJSON();
      this.finalJSON = this.marklessDoc(this.toDoc).toJSON();
      this.ops = createPatch(this.currentJSON, this.finalJSON);
      this.recreateChangeContentSteps();
      this.recreateChangeMarkSteps();
    } else {
      this.currentJSON = this.fromDoc.toJSON();
      this.finalJSON = this.toDoc.toJSON();
      this.ops = createPatch(this.currentJSON, this.finalJSON);
      this.recreateChangeContentSteps();
    }
    this.simplifyTr();
    return this.tr;
  }
  recreateChangeContentSteps() {
    let ops = [];
    while (this.ops.length) {
      let op = this.ops.shift(), toDoc = false;
      const afterStepJSON = JSON.parse(JSON.stringify(this.currentJSON)), pathParts = op.path.split("/");
      ops.push(op);
      while (!toDoc) {
        applyPatch(afterStepJSON, [op]);
        try {
          toDoc = this.schema.nodeFromJSON(afterStepJSON);
          toDoc.check();
        } catch (error) {
          toDoc = false;
          if (this.ops.length) {
            op = this.ops.shift();
            ops.push(op);
          } else {
            throw new Error("No valid diff possible!");
          }
        }
      }
      if (this.complexSteps && ops.length === 1 && (pathParts.includes("attrs") || pathParts.includes("type"))) {
        this.addSetNodeMarkup();
        ops = [];
      } else if (ops.length === 1 && op.op === "replace" && pathParts[pathParts.length - 1] === "text") {
        this.addReplaceTextSteps(op, afterStepJSON);
        ops = [];
      } else {
        if (this.addReplaceStep(toDoc, afterStepJSON)) {
          ops = [];
        }
      }
    }
  }
  recreateChangeMarkSteps() {
    this.toDoc.descendants((tNode, tPos) => {
      if (!tNode.isInline) {
        return true;
      }
      this.tr.doc.nodesBetween(tPos, tPos + tNode.nodeSize, (fNode, fPos) => {
        if (!fNode.isInline) {
          return true;
        }
        const from = Math.max(tPos, fPos), to = Math.min(tPos + tNode.nodeSize, fPos + fNode.nodeSize);
        fNode.marks.forEach((nodeMark) => {
          if (!nodeMark.isInSet(tNode.marks)) {
            this.tr.removeMark(from, to, nodeMark);
          }
        });
        tNode.marks.forEach((nodeMark) => {
          if (!nodeMark.isInSet(fNode.marks)) {
            this.tr.addMark(from, to, nodeMark);
          }
        });
      });
    });
  }
  marklessDoc(doc) {
    const tr = new Transform(doc);
    tr.removeMark(0, doc.nodeSize - 2);
    return tr.doc;
  }
  // From http://prosemirror.net/examples/footnote/
  addReplaceStep(toDoc, afterStepJSON) {
    const fromDoc = this.schema.nodeFromJSON(this.currentJSON), step = getReplaceStep(fromDoc, toDoc);
    if (!step) {
      return false;
    } else if (!this.tr.maybeStep(step).failed) {
      this.currentJSON = afterStepJSON;
    } else {
      throw new Error("No valid step found.");
    }
  }
  /** update node with attrs and marks, may also change type */
  addSetNodeMarkup() {
    const fromDoc = this.schema.nodeFromJSON(this.currentJSON);
    const toDoc = this.schema.nodeFromJSON(this.finalJSON);
    const start = toDoc.content.findDiffStart(fromDoc.content);
    const fromNode = fromDoc.nodeAt(start);
    const toNode = toDoc.nodeAt(start);
    if (start != null) {
      const nodeType = fromNode.type === toNode.type ? null : toNode.type;
      try {
        this.tr.setNodeMarkup(start, nodeType, toNode.attrs, toNode.marks);
      } catch (e) {
        if (nodeType && e.message.includes("Invalid content")) {
          this.tr.replaceWith(start, start + fromNode.nodeSize, toNode);
        } else {
          throw e;
        }
      }
      this.currentJSON = removeMarks(this.tr.doc).toJSON();
      this.ops = createPatch(this.currentJSON, this.finalJSON);
      return true;
    }
    return false;
  }
  addReplaceTextSteps(op, afterStepJSON) {
    const op1 = Object.assign({}, op, { value: "xx" }), op2 = Object.assign({}, op, { value: "yy" });
    const afterOP1JSON = JSON.parse(JSON.stringify(this.currentJSON)), afterOP2JSON = JSON.parse(JSON.stringify(this.currentJSON)), pathParts = op.path.split("/");
    let obj = this.currentJSON;
    applyPatch(afterOP1JSON, [op1]);
    applyPatch(afterOP2JSON, [op2]);
    const op1Doc = this.schema.nodeFromJSON(afterOP1JSON), op2Doc = this.schema.nodeFromJSON(afterOP2JSON);
    let offset = op1Doc.content.findDiffStart(op2Doc.content);
    const marks = op1Doc.resolve(offset + 1).marks();
    pathParts.shift();
    while (pathParts.length) {
      const pathPart = pathParts.shift();
      obj = obj[pathPart];
    }
    const finalText = op.value, currentText = obj;
    const textDiffs = this.wordDiffs ? diffWordsWithSpace(currentText, finalText) : diffChars(currentText, finalText);
    while (textDiffs.length) {
      const diff = textDiffs.shift();
      if (diff.added) {
        if (textDiffs.length && textDiffs[0].removed) {
          const nextDiff = textDiffs.shift();
          this.tr.replaceWith(
            offset,
            offset + nextDiff.value.length,
            this.schema.nodeFromJSON({ type: "text", text: diff.value }).mark(marks)
          );
        } else {
          this.tr.insert(offset, this.schema.nodeFromJSON({ type: "text", text: diff.value }).mark(marks));
        }
        offset += diff.value.length;
      } else if (diff.removed) {
        if (textDiffs.length && textDiffs[0].added) {
          const nextDiff = textDiffs.shift();
          this.tr.replaceWith(
            offset,
            offset + diff.value.length,
            this.schema.nodeFromJSON({ type: "text", text: nextDiff.value }).mark(marks)
          );
          offset += nextDiff.value.length;
        } else {
          this.tr.delete(offset, offset + diff.value.length);
        }
      } else {
        offset += diff.value.length;
      }
    }
    this.currentJSON = afterStepJSON;
  }
  // join adjacent ReplaceSteps
  simplifyTr() {
    if (!this.tr.steps.length) {
      return;
    }
    const newTr = new Transform(this.tr.docs[0]), oldSteps = this.tr.steps.slice();
    while (oldSteps.length) {
      let step = oldSteps.shift();
      while (oldSteps.length && step.merge(oldSteps[0])) {
        const addedStep = oldSteps.shift();
        if (step instanceof ReplaceStep && addedStep instanceof ReplaceStep) {
          step = getReplaceStep(newTr.doc, addedStep.apply(step.apply(newTr.doc).doc).doc);
        } else {
          step = step.merge(addedStep);
        }
      }
      newTr.step(step);
    }
    this.tr = newTr;
  }
};
function recreateTransform(fromDoc, toDoc, complexSteps = true, wordDiffs = false) {
  const recreator = new RecreateTransform(fromDoc, toDoc, complexSteps, wordDiffs);
  return recreator.init();
}

// src/extensions/comment.ts
var commentKey = new PluginKey("commentPlugin");
var hoverKey = new PluginKey("commentHoverPlugin");
var Comment = Mark2.create({
  name: "comment",
  exitable: true,
  inclusive: false,
  excludes: "",
  addOptions() {
    return {
      HTMLAttributes: {
        class: "comment"
      },
      onCommentActivated: void 0,
      onCommentDeactivated: void 0,
      visibleComments: void 0
    };
  },
  addAttributes() {
    return {
      commentId: {
        default: ""
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "span[commentId]"
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const commentId = HTMLAttributes.commentId;
    if (commentId != null && this.options.visibleComments != null && !this.options.visibleComments.has(commentId)) {
      return ["span", {}, 0];
    }
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: `${this.options.HTMLAttributes.class}`,
        commentid: commentId,
        ...HTMLAttributes.optimisticId && {
          optimisticid: HTMLAttributes.optimisticId
        }
      }),
      0
    ];
  },
  addCommands() {
    const { type } = this;
    return {
      // Adds a new decoration to the selected range. The decoration applies a highlight effect.
      // The onCommentActivated option is called in view.update() when a decoration is detected.
      // The decoration is removed automatically when the selection changes.
      setNewComment: () => ({ state, tr, dispatch }) => {
        if (state.selection.from === state.selection.to) return false;
        tr.setMeta(commentKey, {
          add: {
            optimisticId: uuid(),
            from: state.selection.from,
            to: state.selection.to
          }
        }).setMeta("addToHistory", false);
        tr.setSelection(
          TextSelection.near(tr.doc.resolve(state.selection.to))
        );
        return dispatch?.(tr);
      },
      unsetNewComment: () => ({ tr, dispatch, editor }) => {
        const activeNewComment = commentKey.getState(editor.state);
        if (activeNewComment.children.length > 0) {
          tr.setMeta(commentKey, { removeAll: true }).setMeta(
            "addToHistory",
            false
          );
          if (this.options.onCommentActivated) {
            setTimeout(() => {
              this.options.onCommentActivated?.(null);
            }, 0);
          }
        }
        return dispatch?.(tr);
      },
      setComment: (commentId, range) => ({ state, tr, dispatch }) => {
        const attributes = { commentId };
        state.doc.nodesBetween(range.from, range.to, (node, pos) => {
          const trimmedFrom = Math.max(pos, range.from);
          const trimmedTo = Math.min(pos + node.nodeSize, range.to);
          const someHasMark = node.marks.find((mark) => mark.type === type);
          if (someHasMark) {
            node.marks.forEach((mark) => {
              if (type === mark.type) {
                tr.addMark(
                  trimmedFrom,
                  trimmedTo,
                  type.create({
                    ...mark.attrs,
                    ...attributes
                  })
                );
              }
            });
          } else {
            tr.addMark(trimmedFrom, trimmedTo, type.create(attributes));
          }
        });
        tr.setMeta("addToHistory", false);
        return dispatch?.(tr);
      },
      unsetComment: (commentId) => ({ tr, dispatch }) => {
        if (!commentId) return false;
        const commentMarksWithRange = [];
        tr.doc.descendants((node, pos) => {
          const commentMark = node.marks.find(
            (mark) => mark.type.name === "comment" && mark.attrs.commentId === commentId
          );
          if (!commentMark) return;
          commentMarksWithRange.push({
            mark: commentMark,
            range: {
              from: pos,
              to: pos + node.nodeSize
            }
          });
        });
        commentMarksWithRange.forEach(({ mark, range }) => {
          tr.removeMark(range.from, range.to, mark);
        });
        tr.setMeta("addToHistory", false);
        return dispatch?.(tr);
      }
    };
  },
  addProseMirrorPlugins() {
    let isActive = false;
    const { editor, options } = this;
    const { onCommentActivated, onCommentHovered, visibleComments } = options;
    function show(activeComment) {
      isActive = true;
      if (activeComment.newCommentRange == null && visibleComments != null && !visibleComments.has(activeComment.id)) {
        console.log("comment is not visible, not activating");
        return;
      }
      onCommentActivated?.(activeComment);
    }
    function hide() {
      if (isActive) {
        isActive = false;
        editor.commands.unsetNewComment();
        onCommentActivated?.(null);
      }
    }
    return [
      ...onCommentHovered ? [
        new Plugin({
          key: hoverKey,
          props: {
            handleDOMEvents: {
              mouseover(view, event) {
                const pos = view.posAtDOM(event.target, 0);
                const node = view.state.doc.nodeAt(pos);
                const mark = node?.marks.find(
                  (mark2) => mark2.type === view.state.schema.marks.comment
                );
                if (mark) {
                  onCommentHovered?.({ id: mark.attrs.commentId });
                } else {
                  onCommentHovered?.(null);
                }
              }
            }
          }
        })
      ] : [],
      new Plugin({
        key: commentKey,
        view() {
          return {
            update(view) {
              const decos = commentKey.getState(view.state).find(view.state.selection.to);
              let activeComment;
              if (decos.length > 0) {
                const deco = decos[0];
                const id = deco.type.attrs.optimisticId;
                const range = { from: deco.from, to: deco.to };
                if (id) {
                  activeComment = {
                    newCommentRange: range,
                    id
                  };
                }
              }
              if (activeComment) {
                show(activeComment);
              }
            }
          };
        },
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(tr, set) {
            if (isRemoteTransaction(tr)) {
              const mapping = recreateTransform(
                tr.before,
                tr.doc,
                true,
                false
              ).mapping;
              return set.map(mapping, tr.doc);
            } else {
              set = set.map(tr.mapping, tr.doc);
            }
            const action = tr.getMeta(commentKey);
            if (action && action.add) {
              const { optimisticId, from, to } = action.add;
              const deco = Decoration.inline(from, to, {
                optimisticId,
                class: "comment"
              });
              set = set.add(tr.doc, [deco]);
            } else if (action && action.removeAll) {
              set = set.remove(set.find());
            }
            return set;
          }
        },
        props: {
          // @ts-ignore
          decorations(state) {
            return this.getState(state);
          },
          handleClick(view, pos) {
            const $pos = view.state.doc.resolve(pos);
            let marks = $pos.marks();
            if (!marks.length) {
              const node = view.state.doc.nodeAt(pos);
              if (node?.text?.length === 1 && node?.marks.length) {
                marks = node.marks;
              } else if (pos > 0) {
                const beforeNode = view.state.doc.nodeAt(pos - 1);
                if (beforeNode?.text?.length === 1 && beforeNode?.marks.length) {
                  marks = beforeNode.marks;
                }
              }
            }
            let activeComment;
            if (marks.length > 0) {
              const mark = marks.find(
                (mark2) => mark2.type === view.state.schema.marks.comment
              );
              const id = mark?.attrs.commentId;
              if (id) {
                activeComment = { id };
              }
            }
            if (activeComment) {
              show(activeComment);
            } else {
              hide();
            }
          }
        }
      })
    ];
  }
});

// src/builder.ts
var getBuilderExtensions = (options) => {
  const {
    provider,
    type,
    user,
    comments: { onCommentActivated, onCommentHovered },
    signature: { onSignatureClick, signatureBoxComponent }
  } = options;
  const base = [
    UniqueId,
    Collaboration.configure({
      fragment: provider.document.getXmlFragment("default")
    }),
    CollaborationCursor.configure({
      provider,
      user: {
        name: user?.fullName ?? "Anonymous",
        color: uniqolor(user?.firstName ?? "Anonymous").color
      }
    }),
    StarterKit.configure({
      history: false,
      dropcursor: {
        color: "#6398de",
        width: 2
      },
      code: false,
      codeBlock: false
    }),
    TextAlign.configure({
      types: ["heading", "paragraph"],
      defaultAlignment: "justify"
    }),
    Placeholder.configure({
      emptyEditorClass: "is-editor-empty",
      placeholder: "Start typing..."
    }),
    Highlight,
    Comment.configure({
      onCommentActivated,
      onCommentHovered
    }),
    ImageResize.configure({
      HTMLAttributes: {
        class: "w-full flex justify-center items-center"
      }
    }),
    BubbleMenu,
    Underline
  ];
  if (type === "letter" && onSignatureClick != null) {
    base.push(
      Signature.configure({
        onClick: onSignatureClick,
        renderer: signatureBoxComponent
      })
    );
  }
  return base;
};

// src/portal.ts
import { Collaboration as Collaboration2 } from "@tiptap/extension-collaboration";
import { StarterKit as StarterKit2 } from "@tiptap/starter-kit";
import { TextAlign as TextAlign2 } from "@tiptap/extension-text-align";
import { Placeholder as Placeholder2 } from "@tiptap/extension-placeholder";
import { Highlight as Highlight2 } from "@tiptap/extension-highlight";
import { ImageResize as ImageResize2 } from "tiptap-extension-resize-image";
import { BubbleMenu as BubbleMenu2 } from "@tiptap/extension-bubble-menu";
import { Underline as Underline2 } from "@tiptap/extension-underline";
var getPortalExtensions = (options) => {
  const {
    provider,
    comments: { onCommentActivated, onCommentHovered, visibleCommentIds },
    signature: {
      letterActionId,
      onSignatureAdded,
      signatureBoxComponent,
      disabled: signatureDisabled
    }
  } = options;
  const base = [
    UniqueId,
    Collaboration2.configure({
      fragment: provider.document.getXmlFragment("default")
    }),
    StarterKit2.configure({
      history: false,
      dropcursor: {
        color: "#6398de",
        width: 2
      }
    }),
    TextAlign2.configure({
      types: ["heading", "paragraph"],
      defaultAlignment: "justify"
    }),
    Placeholder2.configure({
      emptyEditorClass: "is-editor-empty",
      placeholder: "Start typing..."
    }),
    Highlight2,
    Comment.configure({
      onCommentActivated,
      onCommentHovered,
      visibleComments: new Set(visibleCommentIds)
    }),
    Signature.configure({
      disabled: signatureDisabled,
      letterActionId: letterActionId.toString(),
      onSignatureAdded,
      renderer: signatureBoxComponent
    }),
    ImageResize2.configure({
      HTMLAttributes: {
        class: "w-full flex justify-center items-center"
      }
    }),
    BubbleMenu2,
    Underline2
  ];
  return base;
};

// src/hocuspocus.ts
import TextAlign3 from "@tiptap/extension-text-align";
import StarterKit3 from "@tiptap/starter-kit";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import Underline3 from "@tiptap/extension-underline";
import Image from "tiptap-extension-resize-image";
import Highlight3 from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
var getHocuspocusExtensions = () => [
  UniqueId,
  StarterKit3,
  TextAlign3,
  Highlight3,
  BulletList,
  OrderedList,
  Underline3,
  CommentHighlight.configure({
    renderHTML() {
      return ["", {}, 0];
    }
  }),
  Comment.configure({
    renderHTML() {
      return ["", {}, 0];
    }
  }),
  Signature.configure({
    renderHTML: (node) => {
      if (node.attrs.src == "") return ["div", {}];
      return [
        "img",
        {
          src: node.attrs.src,
          width: "250px",
          height: "80px",
          style: "display: block;"
        }
      ];
    }
  }),
  Image,
  Link
];
export {
  CommentHighlight,
  Signature,
  getBuilderExtensions,
  getHocuspocusExtensions,
  getPortalExtensions,
  useCollaborationProvider,
  useComments,
  useRerenderOnEditorChange,
  useRerenderOnYObjectChange
};
