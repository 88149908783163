import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks/dataField";
import { useOnboarding } from "@/providers/onboardingProvider";

export const Judging = () => {
  const { currentOnboardingNodeId, currentOnboardingNodeName } =
    useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading={currentOnboardingNodeName}>
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        The Judging criteria indicates that you have been invited and
        participated in an industry event as a judge for the work of others in
        your field as demonstration of your expertise.
      </p>
      <Field
        id="supportingDocuments"
        label="Provide supporting evidence for the judging events you participated in"
        description={
          <>
            <div className="flex flex-col gap-1">
              <span>
                These should include only professional events and exclude events
                that were internal to a company, participants were undergraduate
                or students only.
              </span>
              <span>
                Examples to include:
                <ul className="list-disc flex flex-col gap-1 pl-3 mt-1">
                  <li>Email invitations and confirmations of judging</li>
                  <li>
                    Full-page screenshots of the event that indicate you are a
                    judge
                  </li>
                  <li>
                    Confirmation of reviewing scholarly papers, startup or
                    technology company diligence for institutional investors
                  </li>
                </ul>
              </span>
            </div>
          </>
        }
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="Please leave any additional comments you'd like the Lighthouse team to review as it relates to this criteria"
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="judging"
          name="judging"
          description="Add a new Judging criteria if you'd like to add even more evidence of events you have judged in the past."
        />
      )}
    </OnboardingForm>
  );
};
