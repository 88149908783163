import { Button } from "@/components/button";
import { FormModal } from "@/components/modal";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// Simplified store that just tracks if the modal has been shown before
type SupportLettersModalStateType = {
  hasBeenShown: boolean;
  markAsShown: () => void;
};

export const useSupportLettersModalStore = create(
  persist<SupportLettersModalStateType>(
    (set) => ({
      hasBeenShown: false,
      markAsShown: () => set({ hasBeenShown: true }),
    }),
    {
      name: "lighthouse-support-letters-modal",
      storage: createJSONStorage(() => localStorage),
      merge(persistedState, currentState) {
        return {
          hasBeenShown:
            (persistedState as any).hasBeenShown ?? currentState.hasBeenShown,
          markAsShown: currentState.markAsShown,
        };
      },
    }
  )
);

// Controlled version of the modal that can be opened from outside
export const SupportLettersIntroControlledModal = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  // Get state from Zustand to track if modal has been shown before
  const hasBeenShown = useSupportLettersModalStore(
    (state) => state.hasBeenShown
  );
  const markAsShown = useSupportLettersModalStore((state) => state.markAsShown);

  // Close modal and mark as shown if needed
  const closeModal = () => {
    onOpenChange(false);
    if (!hasBeenShown) {
      markAsShown();
    }
  };

  return (
    <FormModal
      open={open}
      onOpenChange={(val) => {
        if (!val) {
          closeModal();
        } else {
          onOpenChange(true);
        }
      }}
      title="Intro to Support letters step"
    >
      <div className="flex flex-col gap-2 pr-12">
        <p className="text-sm text-grey-300 font-normal">
          Support letters are recommendations from others that highlight your
          skills and achievements. These letters help support your application
          by focusing on specific areas, like your job experience or membership
          in an organization.
        </p>

        <p className="text-sm text-grey-300 font-normal">
          Once you submit this step, our team will draft support letters for you
          and you will be able to review and sign them in your dashboard.
        </p>
      </div>
      <div className="w-full flex justify-end pr-8 mt-4">
        <Button variant="primary" onClick={closeModal}>
          Got it
        </Button>
      </div>
    </FormModal>
  );
};
