import { cn } from "@/lib/cn";
import { useResizeObserver } from "@/lib/hooks/resizeObserver";
import { useEffect, useRef, useState } from "react";
import { graphql } from "@/lib/graphql";
import {
  ApprovedIcon,
  DeniedIcon,
  InFinalReviewIcon,
  InProgressIcon,
  InUscisReviewIcon,
  PendingDocumentsIcon,
  RfeIcon,
  SubmittedIcon,
} from "./icons/dashboardTimeline";

type CaseStatus = ReturnType<typeof graphql.scalar<"ExternalStatus">>;

export type TimelineStep = {
  id: CaseStatus;
  name: string;
  icon: React.ReactNode;
};

const baseTimelineSteps: TimelineStep[] = [
  {
    id: "pending_documents",
    name: "Pending onboarding submission",
    icon: <PendingDocumentsIcon />,
  },
  {
    id: "in_progress",
    name: "In progress",
    icon: <InProgressIcon />,
  },
  {
    id: "in_final_review",
    name: "In final review",
    icon: <InFinalReviewIcon />,
  },
  {
    id: "submitted",
    name: "Submitted",
    icon: <SubmittedIcon />,
  },
  {
    id: "in_review_uscis",
    name: "Application In USCIS review",
    icon: <InUscisReviewIcon />,
  },
];

const decisionSteps: TimelineStep[] = [
  {
    id: "approved",
    name: "USCIS Outcome",
    icon: <ApprovedIcon />,
  },
  {
    id: "denied",
    name: "USCIS Outcome",
    icon: <DeniedIcon />,
  },
  {
    id: "rfe",
    name: "USCIS Outcome",
    icon: <RfeIcon />,
  },
];

const calculateWidths = (
  startRef: React.RefObject<HTMLDivElement | null>,
  endRef: React.RefObject<HTMLDivElement | null>,
  selectedStepRef: React.RefObject<HTMLDivElement | null>,
  allSteps: TimelineStep[],
  currentStepId: string
): {
  blackTimelineWidth: number;
  grayTimelineWidth: number;
} => {
  if (allSteps.length === 0)
    return { blackTimelineWidth: 0, grayTimelineWidth: 0 };

  if (currentStepId === allSteps[allSteps.length - 1].id) {
    return {
      blackTimelineWidth:
        (selectedStepRef.current?.offsetLeft ?? 0) -
        (startRef.current?.offsetLeft ?? 0),
      grayTimelineWidth: 0,
    };
  }

  if (currentStepId === allSteps[0].id) {
    return {
      blackTimelineWidth: 0,
      grayTimelineWidth:
        (endRef.current?.offsetLeft ?? 0) - (startRef.current?.offsetLeft ?? 0),
    };
  }

  return {
    blackTimelineWidth:
      (selectedStepRef.current?.offsetLeft ?? 0) -
      (startRef.current?.offsetLeft ?? 0),
    grayTimelineWidth:
      (endRef.current?.offsetLeft ?? 0) - (startRef.current?.offsetLeft ?? 0),
  };
};

const Timeline = (props: {
  allSteps: TimelineStep[];
  currentStepId: string;
}) => {
  const { allSteps, currentStepId } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const startRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const selectedStepRef = useRef<HTMLDivElement>(null);

  const [blackTimelineWidth, setBlackTimelineWidth] = useState(0);
  const [grayTimelineWidth, setGrayTimelineWidth] = useState(0);

  useEffect(() => {
    const widths = calculateWidths(
      startRef,
      endRef,
      selectedStepRef,
      allSteps,
      currentStepId
    );
    setBlackTimelineWidth(widths.blackTimelineWidth);
    setGrayTimelineWidth(widths.grayTimelineWidth);
  }, [allSteps, currentStepId]);

  useResizeObserver(containerRef);

  useEffect(() => {
    if (selectedStepRef.current == null) return;

    // only scroll horizontally if the step is not the first one
    if (currentStepId !== allSteps[0].id) {
      selectedStepRef.current.scrollIntoView({
        behavior: "instant",
        inline: "start",
        block: "nearest",
      });
    }
  }, [currentStepId]);

  return (
    <div className="flex flex-col gap-1 pb-5 relative" ref={containerRef}>
      <div className="flex flex-row gap-3 items-center w-full">
        {allSteps.map((step, index) => (
          <div
            className="flex flex-col gap-1 p-1"
            ref={index === 0 ? startRef : null}
            key={`parent-${index}`}
          >
            <div
              key={index}
              ref={
                step.id === currentStepId
                  ? selectedStepRef
                  : step.id === allSteps[allSteps.length - 1].id
                    ? endRef
                    : null
              }
              className={cn(
                "rounded-full border-grey-400 w-[18px] h-[18px] bg-grey-700 z-20 flex items-center justify-center",
                index === 0 && "translate-x-[5px]",
                "transition-background-color ease-in-out duration-500",
                "transition-border ease-in-out duration-500",
                currentStepId === step.id &&
                  "bg-violet shadow-case-status-selected",
                currentStepId !== step.id && "shadow-case-status"
              )}
            >
              {step.icon}
            </div>
            <div
              className={cn(
                "pr-5 py-1 text-grey-300 text-nowrap text-sm",
                currentStepId === step.id && "text-grey-200"
              )}
            >
              {step.name}
            </div>
          </div>
        ))}
      </div>

      {allSteps.length > 0 && (
        <>
          <div
            style={{
              position: "absolute",
              top: `${(selectedStepRef.current?.offsetTop ?? 0) + (selectedStepRef.current?.offsetHeight ?? 0) / 2 - 1}px`,
              left: `${(startRef.current?.offsetLeft ?? 0) + 1}px`,
              width: `${Math.max(blackTimelineWidth, 10)}px`,
              border: "1px solid #888888",
              opacity: 1,
              zIndex: 11,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: `${(selectedStepRef.current?.offsetTop ?? 0) + (selectedStepRef.current?.offsetHeight ?? 0) / 2 - 1}px`,
              left: `${(startRef.current?.offsetLeft ?? 0) + 1}px`,
              width: `${grayTimelineWidth}px`,
              border: "1px dashed #CDCFD3",
              zIndex: 10,
            }}
          />
        </>
      )}
    </div>
  );
};

export const CaseStatusTimeline = (props: {
  currentCaseStatus: CaseStatus;
  classname?: string;
  scrollable?: boolean;
}) => {
  const { currentCaseStatus, scrollable = false } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const decisionStepsFiltered = decisionSteps.filter(
    (x) => x.id === currentCaseStatus
  );

  // only show one USCIS outcome
  const allSteps = [
    ...baseTimelineSteps,
    ...(decisionStepsFiltered.length > 0
      ? decisionStepsFiltered
      : [decisionSteps[0]]),
  ];

  return (
    <div
      className={cn(
        "overflow-hidden mr-10 scrollbar-hide",
        scrollable && "overflow-x-scroll",
        props.classname
      )}
      ref={containerRef}
    >
      <Timeline allSteps={allSteps} currentStepId={currentCaseStatus} />
    </div>
  );
};
