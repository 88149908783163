import { Button } from "@/components/button";
import { LoadingOverlay } from "@/components/loadingOverlay";

import { Modal } from "@/components/modal";
import { TextEditor } from "@/components/textEditor";
import { SupportLettersTutorialModal } from "@/components/tutorials/supportLettersTutorial";
import { ResultOf } from "@/lib/graphql";
import { useUserContext } from "@/lib/hooks/user";
import { approveLetterAction, getLetterActionById } from "@/lib/queries";
import {
  EditorContextProvider,
  useEditorContext,
} from "@/providers/editorProvider";
import { useRerenderOnEditorChange } from "@lighthouse/editor";
import { ArrowRightIcon, InfoCircledIcon } from "@radix-ui/react-icons";

import { useEffect, useState } from "react";
import { FaRegCircleCheck, FaSignature } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { useMutation } from "urql";
import { LetterTimeline } from "../timeline";
import { MarkRoundIcon } from "@/components/icons/markRound";

const SuccessModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Letter Signed!"
      fitContent
      contentClassName="w-[550px] z-10 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Your signature has been successfully added to the letter. The letter
          is now available to the applicant and the Lighthouse team. You can
          close this window.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export const FailiureModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Oops! Something Went Wrong"
      fitContent
      contentClassName="w-[550px] z-10 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Looks like something went wrong. Please try again.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export const InformationModal = (props: {
  isBeneficiary: boolean;
  beneficiaryName: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, isBeneficiary, beneficiaryName, setOpen } = props;

  const formattedBeneficiaryName =
    beneficiaryName[beneficiaryName.length - 1] == "s"
      ? beneficiaryName + "'"
      : beneficiaryName + "'s";

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Signing a letter"
      fitContent
      contentClassName="w-[650px] max-w-[80%] z-10 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        {!isBeneficiary && (
          <p className="text-sm text-grey-300">
            Your signature is needed regarding this letter in support of{" "}
            {formattedBeneficiaryName} upcoming work visa application.
          </p>
        )}

        <p className="text-sm text-grey-300">
          Once signed, this letter will be submitted to USCIS, the government
          agency responsible for citizenship and immigration.
        </p>

        <p className="text-sm text-grey-200">A few key points about USCIS:</p>

        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-start justify-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              They prefer a grandiose tone in these letters. Although this may
              seem unusual, it is standard practice and enhances the likelihood
              of approval.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Since USCIS may not be familiar with the specific industry, it is
              crucial to clearly outline the applicant's achievements and
              expertise in related or adjacent fields.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Processing times can be lengthy and the stakes are high. Prompt
              review is highly recommended.
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() => setOpen(false)}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ScrollDownPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <div className="flex flex-row items-center bg-grey-600 px-4 py-3 gap-2.5 rounded-lg shadow-border max-w-[400px]">
        <FaSignature className="w-6 h-6" color="#3a87c2" />

        <span className="text-sm text-grey-300">
          Scroll down to find the spot where you can sign the document
        </span>

        <button onClick={() => setOpen(false)}>
          <FiX width={10} className="text-grey-400" />
        </button>
      </div>
    )
  );
};

export const SubmitFailedPopup = (props: {
  onSubmit: () => void;
  letterActionId: number;
  status: string;
  hidden: boolean;
}) => {
  const { onSubmit, letterActionId, status, hidden } = props;

  const { editor } = useEditorContext();

  const [isSigned, setIsSigned] = useState(false);

  const checkForSignature = () => {
    if (editor == null) return setIsSigned(false);

    const signedNode = editor.$node("signature", {
      letterActionId: letterActionId.toString(),
      signed: true,
    });

    setIsSigned(signedNode != null);
  };

  useRerenderOnEditorChange(editor, checkForSignature);

  return (
    !hidden &&
    isSigned &&
    status !== "approved" && (
      <div className="z-10 flex flex-col bg-grey-200 p-4 gap-2.5 absolute rounded-lg bottom-4 right-4 shadow-action-button w-[300px]">
        <div className="flex flex-row items-center gap-1.5">
          <span className="text-sm text-grey-800">Something went wrong!</span>
        </div>

        <span className="text-sm text-grey-500 mb-2">
          There was an issue submitting your signature. Please try again.
        </span>

        <Button onClick={onSubmit} variant="secondary">
          Submit
        </Button>
      </div>
    )
  );
};

export const LetterSignatureEditor = (props: {
  data: ResultOf<typeof getLetterActionById>;
  reviewed?: boolean;
}) => {
  const { data, reviewed } = props;

  const { loggedInUserId, userEntity } = useUserContext();

  const [informationModalOpen, setInformationModalOpen] = useState(
    data?.getLetterAction.status === "sent" && !reviewed
  );
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const userName =
    userEntity != null
      ? userEntity.firstName + " " + userEntity.lastName
      : "You";

  const [status, setStatus] = useState<string>(data.getLetterAction.status);

  const approveLetterActionMutation = useMutation(approveLetterAction)[1];

  const approve = async () => {
    setLoading(true);

    const res = await approveLetterActionMutation({
      letterActionId: data.getLetterAction.id,
    });

    setLoading(false);

    if (res.error) {
      setFailureModalOpen(true);
      return;
    }

    setStatus("approved");
    setSuccessModalOpen(true);
  };

  // todo (r614): refactor this godforsaken hack
  useEffect(() => {
    if (reviewed) {
      let attempts = 0;
      const maxAttempts = 20;
      const interval = setInterval(() => {
        const signatureButton = document.querySelector(
          `[data-signature-button-id="${data.getLetterAction.id}"]`
        );

        console.log(signatureButton);

        if (signatureButton) {
          (signatureButton as HTMLButtonElement).click();
          clearInterval(interval);
        }

        attempts++;
        if (attempts >= maxAttempts) {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [reviewed]);

  return (
    loggedInUserId != null && (
      <div className="w-full h-full bg-grey-700">
        <SupportLettersTutorialModal />

        <InformationModal
          isBeneficiary={data.getLetterAction.recipientType === "beneficiary"}
          beneficiaryName={data.getLetterAction.beneficiaryName}
          open={informationModalOpen}
          setOpen={setInformationModalOpen}
        />
        <SuccessModal open={successModalOpen} setOpen={setSuccessModalOpen} />
        <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />
        <LoadingOverlay isLoading={loading} />

        {data?.getLetterAction.letterId != null && (
          <EditorContextProvider
            letterId={data.getLetterAction.letterId}
            userId={loggedInUserId.toString()}
            userName={userName}
            commentUserIdWhitelist={[]}
            mode="sign"
            letterActionId={data.getLetterAction.id}
            onSignatureAdded={approve}
            disableSignatures={status === "approved"}
            signatureLetterActionId={data.getLetterAction.id}
            onCommentAdded={() => {}}
          >
            <div className="relative w-full h-full flex flex-col items-center overflow-hidden">
              <SubmitFailedPopup
                onSubmit={approve}
                letterActionId={data.getLetterAction.id}
                status={status}
                hidden={loading}
              />

              <div className="w-full flex flex-row items-center px-6 h-20 mb-3 flex-shrink-0">
                <div className="text-sm text-grey-200 w-1/3">
                  {data.getLetterAction.letterName}
                </div>

                <LetterTimeline status={"signature"} />

                <div className="flex flex-row items-center gap-3 ml-auto w-1/3 justify-end">
                  <button
                    onClick={() => setInformationModalOpen(true)}
                    className="bg-grey-200 shadow-action-button h-8 w-8 rounded-full flex items-center justify-center"
                  >
                    <InfoCircledIcon color="white" width={20} />
                  </button>
                </div>
              </div>

              <div className="flex flex-row overflow-hidden w-full">
                <div className="w-1/4 pr-6">
                  {data.getLetterAction.additionalNotes != null &&
                    data.getLetterAction.additionalNotes.trim().length > 0 && (
                      <div className="flex flex-col px-2 w-full bg-white rounded-lg shadow-border">
                        <div className="flex flex-row items-center gap-2 border-b border-grey-700 pt-4 pb-2 px-2">
                          <MarkRoundIcon width={20} height={20} />
                          <span className="text-sm text-grey-300">
                            Lighthouse Notes
                          </span>
                        </div>
                        <div className="flex flex-col gap-2 pt-2 pb-4 px-2">
                          <div
                            className="text-[13px] text-grey-300"
                            dangerouslySetInnerHTML={{
                              __html: data.getLetterAction.additionalNotes,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                </div>

                <div className="flex flex-col gap-4 overflow-hidden pb-0 w-3/5 justify-center min-w-[550px]">
                  {status === "approved" && (
                    <div className="flex flex-row items-center gap-4 bg-grey-600 shadow-bubble p-5 w-full rounded-lg text-sm text-grey-300">
                      <div className="h-9 w-9 bg-[#D9D9D9] rounded-full flex items-center justify-center text-grey-300 text-lg">
                        <FaRegCircleCheck />
                      </div>

                      <span>You have signed this letter.</span>
                    </div>
                  )}

                  <div className="overflow-y-auto no-scrollbar pb-10 w-full">
                    <TextEditor
                      key={data.getLetterAction.letterId}
                      disabled={status === "approved"}
                      size="sm"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="w-1/4 flex flex-col gap-4 px-2">
                  {status !== "approved" && status === "sent" && (
                    <ScrollDownPopup />
                  )}
                </div>
              </div>
            </div>
          </EditorContextProvider>
        )}
      </div>
    )
  );
};
