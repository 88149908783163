import {
  createOnboardingNodeMutation,
  deleteOnboardingNodeMutation,
  getOnboardingNode,
} from "@/lib/queries";
import { useOnboarding } from "@/providers/onboardingProvider";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { useMutation, useQuery } from "urql";
import { TrashIcon } from "./icons/trash";

export const CreateAdditionalWorkExperienceNodeOption = (props: {
  onboardingNodeId: number;
  type: string;
  name: string;
  description: string;
}) => {
  const { onboardingNodeId, type, name, description } = props;

  const {
    onboardingId,
    groupNodes,
    switchNode,
    nodeStatus,
    refetchOnboarding,
  } = useOnboarding();

  const [{ data }] = useQuery({
    query: getOnboardingNode,
    variables: {
      id: onboardingNodeId,
    },
  });

  const deleteNodeMutation = useMutation(deleteOnboardingNodeMutation)[1];

  const createNodeMutation = useMutation(createOnboardingNodeMutation)[1];

  const addNewNode = async () => {
    if (data == null) return;

    const res = await createNodeMutation({
      input: {
        nodeType: type,
        onboardingId: onboardingId,
        orderIndex: data.onboardingNode.orderIndex + 1,
      },
    });

    if (res.error) {
      console.error(res.error);
      alert("An error occurred. Please try again.");
      return;
    }

    refetchOnboarding();
  };

  const deleteNode = async (nodeId: number) => {
    const res = await deleteNodeMutation({ id: nodeId });

    if (res.error) {
      console.error(res.error);
      alert("An error occurred. Please try again.");
      return;
    }
  };

  const customerCreatedNodes = groupNodes.filter(
    (node) => node.type === type && node.customerCreated
  );

  if (
    data == null ||
    data.onboardingNode.customerCreated ||
    ["submitted_for_review", "approved"].includes(nodeStatus ?? "")
  )
    return;

  return (
    <div className="rounded-md bg-grey-700 p-3 flex flex-col gap-2 mr-12">
      <p className="text-xs text-grey-200">{description}</p>

      <div className="flex flex-col gap-1.5">
        {customerCreatedNodes.map((node) => (
          <div key={node.id} className="flex flex-row w-full gap-3">
            <div className="flex flex-row justify-between flex-1 bg-[#DEEDDC] text-positive rounded px-2 py-1 text-[12px]">
              <div className="flex flex-row gap-1 items-center">
                <CheckIcon className="fill-positive w-[12px] h-[12px]" />
                <span>New {name} was added as a next step</span>
              </div>
              <button
                className="font-medium"
                onClick={() => switchNode(node.id)}
              >
                Preview
              </button>
            </div>

            <button
              onClick={() => deleteNode(node.id)}
              className="flex-shrink-0"
            >
              <TrashIcon className="fill-grey-150 w-[16px] h-[16px]" />
            </button>
          </div>
        ))}
      </div>

      <button
        onClick={addNewNode}
        className="flex flex-row items-center gap-1 text-xs text-blue"
      >
        <PlusCircledIcon width={12} height={12} />
        <span>Add next {name}</span>
      </button>
    </div>
  );
};
