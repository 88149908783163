import { useEditorContext } from "@/providers/editorProvider";
import { Editor, EditorContent, Extension, useEditor } from "@tiptap/react";
import { useEffect, useMemo } from "react";
import { Instance, Props } from "tippy.js";
import { FaRegCommentDots } from "react-icons/fa6";

import { useRef } from "react";
import { BubbleMenu, isNodeSelection } from "@tiptap/react";
import { Button } from "./button";

import { cn } from "@/lib/cn";
import StarterKit from "@tiptap/starter-kit";
import { CommentComposerPopover } from "./commentComposerPopover";
import { TiptapDebugViewer } from "./tiptapDebug";
import { Placeholder } from "@tiptap/extension-placeholder";

const HighlightMenu = (props: { editor: Editor }) => {
  const instanceRef = useRef<Instance<Props> | null>(null);
  const { editor } = props;

  const doBlur = (instance: Instance) => {
    instance.hide();
  };

  const createComment = () => {
    if (editor == null) return;

    // Create the new comment decoration
    // This will trigger the comment extension's plugin which will call onCommentActivated
    editor.commands.setNewComment();
    if (instanceRef.current) {
      doBlur(instanceRef.current);
    }
  };

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 200,
        onClickOutside: doBlur,
        moveTransition: "transform 0.15s ease-out",
        onCreate: (val) => {
          instanceRef.current = val;
        },
      }}
      shouldShow={({ editor, state }) =>
        !editor.isActive("image") &&
        !state.selection.empty &&
        !isNodeSelection(state.selection)
      }
    >
      <Button
        variant="primary"
        onClick={createComment}
        className="px-3 py-1.5 flex items-center justify-center text-white flex-row gap-2 text-nowrap"
      >
        <FaRegCommentDots className="w-full" />
        <span>Suggest Changes</span>
      </Button>
    </BubbleMenu>
  );
};

const mdClass =
  "prose prose-md leading-[1.5em] [&+p]:mt-[1em] [&_ol]:mt-[1em] [&_ul]:mt-[1em] [&_ol+p]:mt-[1em] [&_ul+p]:mt-[1em] [&+blockquote]:mt-[0.5em] [&_blockquote+blockquote]:mt-[1em]";

const smallClass =
  "prose prose-sm leading-[1.5em] [&+p]:mt-[0.75em] [&_ol]:mt-[0.75em] [&_ul]:mt-[0.75em] [&_ol+p]:mt-[0.75em] [&_ul+p]:mt-[0.75em] [&+blockquote]:mt-[0.75em] [&_blockquote+blockquote]:mt-[0.75em]";

export const TextEditorRenderer = (props: {
  disabled?: boolean;
  size?: "sm" | "md";
}) => {
  const { disabled = false, size = "sm" } = props;

  const {
    provider,
    extensions,
    ready,
    mode,
    setEditor,
    activeComment,
    letterActionId,
    comments,
  } = useEditorContext();

  const visibleCommentsIds = (comments ?? []).map((c) => c.id.toString());

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class: size === "sm" ? smallClass : mdClass,
        },
      },
      extensions: extensions ?? [],
      editable: false,
      shouldRerenderOnTransaction: true,
    },
    [visibleCommentsIds]
  );

  useEffect(() => {
    // Only set the editor if it exists and isn't destroyed
    if (editor && !editor.isDestroyed) {
      setEditor(editor);
    }
  }, [editor, setEditor]);

  return (
    provider != null &&
    ready && (
      <div>
        {editor != null && mode === "review" && !disabled && (
          <HighlightMenu editor={editor} />
        )}
        {editor != null && mode === "review" && !disabled && (
          <CommentComposerPopover
            editor={editor}
            activeComment={activeComment}
            onDiscard={() => {
              if (editor) {
                editor.commands.unsetNewComment();
              }
            }}
            id={letterActionId}
            open={!!activeComment?.newCommentRange}
          />
        )}
        <EditorContent
          editor={editor}
          className="h-full w-full focus:outline-none focus:ring-0 text-justify"
        />
        {editor != null && (
          <TiptapDebugViewer editor={editor} provider={provider} />
        )}
      </div>
    )
  );
};

export const RichTextEditor = (props: {
  content?: string;
  onUpdate?: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  editorClassName?: string;
}) => {
  const {
    onUpdate,
    readOnly = false,
    content,
    placeholder,
    className,
    editorClassName,
  } = props;

  const editorRef = useRef<Editor>(null);
  const extensions: Extension[] = useMemo(
    () => [
      StarterKit.configure({
        history: false,
        dropcursor: {
          color: "#6398de",
          width: 2,
        },
      }),
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder: placeholder ?? "Start typing...",
      }),
      BubbleMenu as unknown as Extension,
    ],
    []
  );

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class: cn(
            "prose prose-sm leading-[1.5em] [&+p]:mt-[1em] [&_ol]:mt-[1em] [&_ul]:mt-[1em] [&_ol+p]:mt-[1em] [&_ul+p]:mt-[1em] [&+blockquote]:mt-[0.5em] [&_blockquote+blockquote]:mt-[1em]",
            editorClassName
          ),
        },
      },
      content: content ?? "",
      extensions: extensions ?? [],
      editable: !readOnly,
      onUpdate: ({ editor }) => {
        onUpdate?.(editor.getHTML());
      },
    },
    []
  );

  useEffect(() => {
    editorRef.current = editor;

    return () => {
      if (editor != null && !editor.isDestroyed) {
        editor.destroy();
      }
      editorRef.current = null;
    };
  }, [editor, editorRef]);

  return (
    <div
      className={cn(
        "flex flex-col rounded-md shadow-border resize-none pl-3 pr-1 py-2",
        "text-sm leading-[140%] font-normal",

        className
      )}
    >
      <div className="overflow-y-auto flex-grow">
        {editor != null && (
          <EditorContent
            editor={editor}
            autoFocus={props.autoFocus}
            className={cn(
              "h-full w-full focus:outline-none focus:ring-0 p-0 m-0",
              "focus:shadow-border-active focus:outline-none",
              "disabled:bg-grey-700 disabled:text-grey-500 disabled:placeholder:text-grey-700",
              "transition-all duration-300"
            )}
          />
        )}
      </div>
    </div>
  );
};

export const TextEditor = (props: {
  disabled?: boolean;
  className?: string;
  size?: "sm" | "md";
}) => {
  const { size = "md" } = props;
  const { provider, ready } = useEditorContext();

  return (
    <div
      className={cn(
        "bg-white h-fit rounded-lg ",
        size === "md" &&
          "min-h-[800px] min-w-[825px] max-w-[1024px] p-20 shadow-modal mx-2",
        size === "sm" &&
          "min-h-[600px] min-w-[550px] max-w-[825px] p-14 shadow-modal mx-2",
        props.className
      )}
    >
      {provider != null && ready ? (
        <TextEditorRenderer disabled={props.disabled} size={props.size} />
      ) : (
        <div className="flex flex-col gap-10 animate-pulse w-full">
          <ul className="w-9/12 space-y-5">
            <li className="w-3/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-4/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-4/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-6/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>

          <ul className="w-9/12 space-y-5">
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-8/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-11/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-10/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>

          <ul className="w-9/12 space-y-5">
            <li className="w-8/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-10/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-11/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>

          <ul className="w-9/12 space-y-5">
            <li className="w-8/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-11/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-10/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>
        </div>
      )}
    </div>
  );
};
