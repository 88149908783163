import { Editor } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import * as Y from "yjs";
import { CommentYMap } from "@lighthouse/editor";
import { cn } from "@/lib/cn";
import { motion, useDragControls } from "framer-motion";
import { DragHandleDots2Icon, Cross2Icon } from "@radix-ui/react-icons";
import { HocuspocusProvider } from "@hocuspocus/provider";

type ContentView = "html" | "json" | "text";

interface ReplyData {
  id: string;
  content: string;
  userId: string;
  timestamp: number;
  internal: boolean;
  lighthouseAuthor: boolean;
}

interface CommentData {
  id: string;
  content: string;
  userId: string;
  timestamp: number;
  internal: boolean;
  resolved: boolean;
  replies: ReplyData[];
}

interface EditorContent {
  text: string;
  html: string;
  json: string;
}

export const TiptapDebugViewer = (props: {
  editor: Editor;
  provider: HocuspocusProvider;
}) => {
  const { editor, provider } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [size, setSize] = useState({ width: 800, height: 400 });
  const containerRef = useRef<HTMLDivElement>(null);
  const dragControls = useDragControls();
  const [contentView, setContentView] = useState<ContentView>("text");
  const [content, setContent] = useState<EditorContent>({
    text: editor.getText(),
    html: editor.getHTML(),
    json: JSON.stringify(editor.getJSON(), null, 2),
  });
  const [comments, setComments] = useState<CommentData[]>([]);
  const [isResizing, setIsResizing] = useState(false);
  const resizeStartPos = useRef({ x: 0, y: 0 });
  const resizeStartSize = useRef({ width: 0, height: 0 });

  // Toggle visibility with Alt/Option+D
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Handle both regular "d" with Alt key and the special "∂" character on Mac
      if ((e.altKey && e.key.toLowerCase() === "d") || e.key === "∂") {
        e.preventDefault();
        setIsVisible((prev) => !prev);
      }
    };

    // Ensure the event listener is added to the document, not window
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Handle resize functionality
  useEffect(() => {
    if (!containerRef.current) return;

    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizing) return;

      const deltaX = e.clientX - resizeStartPos.current.x;
      const deltaY = e.clientY - resizeStartPos.current.y;

      const newWidth = Math.max(300, resizeStartSize.current.width + deltaX);
      const newHeight = Math.max(200, resizeStartSize.current.height + deltaY);

      setSize({ width: newWidth, height: newHeight });
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  const handleResizeStart = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
    resizeStartPos.current = { x: e.clientX, y: e.clientY };
    resizeStartSize.current = { width: size.width, height: size.height };
  };

  useEffect(() => {
    const update = () => {
      setContent({
        text: editor.getText(),
        html: editor.getHTML(),
        json: JSON.stringify(editor.getJSON(), null, 2),
      });

      setComments(getComments());
    };

    update();

    provider.document.on("update", update);

    return () => {
      provider.document.off("update", update);
    };
  }, [editor, provider]);

  // Get comments from the provider
  const getComments = (): CommentData[] => {
    const comments = provider.document.getArray(
      "comments"
    ) as Y.Array<CommentYMap>;

    return comments.toArray() as any as CommentData[];
  };

  if (!isVisible) return null;

  const renderContent = () => {
    switch (contentView) {
      case "html":
        return content.html;
      case "json":
        return content.json;
      case "text":
        return content.text;
    }
  };

  return (
    <motion.div
      ref={containerRef}
      drag
      dragControls={dragControls}
      dragMomentum={false}
      dragListener={false}
      className={cn(
        "fixed min-w-[300px] min-h-[200px] bg-white border border-neutral-200 rounded-md",
        "shadow-lg overflow-hidden z-50",
        isResizing ? "select-none" : ""
      )}
      style={{
        width: size.width,
        height: size.height,
        left: "20px",
        top: "20px",
        position: "fixed",
      }}
    >
      {/* Header with drag handle */}
      <div className="px-2 py-2 border-b border-neutral-200 bg-neutral-50 font-semibold text-sm flex items-center justify-between select-none">
        <div
          className="flex items-center flex-1 cursor-grab"
          onPointerDown={(e) => dragControls.start(e)}
        >
          <DragHandleDots2Icon className="w-4 h-4 mr-2 text-neutral-400" />
          <span>Tiptap Debug View</span>
        </div>
        <button
          onClick={() => setIsVisible(false)}
          className="p-1 rounded-sm hover:bg-neutral-200 transition-colors"
          aria-label="Close debug viewer"
        >
          <Cross2Icon className="w-4 h-4 text-neutral-500" />
        </button>
      </div>

      <div className="flex h-[calc(100%-37px)] text-xs font-mono">
        {/* Left Column - Editor Content */}
        <div className="flex-1 border-r border-neutral-200 flex flex-col">
          <div className="p-2 border-b border-neutral-200 bg-neutral-50 flex gap-2">
            {(["text", "html", "json"] as ContentView[]).map((view) => (
              <button
                key={view}
                onClick={() => setContentView(view)}
                className={cn(
                  "px-2 py-1 rounded text-xs border border-neutral-200",
                  "transition-colors hover:bg-neutral-100",
                  contentView === view ? "bg-white" : "bg-transparent"
                )}
              >
                {view}
              </button>
            ))}
          </div>
          <pre className="p-3 overflow-auto flex-1 whitespace-pre-wrap">
            {renderContent()}
          </pre>
        </div>

        {/* Right Column - Comments */}
        <div className="w-[40%] p-3 overflow-auto bg-neutral-50">
          <div className="font-semibold mb-2">Comments</div>
          <pre className="m-0 text-xs">{JSON.stringify(comments, null, 2)}</pre>
        </div>
      </div>

      {/* Resize handle */}
      <div
        className="absolute bottom-0 right-0 w-6 h-6 cursor-nwse-resize"
        onMouseDown={handleResizeStart}
        style={{
          backgroundImage:
            "linear-gradient(135deg, transparent 50%, rgba(0,0,0,0.1) 50%)",
          backgroundSize: "10px 10px",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
          zIndex: 10,
        }}
      />
    </motion.div>
  );
};
