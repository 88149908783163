import { useTutorialModalState } from "@/lib/hooks/tutorialModalState";
import { useState } from "react";
import { BaseTutorialPageProps, TutorialModal } from "../modal";

export const SupportLettersTutorialModal = () => {
  const letterModalState = useTutorialModalState((state) => state.letter);
  const setLetterModalShown = useTutorialModalState(
    (state) => state.letter.setModalShown
  );

  const [open, setOpen] = useState(letterModalState.modalShown ? false : true);

  const onCompleteTutorial = () => {
    setLetterModalShown(true);
    setOpen(false);
  };

  const pages: BaseTutorialPageProps[] = [
    {
      topDescription: (
        <p>
          <span className="text-grey-200">
            Welcome to your support letters,
          </span>{" "}
          where you can review, comment, and approve them once they're ready to
          be signed or send them for signature if you have chosen this option.
        </p>
      ),
      title: "Make sure all data in your support letter is correct",
      bottomDescription:
        "Please review the facts presented in this letter carefully. This letter will be submitted to USCIS, the government agency responsible for citizenship and immigration.",
      image: <img src="/tutorial/support_letters/1.webp" />,
    },
    {
      topDescription: (
        <p>
          <span className="text-grey-200">
            Welcome to your support letters,
          </span>{" "}
          where you can review, comment, and approve them once they're ready to
          be signed or send them for signature if you have chosen this option.
        </p>
      ),
      title: "Comment if you think changes are needed",
      image: <img src="/tutorial/support_letters/2.webp" />,
      bottomDescription:
        "Lighthouse team will update it in 3 working days and let you know once it’s ready to review.",
    },
    {
      topDescription: (
        <p>
          <span className="text-grey-200">
            Welcome to your support letters,
          </span>{" "}
          where you can review, comment, and approve them once they're ready to
          be signed or send them for signature if you have chosen this option.
        </p>
      ),
      title: "Approve once they're ready to be signed",
      image: <img src="/tutorial/support_letters/3.webp" />,
    },
  ];

  return (
    <TutorialModal
      pages={pages}
      open={open}
      onOpenChange={setOpen}
      onCompleteTutorial={onCompleteTutorial}
    />
  );
};
