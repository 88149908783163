import { useTutorialModalState } from "@/lib/hooks/tutorialModalState";
import { useState } from "react";
import { BaseTutorialPageProps, TutorialModal } from "../modal";

export const PetitionReviewTutorialModal = () => {
  const petitionReviewModalState = useTutorialModalState(
    (state) => state.petitionReview
  );

  const [open, setOpen] = useState(
    petitionReviewModalState.modalShown ? false : true
  );

  const onCompleteTutorial = () => {
    petitionReviewModalState.setModalShown(true);
    setOpen(false);
  };

  const pages: BaseTutorialPageProps[] = [
    {
      topDescription: (
        <p>
          <span className="text-grey-200">
            Welcome to your visa application,
          </span>{" "}
          where you can review, comment, and approve the final application
          before it is sent to the USCIS.
        </p>
      ),
      title: "Make sure all the information in your petition is accurate",
      image: <img src="/tutorial/petition_review/1.webp" />,
    },
    {
      topDescription: (
        <p>
          <span className="text-grey-200">
            Welcome to your visa application,
          </span>{" "}
          where you can review, comment, and approve the final application
          before it is sent to the USCIS.
        </p>
      ),
      title: "Comment if you think changes are needed",
      image: <img src="/tutorial/petition_review/2.webp" />,
      bottomDescription:
        "Lighthouse team will update your case in 3 working days and let you know once it's ready to review.",
    },
    {
      topDescription: (
        <p>
          <span className="text-grey-200">
            Welcome to your visa application,
          </span>{" "}
          where you can review, comment, and approve the final application
          before it is sent to the USCIS.
        </p>
      ),
      title: "Approve once it's ready to go",
      image: <img src="/tutorial/petition_review/3.webp" />,
    },
  ];

  return (
    <TutorialModal
      pages={pages}
      open={open}
      onOpenChange={setOpen}
      onCompleteTutorial={onCompleteTutorial}
    />
  );
};
