import { useWorkspaces, Workspace } from "@/lib/hooks/workspaces";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { useState } from "react";
import { cn } from "@/lib/cn";
import { ChevronDownIcon, Separator } from "@radix-ui/themes";
import { useAuth } from "@clerk/clerk-react";
import { ManageTeamMembersModal } from "./manageTeamMembersModal";
import uniqolor from "uniqolor";
import { ExitIcon } from "@radix-ui/react-icons";

const getOptionsFromWorkspaces = (
  workspaces: Workspace[]
): {
  personal: Workspace[];
  companies: Workspace[];
} => {
  const personal: Workspace[] = [];
  const companies: Workspace[] = [];

  for (const workspace of workspaces) {
    if (workspace.id.startsWith("company")) {
      companies.push({
        id: workspace.id,
        name: workspace.name,
        onboardings: workspace.onboardings,
      });
      continue;
    }

    for (const onboardings of workspace.onboardings) {
      personal.push({
        id: workspace.id,
        name: `${onboardings.visaClass.toUpperCase()} (${onboardings.companyName ?? "Self Petition"})`,
        onboardings: workspace.onboardings,
        selectedOnboardingId: onboardings.id,
      });
    }
  }

  return { personal, companies };
};

const getColorFromName = (name: string) => {
  const color = uniqolor(name, {
    format: "rgb",
    lightness: [25, 75],
  });
  return {
    color: color.color,
    backgroundColor: color.color.split(")")[0] + ", 0.5)",
  };
};

export const WorkplaceSelectorExpanded = () => {
  const { selectedWorkspace, setSelectedWorkspace, workspaces } =
    useWorkspaces();

  const { signOut } = useAuth();

  const [open, setOpen] = useState(false);
  const [manageTeamMembersModalOpen, setManageTeamMembersModalOpen] =
    useState(false);

  const options = getOptionsFromWorkspaces(workspaces);

  const selectedOnboarding = selectedWorkspace?.onboardings.find(
    (onboarding) => onboarding.id === selectedWorkspace?.selectedOnboardingId
  );

  const selectedOnboardingName = selectedWorkspace?.id.startsWith("personal")
    ? `${selectedOnboarding?.visaClass.toUpperCase()} (${selectedOnboarding?.companyName ?? "Self Petition"})`
    : (selectedWorkspace?.name ?? "");
  const { color, backgroundColor } = getColorFromName(selectedOnboardingName);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex flex-row gap-2 p-2 items-center hover:bg-grey-700 rounded-md">
          <div
            className="w-5 h-5 shrink-0 rounded-sm font-medium text-[14px] bg-opacity-5 flex items-center justify-center"
            style={{
              backgroundColor,
              border: `1px solid ${color}`,
              color,
            }}
          >
            {selectedOnboardingName.split("(")[0][0]}
          </div>
          <span className="text-sm font-medium text-grey-100 max-w-3/4 text-ellipsis truncate">
            {selectedOnboardingName}
          </span>
          <ChevronDownIcon />
        </div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="start"
        className="-translate-y-2 bg-grey-800 w-64 shadow-border p-0 rounded-md"
      >
        {options.personal.length > 0 && (
          <>
            <h1 className="text-xs pt-2 pb-1 text-grey-400 items-center pl-3">
              My Visas
            </h1>
            <div className="flex flex-col w-full p-0.5">
              {options.personal.map((option) => {
                const { color, backgroundColor } = getColorFromName(
                  option.name
                );
                return (
                  <button
                    key={option.id}
                    className={cn(
                      "flex flex-row w-full p-2 items-center gap-2 text-sm rounded-sm hover:bg-grey-700 truncate text-nowrap text-grey-200",
                      option.id === selectedWorkspace?.id &&
                        selectedOnboarding?.id ===
                          option.selectedOnboardingId &&
                        "bg-grey-700 text-grey-100"
                    )}
                    onClick={() => setSelectedWorkspace(option)}
                  >
                    <div
                      className="w-5 h-5 shrink-0 rounded-sm font-medium text-[14px] bg-opacity-5 flex items-center justify-center"
                      style={{
                        backgroundColor,
                        border: `1px solid ${color}`,
                        color,
                      }}
                    >
                      {option.name.split("(")[0][0]}
                    </div>

                    <span className="max-w-3/4 text-ellipsis truncate">
                      {option.name}
                    </span>
                  </button>
                );
              })}
            </div>

            {options.companies.length > 0 && (
              <Separator className="bg-grey-600 mx-1" />
            )}
          </>
        )}

        {options.companies.length > 0 && (
          <>
            <h1 className="text-xs pt-2 pb-1 text-grey-400 items-center pl-3">
              Employer Workspaces
            </h1>
            <div className="flex flex-col w-full p-0.5">
              {options.companies.map((option) => {
                const { color, backgroundColor } = getColorFromName(
                  option.name
                );
                return (
                  <button
                    key={option.id}
                    className={cn(
                      "flex flex-row w-full p-2 items-center gap-2 text-sm rounded-sm hover:bg-grey-700 truncate text-nowrap text-grey-200",
                      option.id === selectedWorkspace?.id &&
                        "bg-grey-700 text-grey-100"
                    )}
                    onClick={() => setSelectedWorkspace(option)}
                  >
                    <div
                      className="w-5 h-5 shrink-0 rounded-sm font-medium text-[14px] bg-opacity-5 flex items-center justify-center"
                      style={{
                        backgroundColor,
                        border: `1px solid ${color}`,
                        color,
                      }}
                    >
                      {option.name.split("(")[0][0]}
                    </div>
                    <span className="max-w-3/4 text-ellipsis truncate">
                      {option.name}
                    </span>
                  </button>
                );
              })}
            </div>

            <Separator className="bg-grey-600 mx-1" />
          </>
        )}
        <div className="p-0.5">
          {selectedWorkspace?.id != null &&
            selectedWorkspace.id.startsWith("company") && (
              <>
                <ManageTeamMembersModal
                  open={manageTeamMembersModalOpen}
                  onOpenChange={setManageTeamMembersModalOpen}
                />
                <button
                  className={cn(
                    "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm"
                  )}
                  onClick={() => {
                    setManageTeamMembersModalOpen(true);
                  }}
                >
                  Manage team members
                </button>
              </>
            )}

          <button
            className={cn(
              "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm text-grey-300"
            )}
            onClick={() =>
              signOut({
                redirectUrl: "/",
              })
            }
          >
            <ExitIcon className="w-3 h-3" />
            Log out
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const WorkplaceSelectorCollapsed = () => {
  const { selectedWorkspace, setSelectedWorkspace, workspaces } =
    useWorkspaces();

  const { signOut } = useAuth();

  const [open, setOpen] = useState(false);
  const [manageTeamMembersModalOpen, setManageTeamMembersModalOpen] =
    useState(false);

  const options = getOptionsFromWorkspaces(workspaces);

  const selectedOnboarding = selectedWorkspace?.onboardings.find(
    (onboarding) => onboarding.id === selectedWorkspace?.selectedOnboardingId
  );

  const selectedOnboardingName = selectedWorkspace?.id.startsWith("personal")
    ? `${selectedOnboarding?.visaClass.toUpperCase()} (${selectedOnboarding?.companyName ?? "Self Petition"})`
    : (selectedWorkspace?.name ?? "");

  const { color, backgroundColor } = getColorFromName(selectedOnboardingName);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div className="w-full flex items-center justify-center p-3 hover:bg-grey-700">
            <div
              className="w-4 h-4 shrink-0 rounded-sm font-medium text-[10px] bg-opacity-5 flex items-center justify-center"
              style={{
                backgroundColor,
                border: `1px solid ${color}`,
                color,
              }}
            >
              {selectedOnboardingName.split("(")[0][0]}
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          side="bottom"
          align="start"
          className="-translate-y-2 bg-grey-800 w-64 shadow-border p-0 rounded-md"
        >
          {options.personal.length > 0 && (
            <>
              <h1 className="text-sm p-2 text-grey-300 items-center pl-3">
                Personal Cases
              </h1>
              <div className="flex flex-col w-full p-0.5">
                {options.personal.map((workspace) => (
                  <button
                    key={workspace.id}
                    className={cn(
                      "flex flex-row w-full p-2 items-center gap-2 text-sm rounded-sm hover:bg-grey-700 truncate text-nowrap text-grey-200",
                      workspace.id === selectedWorkspace?.id &&
                        "bg-grey-700 text-grey-100"
                    )}
                    onClick={() => setSelectedWorkspace(workspace)}
                  >
                    <div
                      className="w-4 h-4 shrink-0 rounded-sm font-medium text-[10px] bg-opacity-5 flex items-center justify-center"
                      style={{
                        backgroundColor: getColorFromName(workspace.name)
                          .backgroundColor,
                        border: `1px solid ${getColorFromName(workspace.name).color}`,
                        color: getColorFromName(workspace.name).color,
                      }}
                    >
                      {workspace.name.split("(")[0][0]}
                    </div>
                    {workspace.name}
                  </button>
                ))}
              </div>
            </>
          )}

          {options.companies.length > 0 && (
            <>
              <Separator className="my-2" size="4" />
              <h1 className="text-sm p-2 text-grey-300 items-center pl-3">
                Companies
              </h1>
              <div className="flex flex-col w-full p-0.5">
                {options.companies.map((workspace) => (
                  <button
                    key={workspace.id}
                    className={cn(
                      "flex flex-row w-full p-2 items-center gap-2 text-sm rounded-sm hover:bg-grey-700 truncate text-nowrap text-grey-200",
                      workspace.id === selectedWorkspace?.id &&
                        "bg-grey-700 text-grey-100"
                    )}
                    onClick={() => setSelectedWorkspace(workspace)}
                  >
                    <div
                      className="w-4 h-4 shrink-0 rounded-sm font-medium text-[10px] bg-opacity-5 flex items-center justify-center"
                      style={{
                        backgroundColor: getColorFromName(workspace.name)
                          .backgroundColor,
                        border: `1px solid ${getColorFromName(workspace.name).color}`,
                        color: getColorFromName(workspace.name).color,
                      }}
                    >
                      {workspace.name[0]}
                    </div>
                    {workspace.name}
                  </button>
                ))}
              </div>
            </>
          )}

          <Separator className="my-2" size="4" />
          <div className="p-0.5">
            {selectedWorkspace?.id != null &&
              selectedWorkspace.id.startsWith("company") && (
                <button
                  className={cn(
                    "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm"
                  )}
                  onClick={() => setManageTeamMembersModalOpen(true)}
                >
                  Manage team members
                </button>
              )}

            <button
              className={cn(
                "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm text-grey-300"
              )}
              onClick={() =>
                signOut({
                  redirectUrl: "/",
                })
              }
            >
              <ExitIcon className="w-4 h-4" />
              Log out
            </button>
          </div>
        </PopoverContent>
      </Popover>

      <ManageTeamMembersModal
        open={manageTeamMembersModalOpen}
        onOpenChange={setManageTeamMembersModalOpen}
      />
    </>
  );
};
