import { Applicants } from "@/components/companyApplicants";
import { CompanySidebarFrame } from "@/components/sidebarFrame";
import { useWorkspaces } from "@/lib/hooks/workspaces";
import { CompanyProvider } from "@/providers/companyProvider";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

const ApplicantsPage = () => {
  const { selectedWorkspace, rawId } = useWorkspaces();
  const nav = useNavigate();

  if (selectedWorkspace?.id.startsWith("user")) {
    nav({ to: "/home", replace: true });
    return;
  }

  return (
    <CompanyProvider companyId={rawId}>
      <CompanySidebarFrame>
        <div className="w-full h-full bg-grey-800 flex flex-col px-12 py-6 overflow-scroll">
          <Applicants />
        </div>
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

export const Route = createFileRoute("/_portal/applicants/")({
  component: () => <ApplicantsPage />,
});
