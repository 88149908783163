import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { getWorkspaces } from "@/lib/queries";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useQuery } from "urql";
import { useAdminOverrideUserId } from "./admin";
import { ResultOf } from "gql.tada";

export type Workspace = {
  id: string;
  name: string;
  selectedOnboardingId?: number;
  onboardings: ResultOf<
    typeof getWorkspaces
  >["getWorkspaces"][0]["onboardings"];
};

type WorkspaceStateType = {
  selectedWorkspace?: Workspace;
  setSelectedWorkspace: (workspace?: Workspace) => void;
};

export const useWorkspaceStore = create(
  persist<WorkspaceStateType>(
    (set) => ({
      setSelectedWorkspace: (value?: Workspace) => {
        set({ selectedWorkspace: value });
      },
    }),
    {
      name: "lighthouse-workspace-store-1",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useWorkspaces = () => {
  const overrideUserId = useAdminOverrideUserId();
  const nav = useNavigate();
  const { selectedWorkspace, setSelectedWorkspace } = useWorkspaceStore();

  const [{ data, fetching }, refetch] = useQuery({
    query: getWorkspaces,
    variables: {
      overrideUserId,
    },
    requestPolicy: "cache-first",
  });

  const doSetSelectedWorkspace = (
    workspace?: Workspace,
    dontNavigate?: boolean
  ) => {
    setSelectedWorkspace(workspace);

    if (dontNavigate) return;
    nav({ to: `/home` });
  };

  useEffect(() => {
    if (data?.getWorkspaces == null) return;
    if (data.getWorkspaces.length === 0) {
      return;
    }

    if (
      selectedWorkspace == null ||
      data.getWorkspaces.find((x) => x.id === selectedWorkspace.id) == null
    ) {
      setSelectedWorkspace({
        id: data.getWorkspaces[0].id,
        name: data.getWorkspaces[0].name,
        onboardings: data.getWorkspaces[0].onboardings,
        selectedOnboardingId:
          data.getWorkspaces[0].onboardings.length > 0
            ? data.getWorkspaces[0].onboardings[0].id
            : undefined,
      });
    }
  }, [selectedWorkspace, data, setSelectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace == null) return;
    if (
      selectedWorkspace.onboardings != null &&
      selectedWorkspace.onboardings.length === 0
    ) {
      return;
    }

    if (
      selectedWorkspace.selectedOnboardingId != null &&
      selectedWorkspace.onboardings.filter(
        (x) => x.id === selectedWorkspace.selectedOnboardingId
      ).length !== 0
    )
      return;

    setSelectedWorkspace({
      ...selectedWorkspace,
      selectedOnboardingId: selectedWorkspace.onboardings[0].id,
    });
  }, []);

  return {
    fetching,
    workspaces: data?.getWorkspaces ?? [],
    selectedWorkspace,
    setSelectedWorkspace: doSetSelectedWorkspace,
    refetchWorkspaces: refetch,
    rawId:
      selectedWorkspace?.id != null
        ? parseInt(selectedWorkspace?.id.split("-")[1])
        : undefined,
  };
};
