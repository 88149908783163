import { ArrowRightIcon, CheckCircledIcon } from "@radix-ui/react-icons";
import { ChatText } from "@/components/typography";
import { ChatBubble } from "@/components/chat/bubble";
import { AboutIcon } from "@/components/icons/about";
import { WorkExperienceIcon } from "@/components/icons/workExperience";
import { DocumentsIcon } from "@/components/icons/documents";
import { SupportLetterIcon } from "@/components/icons/supportLetters";

import {
  SidebarProgressCompleteIcon,
  SidebarProgressInProgress1Icon,
  SidebarProgressInProgress2Icon,
  SidebarProgressNotStartedIcon,
} from "@/components/icons/sidebarProgress";
import { Button } from "@/components/button";

import { useO1StartContext } from "./store";
import { Checkbox, DateInput, TextArea } from "@/components/inputs";
import { useRef } from "react";
import { cn } from "@/lib/cn";
import { useOnboardingData } from "@/providers/onboardingDataProvider";
import { TypingIndicator } from "./typingIndicator";
import { LockIcon } from "@/components/icons/sidebar";
import { WarningIcon } from "@/components/icons/warning";

const StatusExplainer = (props: { children: React.ReactNode }) => {
  return (
    <div className="bg-grey-800 rounded-full px-2 py-1 flex flex-row gap-[6px] text-[#111111B8]/70 items-center text-[14px] w-fit shadow-bubble">
      {props.children}
    </div>
  );
};

const SectionExplainer = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "bg-[#111111B8] bg-opacity-[72%] rounded-md px-2 py-1 flex flex-row gap-[6px] text-grey-800 items-center text-[14px] w-fit text-nowrap border border-blue border-opacity-[8%]",
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

const Goal = () => (
  <ChatBubble id="goal">
    <ChatText>
      Extraordinary immigrants start with Lighthouse. Our goal is to make
      preparing your visa application fast and seamless, so you can focus on
      building. <br /> <br />
      <span className="font-semibold">
        We work at your speed — the more swiftly you complete your onboarding
        and respond to our feedback, the faster our team will be able to prepare
        your visa application.
      </span>
      <br />
      <br />
      Thank you for trusting us to support your immigration journey.
    </ChatText>
  </ChatBubble>
);

const Timeline = () => (
  <ChatBubble id="timeline">
    <ChatText>
      Here's what to expect next: Submitting your onboarding will take you about
      1 hour. Submit as much of your onboarding as you have on hand. Our team
      will review and respond with feedback on any missing items or more
      information.
      <br />
      We'll request details about you, documentation related to work experience
      that will support your visa application case strategy, and names and
      profiles of your support letter signers.
      <br />
      <br />
      Once all sections of your onboarding are submitted, our team will confirm
      your case strategy, provide feedback on your information and documents,
      and make progress drafting your immigration forms, support letters, and
      application materials.
      <br />
      Over 3 to 4 weeks of application preparation, you will be primarily
      responsible for reviewing, revising, and approving support letters we
      draft for you plus any pending item requests.
      <br />
      The quicker you provide information and feedback, the faster your
      application will be prepared.
      <br />
      <br />
      Upon submission, USCIS premium processing ensures an outcome within{" "}
      <strong>
        15 business days, while standard processing takes 3+ months.
        <br /> <br />
        The anticipated outcomes are straight to an approval (happens with most
        of our applicants!), denial, or a Request for Evidence (RFE). Our team
        will guide you at every step of the way.
      </strong>
    </ChatText>
  </ChatBubble>
);

const Sections = () => (
  <ChatBubble id="sections" className="flex flex-col gap-1">
    <ChatText>
      There are four sections personalized to your case strategy, each
      containing information about you to verify or provide additional
      documentation:
    </ChatText>

    <div className="flex flex-row gap-1 my-4 w-full flex-wrap">
      <SectionExplainer>
        <AboutIcon />
        About you
      </SectionExplainer>
      <SectionExplainer>
        <WorkExperienceIcon />
        Your case strategy
      </SectionExplainer>
      <SectionExplainer>
        <DocumentsIcon />
        Employer details and documents
      </SectionExplainer>
      <SectionExplainer>
        <SupportLetterIcon />
        Support letters
      </SectionExplainer>
    </div>

    <ChatText>
      You will see progress at each step marked by these indicators:
    </ChatText>
    <div className="flex flex-row gap-2 mx-1 my-4 flex-wrap">
      <StatusExplainer>
        <SidebarProgressNotStartedIcon />- Not Started
      </StatusExplainer>
      <StatusExplainer>
        <div className="flex flex-row gap-0.5 items-center">
          <SidebarProgressInProgress1Icon />, <SidebarProgressInProgress2Icon />
        </div>
        - In Progress
      </StatusExplainer>
      <StatusExplainer>
        <SidebarProgressCompleteIcon />- Complete
      </StatusExplainer>
      <StatusExplainer>
        <LockIcon />- Submitted for Review
      </StatusExplainer>
      <StatusExplainer>
        <CheckCircledIcon className="text-positive w-3 h-3" />- Approved
      </StatusExplainer>
      <StatusExplainer>
        <WarningIcon />- Needs Review
      </StatusExplainer>
    </div>
  </ChatBubble>
);

const Autosave = () => (
  <ChatBubble id="autosave">
    <ChatText>
      If you need a break during the process, don't worry,{" "}
      <strong>your information will be saved as you go.</strong> You can return
      to your onboarding at any time and pick up where you left off.
      <br />
      <br />
      Before we dive in, please answer some questions:
    </ChatText>
  </ChatBubble>
);

const TravelPlan = (props: { doAddMessage: () => void }) => {
  const { state, setTravelWithin2Weeks, setTravelDetails, setDisableScroll } =
    useO1StartContext();
  const { travelWithin2Weeks, travelDetails } = state.data;
  const { isProgressing } = state.ui;

  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const handleTravelResponse = (value: boolean) => {
    if (isProgressing) return;
    setTravelWithin2Weeks(value);
    scrollToBottom();

    if (!value) {
      setTimeout(() => {
        props.doAddMessage();
      }, 1000);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      travelDetails &&
      travelDetails.trim().length >= 10
    ) {
      e.preventDefault();
      e.stopPropagation();
      props.doAddMessage();
    }
  };

  return (
    <div className={cn("flex flex-col")} id="travel-plan">
      <ChatBubble id="travel-plan">
        <ChatText className="text-grey-100">
          Do you have any international travel planned within the next two
          months?
        </ChatText>

        <div className="flex flex-row gap-2 mt-4">
          <Button
            variant="action"
            onClick={() => handleTravelResponse(true)}
            className={cn(
              "hover:opacity-100",
              travelWithin2Weeks === false && "opacity-50"
            )}
          >
            Yes, I have planned international travel
          </Button>
          <Button
            variant="action"
            onClick={() => handleTravelResponse(false)}
            className={cn(
              "hover:opacity-100",
              travelWithin2Weeks === true && "opacity-50"
            )}
          >
            No, I do not have international travel plans
          </Button>
        </div>
      </ChatBubble>

      {(state.messages.includes("travel-details") ||
        state.data.travelWithin2Weeks === true) && (
        <ChatBubble id="travel-details-2" className="mt-4">
          <ChatText className="text-grey-100 font-normal">
            Please describe your upcoming international travel plans for optimal
            scheduling of your case preparation. Our team will contact you with
            any strategic considerations as it relates to your application.
          </ChatText>
          <TextArea
            className="min-h-[80px] w-full mt-2"
            placeholder="Enter your message here..."
            value={travelDetails}
            onChange={(e) => setTravelDetails(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={() => setDisableScroll(true)}
            onBlur={() => setDisableScroll(false)}
          />
        </ChatBubble>
      )}

      <div ref={bottomRef} />
    </div>
  );
};

const CanBeResponsive = (props: { doAddMessage: () => void }) => {
  const { state, setCanBeResponsive, setBestDates } = useO1StartContext();

  const { canBeResponsive, bestDates } = state.data;
  const { isProgressing } = state.ui;

  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const handleResponsiveChange = (value: boolean) => {
    if (isProgressing) return;
    setCanBeResponsive(value);
    scrollToBottom();

    if (value) {
      setTimeout(() => {
        props.doAddMessage();
      }, 1000);
    }
  };

  const handleStartDateChange = (value: string) => {
    setBestDates({ ...bestDates, startDate: value });
  };

  const handleEndDateChange = (value: string) => {
    setBestDates({ ...bestDates, endDate: value });
  };

  return (
    <div className={cn("flex flex-col")} id="can-be-responsive">
      <ChatBubble>
        <ChatText className="text-grey-100">
          Can you commit to being responsive and available for about 1 hour a
          week for case preparation over the next two weeks?
        </ChatText>

        <div className="flex flex-row gap-2 mt-4">
          <Button
            variant="action"
            onClick={() => handleResponsiveChange(true)}
            className={cn(
              "hover:opacity-100",
              canBeResponsive === false && "opacity-50"
            )}
          >
            Yes, I can
          </Button>
          <Button
            variant="action"
            onClick={() => handleResponsiveChange(false)}
            className={cn(
              "hover:opacity-100",
              canBeResponsive === true && "opacity-50"
            )}
          >
            No, I can't
          </Button>
        </div>
      </ChatBubble>

      {(state.messages.includes("date-range") ||
        state.data.canBeResponsive === false) && (
        <div className="mt-4">
          <ChatBubble>
            <ChatText className="text-grey-100">
              Please select your preferred start and end dates for the
              preparation of your case. Our team will aim to meet these
              timelines, pending your collaboration.
            </ChatText>

            <div className="flex flex-row gap-2 mt-4 items-center">
              <DateInput
                value={
                  bestDates?.startDate != null
                    ? new Date(bestDates.startDate)
                    : undefined
                }
                onValueChange={(x) => {
                  if (x) {
                    handleStartDateChange(x.toISOString());
                  }
                }}
              />
              -
              <DateInput
                value={
                  bestDates?.endDate != null
                    ? new Date(bestDates.endDate)
                    : undefined
                }
                onValueChange={(x) => {
                  if (x) {
                    handleEndDateChange(x.toISOString());
                  }
                }}
              />
            </div>
          </ChatBubble>
        </div>
      )}

      <div ref={bottomRef} />
    </div>
  );
};

const Documents = () => (
  <ChatBubble id="documents">
    <ChatText className="text-grey-100">
      Great! Now you can continue to your onboarding submission. For the first
      part you will want to gather:
    </ChatText>

    <ol className="flex flex-col gap-1 mt-4">
      <li className="flex flex-row">
        <div className="p-1 pl-0">
          <ArrowRightIcon />
        </div>
        <ChatText className="text-grey-100">
          <strong>Passport</strong> for photos of your passport ID page and US
          visa stamps inside.
        </ChatText>
      </li>
      <li className="flex flex-row">
        <div className="p-1 pl-0">
          <ArrowRightIcon />
        </div>
        <ChatText className="text-grey-100">
          <strong>Copies of any of your prior visa documentation.</strong> It
          can be I-797, I-20, DS-2019, EAD cards, etc.
        </ChatText>
      </li>
    </ol>
  </ChatBubble>
);

const CaseStrategy = () => {
  const { allOnboardingGroups } = useOnboardingData();
  const strategy =
    allOnboardingGroups.find((x) => x.id === "work_experience")?.nodes ?? [];

  return (
    <ChatBubble id="case-strategy">
      <ChatText>
        We have prepared a case strategy based on information we've researched
        about you. Your case strategy reflects how your professional
        achievements map to the USCIS criteria for this visa.
      </ChatText>

      <div className="my-4 flex flex-col gap-1">
        {strategy.map((node, index) => {
          return (
            <div key={index} className="flex flex-row items-center gap-2 px-1">
              <Checkbox
                checked={true}
                disabled
                onCheckedChange={() => {}}
                className="disabled:opacity-100"
              />
              <div className="text-sm text-grey-100 flex flex-row gap-1">
                <span>
                  {node.type
                    .split("-")
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
                {node.name.trim().length > 0 && <>({node.name})</>}
              </div>
            </div>
          );
        })}
      </div>

      <ChatText>
        If you think we missed something you think is important to include to
        your case strategy, you can add it later for our review in the “Your
        case strategy” section of your onboarding.
      </ChatText>
    </ChatBubble>
  );
};

export const O1ChatLogs = (props: { doAddMessage: () => void }) => {
  const { state } = useO1StartContext();
  const { messages } = state;

  return (
    <div className="flex flex-col gap-4 h-full w-full overflow-y-scroll">
      {messages.map((message, index) => {
        switch (message) {
          case "goal":
            return <Goal key={index} />;
          case "timeline":
            return <Timeline key={index} />;
          case "sections":
            return <Sections key={index} />;
          case "autosave":
            return <Autosave key={index} />;
          case "travel-plan":
            return <TravelPlan key={index} doAddMessage={props.doAddMessage} />;
          case "can-be-responsive":
            return (
              <CanBeResponsive key={index} doAddMessage={props.doAddMessage} />
            );
          case "case-strategy":
            return <CaseStrategy key={index} />;
          case "documents":
            return <Documents key={index} />;
          default:
            return null;
        }
      })}
      {state.ui.isTyping && <TypingIndicator />}
    </div>
  );
};
